import { ToastContainer, Slide } from 'react-toastify'
import { useRoutes } from 'react-router-dom'
import ThemeRoutes from './routes/Router'

const App = () => {
  const appRoutes = useRoutes(ThemeRoutes)
  return (
    <div className='dark'>
      <ToastContainer
        transition={Slide}
        position='top-center'
        autoClose={5000}
        hideProgressBar
        theme='colored'
      />
      {appRoutes}
    </div>
  )
}

export default App
