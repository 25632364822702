import { MouseEventHandler, useContext, useState } from 'react'
import { Nav } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import AuthContext from '@/context/AuthProvider'
import { useTranslation } from 'react-i18next'
import { MODULES, ROLES } from '@/helper/Permissions'
import InvitePublicModal from '@/components/shared/InvitePublicModal'
//import Logo from './Logo'

interface SidebarProps {
  onLogout: MouseEventHandler<HTMLButtonElement> | undefined
  toggleMenu: MouseEventHandler<HTMLAnchorElement> | undefined
}

const Sidebar: React.FC<SidebarProps> = ({ toggleMenu }: SidebarProps) => {
  const { t } = useTranslation()
  const { auth, user } = useContext<any>(AuthContext)
  //console.log(auth.roles)
  // const showMobilemenu = () => {
  //   document.getElementById("sidebarArea").classList.toggle("showSidebar");
  // };
  const location = useLocation()
  const [showLink, setShowLink] = useState(false)

  const mapArray = location.pathname.split('/').filter((x) => x)

  // const link = encodeURI(`${window.location.origin}/invite?organization_name=${user.organizations[0] && user.organizations[0].organizationName}&link_url=${user.organizations[0].organizationOpenLinkIdUrlSafe}`);

  // console.log(link)

  const navigation = [
    {
      header: 'SkillsCV',
      list: [
        {
          title: t('organizations'),
          href: '/organization-list',
          icon: 'icon icon-building',
          allowedRoles: [ROLES.SkillsCVStaff, ROLES.SkillsCVSuperAdmin],
          allowedModules: [MODULES.SkillscvAdminModule],
        },
        // {
        //   title: "Students",
        //   href: "/students",
        //   icon: "bi bi-person",
        //   allowedRoles: ["OrganizationAdmin", "OrganizationStaff"],
        // },
      ],
    },
    // {
    //   header: 'Account',
    //   list: [
    //     {
    //       title: 'My Organization',
    //       href: '/organization',
    //       icon: 'icon icon-building',
    //       allowedRoles: [ROLES.OrganizationAdmin, ROLES.SkillsCVSuperAdmin],
    //       allowedModules: [MODULES.OrganizationSetUpModule],
    //     },
    //   ],
    // },
    {
      header: t('coaching'),
      list: [
        {
          title: t('my_groups'),
          href: '/my-groups',
          icon: 'bi bi-people',
          allowedRoles: [
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
            ROLES.SkillsCVSuperAdmin,
          ],
          allowedModules: [MODULES.CoachingModule],
        },
        {
          title: t('invite_members'),
          href: '/invite-members',
          icon: 'bi bi-link',
          allowedRoles: [
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
            ROLES.SkillsCVSuperAdmin,
          ],
          allowedModules: [MODULES.CoachingModule],
        },
        {
          title: t('statistics_member'),
          href: '/member-statistics',
          icon: 'icon icon-group-members',
          allowedRoles: [
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
            ROLES.SkillsCVSuperAdmin,
          ],
          allowedModules: [MODULES.CoachingModule],
        },
        {
          title: t('statistics_group'),
          href: '/group-statistics',
          icon: 'icon icon-groups',
          allowedRoles: [
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
            ROLES.SkillsCVSuperAdmin,
          ],
          allowedModules: [MODULES.CoachingModule],
        },
      ],
    },
    {
      header: t('search_and_match'),
      list: [
        {
          title: t('best_candidate_for_vac'),
          href: '/all-vacancies',
          icon: 'bi bi-stack',
          allowedRoles: [
            ROLES.SkillsCVStaff,
            ROLES.SkillsCVSuperAdmin,
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
            ROLES.WhiteLabelLiteUser,
          ],
          allowedModules: [MODULES.SearchAndMatchModule],
        },
        {
          title: t('all_candidates'),
          href: '/all-candidates',
          icon: 'bi bi-person-add',
          allowedRoles: [
            ROLES.SkillsCVStaff,
            ROLES.SkillsCVSuperAdmin,
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
          ],
          allowedModules: [MODULES.SearchAndMatchModule],
        },
        {
          title: t('invite_candidates'),
          href: '/invite-candidates',
          icon: 'bi bi-link',
          allowedRoles: [
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
            ROLES.SkillsCVSuperAdmin,
          ],
          allowedModules: [MODULES.SearchAndMatchModule],
        },
        {
          title: t('all_applications'),
          href: '/my-applications',
          icon: 'bi bi-envelope',
          allowedRoles: [
            ROLES.SkillsCVStaff,
            ROLES.SkillsCVSuperAdmin,
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
            ROLES.WhiteLabelLiteUser,
          ],
          allowedModules: [MODULES.SearchAndMatchModule],
        },
        // {
        //   title: 'Match vinden',
        //   href: '/match-vinded',
        //   icon: 'bi bi-person-add',
        //   allowedRoles: [
        //     ROLES.SkillsCVStaff,
        //     ROLES.SkillsCVSuperAdmin,
        //     ROLES.OrganizationAdmin,
        //     ROLES.OrganizationStaff,
        //   ],
        //   allowedModules: [MODULES.SearchAndMatchModule],
        // },
      ],
    },
    {
      header: t('search_match_plus'),
      list: [
        {
          title: t('best_candidate_for_vac'),
          href: '/talentpool-vacancies',
          icon: 'bi bi-stack',
          allowedRoles: [
            ROLES.SkillsCVStaff,
            ROLES.SkillsCVSuperAdmin,
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
            ROLES.WhiteLabelLiteUser,
          ],
          allowedModules: [MODULES.SearchAndMatchPlusModule],
        },
        // {
        //   title: t('all_candidates'),
        //   href: '/talentpool-candidates',
        //   icon: 'bi bi-person-add',
        //   allowedRoles: [
        //     ROLES.SkillsCVStaff,
        //     ROLES.SkillsCVSuperAdmin,
        //     ROLES.OrganizationAdmin,
        //     ROLES.OrganizationStaff,
        //   ],
        //   allowedModules: [MODULES.SearchAndMatchPlusModule],
        // },
        {
          title: t('all_applications'),
          href: '/talentpool-applications',
          icon: 'bi bi-envelope',
          allowedRoles: [
            ROLES.SkillsCVStaff,
            ROLES.SkillsCVSuperAdmin,
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
            ROLES.WhiteLabelLiteUser,
          ],
          allowedModules: [MODULES.SearchAndMatchPlusModule],
        },
        // {
        //   title: t('invite_candidates'),
        //   href: '/talentpool-invite-candidates',
        //   icon: 'bi bi-link',
        //   allowedRoles: [
        //     ROLES.OrganizationAdmin,
        //     ROLES.OrganizationStaff,
        //     ROLES.SkillsCVSuperAdmin,
        //   ],
        //   allowedModules: [MODULES.SearchAndMatchPlusModule],
        // },
      ],
    },
    {
      header: 'Talentpools',
      list: [
        {
          title: 'Talentpool',
          href: '/talentpool-manager',
          icon: 'bi bi-calendar-event',
          allowedRoles: [
            ROLES.SkillsCVStaff,
            ROLES.SkillsCVSuperAdmin,
            ROLES.ReadOnlyDemoUser,
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
          ],
          allowedModules: [MODULES.TalentPoolManagerModule],
        },
        // {
        //   title: t('campaigns'),
        //   href: '/campaigns',
        //   icon: 'bi bi-calendar4-week',
        //   allowedRoles: [
        //     ROLES.SkillsCVStaff,
        //     ROLES.SkillsCVSuperAdmin,
        //     ROLES.ReadOnlyDemoUser,
        //     ROLES.OrganizationAdmin,
        //     ROLES.OrganizationStaff,
        //   ],
        //   allowedModules: [MODULES.EventManagerModule],
        // },
      ],
    },
    {
      header: 'Arbeidsmarkt',
      list: [
        {
          title: t('professions'),
          href: '/professions',
          icon: 'icon icon-professions',
          allowedRoles: [
            ROLES.SkillsCVStaff,
            ROLES.SkillsCVSuperAdmin,
            ROLES.ReadOnlyDemoUser,
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
          ],
          allowedModules: [MODULES.JobMarketAnalysisModule],
        },
      ],
    },
    {
      header: 'Analytics',
      list: [
        // {
        //   title: 'Uitstroom',
        //   href: '/uitstroom',
        //   icon: 'bi bi-graph-up-arrow',
        //   allowedRoles: [ROLES.OrganizationAdmin],
        // },
        {
          title: t('talentpool'),
          href: '/talentpool',
          icon: 'bi bi-trophy',
          allowedRoles: [ROLES.SkillsCVStaff, ROLES.SkillsCVSuperAdmin, ROLES.ReadOnlyDemoUser],
          allowedModules: [
            MODULES.CoachingModule,
            MODULES.JobMarketAnalysisModule,
            MODULES.SearchAndMatchModule,
            MODULES.SkillscvAdminModule,
          ],
        },
        {
          title: t('job_seeker_insights'),
          href: '/jobseeker-insights',
          allowedRoles: [ROLES.SkillsCVStaff, ROLES.SkillsCVSuperAdmin, ROLES.ReadOnlyDemoUser],
          icon: 'icon icon-line-chart-line',
          allowedModules: [
            MODULES.CoachingModule,
            MODULES.JobMarketAnalysisModule,
            MODULES.SearchAndMatchModule,
            MODULES.SkillscvAdminModule,
          ],
        },
        {
          title: t('job_application_insights'),
          href: '/job-application-insights',
          allowedRoles: [ROLES.SkillsCVStaff, ROLES.SkillsCVSuperAdmin, ROLES.ReadOnlyDemoUser],
          icon: 'icon icon-shake-hands-line',
          allowedModules: [
            MODULES.CoachingModule,
            MODULES.JobMarketAnalysisModule,
            MODULES.SearchAndMatchModule,
            MODULES.SkillscvAdminModule,
          ],
        },
      ],
    },
    {
      header: t('settings'),
      list: [
        {
          title: t('my_organization'),
          href: '/organization',
          icon: 'icon icon-building',
          allowedRoles: [ROLES.OrganizationAdmin, ROLES.SkillsCVSuperAdmin],
          allowedModules: [MODULES.OrganizationSetUpModule],
        },
        {
          title: t('staff'),
          href: '/staff',
          icon: 'bi bi-mortarboard',
          allowedRoles: [ROLES.OrganizationAdmin, ROLES.SkillsCVSuperAdmin],
          allowedModules: [
            MODULES.CoachingModule,
            MODULES.SearchAndMatchModule,
            MODULES.SearchAndMatchPlusModule,
            MODULES.TalentPoolManagerModule,
          ],
        },
        {
          title: t('subscription_usage'),
          href: '/subscription-usage',
          icon: 'bi bi-graph-up-arrow',
          allowedRoles: [
            ROLES.OrganizationAdmin,
            ROLES.OrganizationStaff,
            ROLES.SkillsCVStaff,
            ROLES.ViewAPIDocs,
            ROLES.SkillsCVSuperAdmin,
            ROLES.ReadOnlyDemoUser,
            ROLES.WhiteLabelLiteUser,
          ],
          allowedModules: [MODULES.SearchAndMatchPlusModule],
        },
        // {
        //   title: t('invite_candidates'),
        //   href: '/invite-candidates',
        //   icon: 'bi bi-link',
        //   allowedRoles: [
        //     ROLES.OrganizationAdmin,
        //     ROLES.OrganizationStaff,
        //     ROLES.SkillsCVSuperAdmin,
        //   ],
        //   allowedModules: [MODULES.CoachingModule, MODULES.SearchAndMatchModule],
        // },
      ],
    },
    {
      header: 'API',
      list: [
        {
          title: 'API Documents',
          href: '/docs',
          icon: 'bi bi-file-earmark-code',
          allowedRoles: [ROLES.ViewAPIDocs, ROLES.SkillsCVSuperAdmin],
          allowedModules: [MODULES.SkillscvAdminModule],
        },
      ],
    },
    // {
    //   header: 'Account',
    //   list: [
    //     {
    //       title: t('profile'),
    //       href: '/profile',
    //       icon: 'icon icon-user-white',
    //       allowedRoles: [
    //         ROLES.ViewAPIDocs,
    //         ROLES.SkillsCVSuperAdmin,
    //         ROLES.OrganizationAdmin,
    //         ROLES.OrganizationStaff,
    //         ROLES.SkillsCVStaff,
    //         ROLES.ReadOnlyDemoUser,
    //       ],
    //       allowedModules: [
    //         MODULES.CoachingModule,
    //         MODULES.JobMarketAnalysisModule,
    //         MODULES.SearchAndMatchModule,
    //         MODULES.SkillscvAdminModule,
    //       ],
    //     },
    //     {
    //       title: t('change_password'),
    //       href: '/change-password',
    //       icon: 'icon icon-password',
    //       allowedRoles: [
    //         ROLES.ViewAPIDocs,
    //         ROLES.SkillsCVSuperAdmin,
    //         ROLES.OrganizationAdmin,
    //         ROLES.OrganizationStaff,
    //         ROLES.SkillsCVStaff,
    //         ROLES.ReadOnlyDemoUser,
    //       ],
    //       allowedModules: [
    //         MODULES.CoachingModule,
    //         MODULES.JobMarketAnalysisModule,
    //         MODULES.SearchAndMatchModule,
    //         MODULES.SkillscvAdminModule,
    //       ],
    //     },
    //   ],
    // },
  ]

  const filteredNavigation = navigation
    .map((item) => ({
      ...item,
      list: item.list.filter(
        (navi) =>
          auth?.roles.some((role) => navi.allowedRoles.includes(role)) &&
          auth?.modules.some((module) => navi.allowedModules.includes(module)),
      ),
    }))
    .filter((item) => item.list.length > 0)

  //console.log(filteredNavigation)

  return (
    <>
      <div className='sidebar-scoll'>
        <div className='p-3'>
          {/* <div className='d-flex align-items-center justify-content-between mb-4 pb-3'>
        <Logo type='dark' width='120' />
      </div> */}
          <div className='pt-0 pt-md-4'>
            {filteredNavigation.map((item, i, row) => (
              <Nav
                className={`flex-column ${i === row.length - 1 ? '' : 'mb-4'}`}
                key={item.header}
              >
                <h6 className='fs-12 pe-3 mb-3'>{item.header}</h6>

                {item.list.map((navi) => (
                  <Nav.Item key={navi.title} className='sidenav-bg navbar-nav'>
                    <Link
                      onClick={toggleMenu}
                      className={`nav-link
                    ${
                      location.pathname === navi.href ||
                      mapArray.some((x: string) => `/${x}` === navi.href)
                        ? 'active'
                        : ''
                    }
                    ${
                      navi.href === '/subscription-usage' &&
                      (user?.organizations?.[0]?.idEcommerce ||
                        user?.organizations?.[0]?.oneInOneOutFlag)
                        ? ''
                        : navi.href === '/subscription-usage' && 'd-none'
                    }`}
                      to={`${navi.href}`}
                    >
                      <i className={navi.icon}></i>
                      <span className='ms-3 d-inline-block'>{navi.title}</span>
                    </Link>
                  </Nav.Item>
                ))}
              </Nav>
            ))}

            {/* <Nav.Item className='sidenav-bg navbar-nav'>
          <Nav.Link onClick={onLogout}>
            <i className='icon icon-logout me-3'></i>Logout
          </Nav.Link>
        </Nav.Item> */}
          </div>
        </div>
      </div>

      {user?.organizations[0]?.modules.some((ai: string) =>
        [MODULES.TalentPoolManagerModule, MODULES.SearchAndMatchPlusModule]
          .map((mod) => mod)
          .toString()
          .includes(ai),
      ) && (
        <>
          <div className='text-center py-3 border-top'>
            <div className='text-pink cursor fs-12 fw-semibold' onClick={() => setShowLink(true)}>
              <i className='bi bi-share me-2'></i> {t('invite_candidates')}
            </div>
          </div>

          {showLink ? (
            <InvitePublicModal
              show={showLink}
              handleClose={() => setShowLink(false)}
              link={user.organizations[0].organizationOpenLinkIdUrlSafe || ''}
              organization_name={user.organizations[0].organizationName || ''}
            />
          ) : null}
        </>
      )}
    </>
  )
}

export default Sidebar
