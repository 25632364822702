import Logo from './Logo'

import { Navbar, Container } from 'react-bootstrap'
import DropdownLanguage from '@/components/shared/DropdownLanguage'

const AuthHeader = () => {
  return (
    <Navbar bg='transparent' variant='light' className='py-3' fixed='top'>
      <Container fluid>
        <Navbar.Brand>
          <Logo type='dark' width='180' />
        </Navbar.Brand>
        <DropdownLanguage />
      </Container>
    </Navbar>
  )
}

export default AuthHeader
