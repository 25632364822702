import moment from 'moment'

export const downloadQR = (name: string) => {
  const filename = name.replace(/ /g, '_')
  const svg = document.getElementById('qrcode')
  const now = moment(new Date()).format('YYYY_MM_DD_MM__H_mm_ss_A')

  if (svg) {
    const svgData = new XMLSerializer().serializeToString(svg)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.onload = function () {
      canvas.width = img.width + 40
      canvas.height = img.height + 40
      ctx?.strokeRect(0, 0, canvas.width, canvas.height)
      if (ctx) {
        ctx.fillStyle = '#FFFFFF'
      }
      ctx?.fillRect(0, 0, canvas.width, canvas.height)
      ctx?.drawImage(img, 20, 20)
      const pngFile = canvas.toDataURL('image/png', 1.0)
      //const pngFile = canvas.toDataURL("image/png");

      const downloadLink = document.createElement('a')
      downloadLink.download = `${filename}_${now}.png` // Set the file extension
      downloadLink.href = `${pngFile}`
      downloadLink.click()
    }
    img.src = 'data:image/svg+xml;base64,' + btoa(svgData)
  }
}
