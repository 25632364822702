import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

const parseError = (error, show?: boolean) => {
  console.log(error)
  let message
  if (error instanceof AxiosError) {
    if (
      error.response?.status === 422 ||
      error.response?.status === 409 ||
      error.response?.status === 404 ||
      error.response?.status === 491 ||
      error.response?.status === 492
    ) {
      if (error.response?.data?.detail && typeof error.response?.data?.detail === 'string') {
        message = error.response?.data?.detail
      } else if (error.response?.data?.detail && Array.isArray(error.response?.data?.detail)) {
        message = error.response?.data?.detail
          .map((error) => error.loc.join('=>') + ' ' + error.msg)
          .join(', ')
      }
      if (show) {
        toast.error(message)
        return
      } else {
        return (
          <div className='d-flex align-items-center'>
            <i className='bi bi-exclamation-triangle-fill me-2'></i> {JSON.stringify(message)}
          </div>
        )
      }
    } else {
      if (show) {
        toast.error('Something went wrong, Please try again later.')
      } else {
        return 'An error occurred. Please try again later.'
      }
    }
  } else {
    message = error.message || 'An error occurred. Please try again later.'
    return message
  }
}

export default parseError
