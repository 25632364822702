import axios from 'axios'
import { createApiClient } from './schemaTypes'

const AUTH_URL = import.meta.env.VITE_AUTH_URL
const ANALYTIC_URL = import.meta.env.VITE_ANALYTIC_URL
// Change to 2
export default axios.create({
  baseURL: `${AUTH_URL}/api/`,
})

//console.log(process.env.REACT_APP_ENV)
// console.log(process.env.REACT_APP_AUTH_URL);
// console.log(process.env.REACT_APP_ANALYTIC_URL);

export const axiosPrivate = axios.create({
  baseURL: `${ANALYTIC_URL}/api/`,
})

export const zodiosApi = createApiClient(`${ANALYTIC_URL}`, { axiosInstance: axiosPrivate })

export const axiosPublic = axios.create({
  baseURL: `${AUTH_URL}/api/`,
})
