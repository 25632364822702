import parseError from '@/service/error.service'
import { useState } from 'react'

const useHandleCopy = () => {
  const [copied, setCopied] = useState(false)
  const [inputCopied, setInputCopied] = useState(false)

  const handleCopy = (ref: any, type: string, mode?: string) => {
    const contentToCopy =
      type === 'div'
        ? mode === 'html'
          ? ref?.current?.innerHTML
          : ref?.current?.innerText
        : ref?.current?.value
    if (contentToCopy) {
      navigator.clipboard
        .writeText(contentToCopy)
        .then(() => {
          type === 'div' ? setCopied(true) : setInputCopied(true)
          setTimeout(() => {
            type === 'div' ? setCopied(false) : setInputCopied(false)
          }, 2000) // Reset copied state after 2 seconds
        })
        .catch((error) => {
          parseError(error)
        })
    }
  }

  return {
    copied,
    inputCopied,
    handleCopy,
  }
}

export default useHandleCopy
