import useHandleCopy from '@/helper/HandleCopy'
import CopySnippet from '../CopySnippet'
import { LinksData } from '@/interfaces/skillscv'
import logo from '@/assets/images/logos/logo.png'
import TabRowLayout from '../TabRowLayout'
import { ReactNode } from 'react'

type LinkType = {
  data?: LinksData
  link?: string
  children: ReactNode
}

const ViaWebsite = ({ data, link, children }: LinkType) => {
  const { copied, handleCopy } = useHandleCopy()

  const linkUrl = data?.linkUrl || link || ''

  const htmlString = `
    <a href="${linkUrl}" style="background:#390099;display: inline-block; padding: 15px 20px; border-radius:10px">
      <img src="${window.location.origin}/assets/logo-dark-DL7GlEFH.png" width="188" height="48" />
      <p style="margin-bottom:0; margin-top:10px; color:#000; text-align:right; font-size: 16px; font-weight:500">Sign Up now</p>
    </a>
  `

  return (
    <TabRowLayout
      leftChildren={children}
      rightChildren={
        <>
          <CopySnippet copied={copied} handleCopy={handleCopy} mode='dark'>
            {htmlString as any}
          </CopySnippet>

          <h5 className='pt-2'>Preview</h5>

          <div>
            <div className='mb-0'>
              <a
                href='#'
                className='bg-primary'
                style={{ display: 'inline-block', padding: '15px 20px', borderRadius: 10 }}
              >
                <img src={logo} width={150} height={38} />
                <p
                  className='text-white'
                  style={{
                    marginBottom: 0,
                    marginTop: 10,
                    color: '#000',
                    textAlign: 'right',
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Sign Up Now
                </p>
              </a>
            </div>
          </div>
        </>
      }
    />
  )
}

export default ViaWebsite
