import { t } from 'i18next'
import { Modal, Button, Tab, Col, Nav, Row } from 'react-bootstrap'
import ViaLink from './tabs/ViaLink'
import ViaQR from './tabs/ViaQR'
import ViaWebsite from './tabs/ViaWebsite'
//import ViaATS from './tabs/ViaATS'

interface props {
  show: boolean
  handleClose: (...args: any[]) => any
  link: string
  organization_name: string
  //children: JSX.Element
}

const InvitePublicModal = ({ show, handleClose, link, organization_name }: props) => {
  const selectedLink = encodeURI(
    `${window.location.origin}/invite?organization_name=${organization_name}&link_url=${link}`,
  )

  return (
    <Modal size='xl' show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className='fs-6'>Invite to join {organization_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: 450 }}>
        <Tab.Container id='left-tabs-example' defaultActiveKey='first'>
          <Row className='link-tabs h-100'>
            <Col sm={3} className='border-end'>
              <Nav variant='underline'>
                <Nav.Item>
                  <Nav.Link eventKey='first'>{t('invitation_content_link')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='second'>{t('invitation_content_qr_code')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='third'>{t('invitation_content_website')}</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey='fourth'>{t('invitation_content_ats')}</Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey='first'>
                  <ViaLink link={selectedLink}>
                    <>
                      <h5 className='mb-4'>{t('invitation_content_link')}:</h5>
                      <p>
                        <b>{t('purpose')}:</b> {t('via_link_purpose')}
                      </p>

                      <p>
                        <b>{t('implementation')}:</b> {t('via_link_implementation')}
                      </p>

                      <p>
                        <b>{t('client_impact')}:</b> {t('via_link_impact')}
                      </p>
                    </>
                  </ViaLink>
                </Tab.Pane>
                <Tab.Pane eventKey='second'>
                  <ViaQR link={link} organization_name={organization_name}>
                    <>
                      <h5 className='mb-4'>{t('invitation_content_qr_code')}:</h5>
                      <p>
                        <b>{t('purpose')}:</b> {t('via_qr_purpose')}
                      </p>

                      <p>
                        <b>{t('implementation')}:</b> {t('via_qr_implementation')}
                      </p>

                      <p>
                        <b>{t('client_impact')}:</b> {t('via_qr_impact')}
                      </p>
                    </>
                  </ViaQR>
                </Tab.Pane>
                <Tab.Pane eventKey='third'>
                  <ViaWebsite link={selectedLink}>
                    <>
                      <h5 className='mb-4'>{t('invitation_content_ats')}:</h5>
                      <p>
                        <b>{t('purpose')}:</b> {t('via_website_purpose')}
                      </p>

                      <p>
                        <b>{t('implementation')}:</b> {t('via_website_implementation')}
                      </p>

                      <p>
                        <b>{t('client_impact')}:</b> {t('via_website_impact')}
                      </p>
                    </>
                  </ViaWebsite>
                </Tab.Pane>
                {/* <Tab.Pane eventKey='fourth'>
                  <ViaATS />
                </Tab.Pane> */}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={handleClose}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default InvitePublicModal
