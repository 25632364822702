import useHandleCopy from '@/helper/HandleCopy'
import CopySnippet from '../CopySnippet'
import { LinksData } from '@/interfaces/skillscv'
import { ReactNode } from 'react'
import TabRowLayout from '../TabRowLayout'

type LinkType = {
  data?: LinksData
  link?: string
  children: ReactNode
}

const ViaLink = ({ data, link, children }: LinkType) => {
  const { copied, handleCopy } = useHandleCopy()

  const linkUrl = data?.linkUrl || link || ''

  return (
    <TabRowLayout
      leftChildren={children}
      rightChildren={
        <CopySnippet copied={copied} handleCopy={handleCopy} mode='dark'>
          <p className='mb-0'>{linkUrl}</p>
        </CopySnippet>
      }
    />
  )
}

export default ViaLink
