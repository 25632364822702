import { ReactNode } from 'react'
import { Col, Row } from 'react-bootstrap'

interface TwoColumnLayoutProps {
  leftChildren: ReactNode
  rightChildren: ReactNode
}

const TabRowLayout = ({ leftChildren, rightChildren }: TwoColumnLayoutProps) => {
  return (
    <Row>
      <Col lg='6' className='border-end'>
        {leftChildren}
      </Col>
      <Col lg='6' className='border-end'>
        {rightChildren}
      </Col>
    </Row>
  )
}

export default TabRowLayout
