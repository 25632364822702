import { Button } from 'react-bootstrap'
import { LinksData } from '@/interfaces/skillscv'
import { downloadQR } from '@/helper/DownloadQR'
import QRCode from 'react-qr-code'
import useCopyQR from '@/helper/CopyQR'
import { ReactNode } from 'react'
import TabRowLayout from '../TabRowLayout'

type LinkType = {
  organization_name: string
  data?: LinksData
  link?: string
  children: ReactNode
}

const ViaQR = ({ data, organization_name, link, children }: LinkType) => {
  const name = data?.eventName || organization_name

  const url = encodeURI(
    `${window.location.origin}/invite?organization_name=${name}&link_url=${data?.linkIdUrlSafe || link || ''}`,
  )

  const { handleCopy, copied, canvasRef } = useCopyQR()

  return (
    <TabRowLayout
      leftChildren={children}
      rightChildren={
        <>
          {(data || link) && (
            <>
              <div
                ref={canvasRef}
                style={{ height: 'auto', margin: '0 auto', maxWidth: 200, width: '100%' }}
              >
                <QRCode
                  size={200}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={url}
                  viewBox={`0 0 200 200`}
                  id='qrcode'
                  bgColor='#fff'
                />
              </div>
              <div className='text-center pt-4'>
                <Button
                  size='lg'
                  variant='outline-pink'
                  onClick={() => downloadQR(`${name}_${data?.linkName}`)}
                >
                  <i className='bi bi-download'></i> Download QR
                </Button>
                <Button size='lg' onClick={handleCopy} className='copy-btn ms-3'>
                  <i className='bi bi-copy'></i>
                  {copied ? (
                    <span className='ms-2'>Copied!</span>
                  ) : (
                    <span className='ms-2'>Copy QR</span>
                  )}
                </Button>
              </div>
            </>
          )}
        </>
      }
    />
  )
}

export default ViaQR
