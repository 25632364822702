import { makeApi, Zodios, type ZodiosOptions } from '@zodios/core'
import { z } from 'zod'

export type AnonymizedCVOutSchema = {
  skillsCvId: string
  user: (AnonymizedUserOutSchema | null) | Array<AnonymizedUserOutSchema | null>
  cvProfile:
    | (AnonymizedSkillCVProfileOutSchema | null)
    | Array<AnonymizedSkillCVProfileOutSchema | null>
  cvAddress: (SkillsCVAddressOutSchema | null) | Array<SkillsCVAddressOutSchema | null>
  cvItems?:
    | ((SkillsCVLineItemsOutSchema | null) | Array<SkillsCVLineItemsOutSchema | null>)
    | undefined
  organization?:
    | ((CVOrganizationOutSchema | null) | Array<CVOrganizationOutSchema | null>)
    | undefined
}
export type AnonymizedUserOutSchema = Partial<{
  userId: string | null
  email: (string | null) | Array<string | null>
  preferredName: (string | null) | Array<string | null>
}>
export type AnonymizedSkillCVProfileOutSchema = Partial<{
  firstNm: (string | null) | Array<string | null>
  prefixNm: (string | null) | Array<string | null>
  lastNm: (string | null) | Array<string | null>
  dateOfBirth: (string | null) | Array<string | null>
  workingHours: (number | null) | Array<number | null>
  willingToTravel: (boolean | null) | Array<boolean | null>
  telephoneNumber: (string | null) | Array<string | null>
  aboutMe: (string | null) | Array<string | null>
  lastModifiedDt: (string | null) | Array<string | null>
  profilePhoto3x: (string | null) | Array<string | null>
  profilePhoto2x: (string | null) | Array<string | null>
  profilePhotoCircle2x: (string | null) | Array<string | null>
  profilePhoto1x: (string | null) | Array<string | null>
  skillsCvId: (string | null) | Array<string | null>
  gender: (string | null) | Array<string | null>
}>
export type SkillsCVAddressOutSchema = Partial<{
  street: (string | null) | Array<string | null>
  houseNumber: (string | null) | Array<string | null>
  postalCode: (string | null) | Array<string | null>
  city: (string | null) | Array<string | null>
  state: (string | null) | Array<string | null>
  lastModifiedDt: (string | null) | Array<string | null>
  skillsCvId: (string | null) | Array<string | null>
}>
export type SkillsCVLineItemsOutSchema = Partial<{
  experience:
    | (Array<SkillsCVLineItemExperienceOutSchema> | null)
    | Array<Array<SkillsCVLineItemExperienceOutSchema> | null>
  education:
    | (Array<SkillsCVLineItemEducationOutSchema> | null)
    | Array<Array<SkillsCVLineItemEducationOutSchema> | null>
  hobby:
    | (Array<SkillsCVLineItemHobbyOutSchema> | null)
    | Array<Array<SkillsCVLineItemHobbyOutSchema> | null>
  language:
    | (Array<SkillsCVLineItemLanguageOutSchema> | null)
    | Array<Array<SkillsCVLineItemLanguageOutSchema> | null>
  softSkillKnowledge:
    | (Array<SkillsCVLineItemSoftSkillKnowledgeOutSchema> | null)
    | Array<Array<SkillsCVLineItemSoftSkillKnowledgeOutSchema> | null>
  certificate:
    | (Array<SkillsCVLineItemCertificateOutSchema> | null)
    | Array<Array<SkillsCVLineItemCertificateOutSchema> | null>
}>
export type SkillsCVLineItemExperienceOutSchema = {
  skillsCvLineItemId?: ((string | null) | Array<string | null>) | undefined
  profession: skillscv_analytics_web__group__schemas__external_schemas__ProfessionOutSchema
  company: string
  startD?: ((string | null) | Array<string | null>) | undefined
  endD?: ((string | null) | Array<string | null>) | undefined
  location?: ((string | null) | Array<string | null>) | undefined
  description?: ((string | null) | Array<string | null>) | undefined
  lastModifiedDt?: ((string | null) | Array<string | null>) | undefined
  lineItemType: string
  relatedSkills?:
    | (
        | (Array<SkillsCVLineItemRelatedSkillOutSchema> | null)
        | Array<Array<SkillsCVLineItemRelatedSkillOutSchema> | null>
      )
    | undefined
}
export type skillscv_analytics_web__group__schemas__external_schemas__ProfessionOutSchema = {
  id?: ((string | null) | Array<string | null>) | undefined
  professionNm: string
}
export type SkillsCVLineItemRelatedSkillOutSchema = {
  relatedSkillId: string
  relatedSkillsCvLineItemId: string
}
export type SkillsCVLineItemEducationOutSchema = {
  skillsCvLineItemId?: ((string | null) | Array<string | null>) | undefined
  educationalInstitutionLocation?: ((string | null) | Array<string | null>) | undefined
  startD?: ((string | null) | Array<string | null>) | undefined
  endD?: ((string | null) | Array<string | null>) | undefined
  lastModifiedDt?: ((string | null) | Array<string | null>) | undefined
  educationCompleted?: ((boolean | null) | Array<boolean | null>) | undefined
  lineItemType: string
  education?: ((EducationOutSchema | null) | Array<EducationOutSchema | null>) | undefined
  educationalInstitution: EducationalInstitutionOutSchema
  relatedSkills?:
    | (
        | (Array<SkillsCVLineItemRelatedSkillOutSchema> | null)
        | Array<Array<SkillsCVLineItemRelatedSkillOutSchema> | null>
      )
    | undefined
  educationDegree?:
    | ((EducationDegreeOutSchema | null) | Array<EducationDegreeOutSchema | null>)
    | undefined
  educationFieldOfStudy: EducationFieldOfStudyOutSchema
}
export type EducationOutSchema = {
  educationNm: string
  educationModeratedId: string
}
export type EducationalInstitutionOutSchema = Partial<{
  id: (string | null) | Array<string | null>
  educationalInstitutionNm: (string | null) | Array<string | null>
}>
export type EducationDegreeOutSchema = {
  id: string
  degreeCode?: ((string | null) | Array<string | null>) | undefined
}
export type EducationFieldOfStudyOutSchema = {
  id: string
  fieldOfStudyNm: string
}
export type SkillsCVLineItemHobbyOutSchema = {
  skillsCvLineItemId?: ((string | null) | Array<string | null>) | undefined
  hobby: HobbyOutSchema
  lastModifiedDt?: ((string | null) | Array<string | null>) | undefined
  lineItemType: string
  relatedSkills?:
    | (
        | (Array<SkillsCVLineItemRelatedSkillOutSchema> | null)
        | Array<Array<SkillsCVLineItemRelatedSkillOutSchema> | null>
      )
    | undefined
}
export type HobbyOutSchema = {
  hobbyNm: string
  hobbyModeratedId: string
}
export type SkillsCVLineItemLanguageOutSchema = {
  skillsCvLineItemId?: ((string | null) | Array<string | null>) | undefined
  rating?: ((number | null) | Array<number | null>) | undefined
  lastModifiedDt?: ((string | null) | Array<string | null>) | undefined
  lineItemType: string
  language: SkillOutSchema
}
export type SkillOutSchema = {
  id?: ((string | null) | Array<string | null>) | undefined
  skillCategory?: ((string | null) | Array<string | null>) | undefined
  skillNm: string
}
export type SkillsCVLineItemSoftSkillKnowledgeOutSchema = {
  skillsCvLineItemId?: ((string | null) | Array<string | null>) | undefined
  skill?: ((SkillOutSchema | null) | Array<SkillOutSchema | null>) | undefined
  rating?: ((number | null) | Array<number | null>) | undefined
  lastModifiedDt?: ((string | null) | Array<string | null>) | undefined
  lineItemType: string
}
export type SkillsCVLineItemCertificateOutSchema = {
  skillsCvLineItemId?: ((string | null) | Array<string | null>) | undefined
  certificate?: ((SkillOutSchema | null) | Array<SkillOutSchema | null>) | undefined
  lastModifiedDt?: ((string | null) | Array<string | null>) | undefined
  lineItemType: string
}
export type CVOrganizationOutSchema = Partial<{
  organizationName: (string | null) | Array<string | null>
  theme: (OrganizationThemeOutSchema | null) | Array<OrganizationThemeOutSchema | null>
  organizationLogoUrl: (string | null) | Array<string | null>
}>
export type OrganizationThemeOutSchema = Partial<{
  primaryColor: string
  secondaryColor: string
  tertiaryColor: string
  otherColor: string
}>
export type AnonymizedOutSchema = {
  email: string
  name?: ((string | null) | Array<string | null>) | undefined
  cvDetails: (Array<CVDetailsOutSchema> | null) | Array<Array<CVDetailsOutSchema> | null>
}
export type CVDetailsOutSchema = Partial<{
  skillscvId: (string | null) | Array<string | null>
  completenessPercentage: (number | null) | Array<number | null>
  skillsCompleteness: boolean
  workExperienceCompleteness: boolean
  educationCompleteness: boolean
  hobbyCompleteness: boolean
  languageCompleteness: boolean
  profileCompleteness: boolean
}>
export type CompareProfessionOutSchema = {
  bocCode: string
  professionName: string
  numberOfPositionsOpened: number
  skillOverlapPercentage: number
  skills: Array<SkillsCompareProfessionOutSchema>
}
export type SkillsCompareProfessionOutSchema = {
  skillId: string
  name: string
  percentage: number
  sharedSkill?: boolean | undefined
  sharedSkillPosition?: number | undefined
}
export type CustomPage_AllCandidatesOutSchema_ = {
  items: Array<AllCandidatesOutSchema>
  pageInfo: PageInfo
}
export type AllCandidatesOutSchema = {
  skillscvId: string
  cvCreationDate: string
  candidateCode: string
}
export type PageInfo = Partial<{
  itemsTotal: number
  itemsOnPage: number
  pageSize: number
  pageCurrent: number
  pageMax: number
}>
export type CustomPage_Annotated_Union_ExternalCandidatesOutSchema__ExternalCandidatesSimpleOutSchema___FieldInfo_annotation_NoneType__required_True__discriminator__detailed____ =
  {
    items: Array<ExternalCandidatesOutSchema | ExternalCandidatesSimpleOutSchema>
    pageInfo: PageInfo
  }
export type ExternalCandidatesOutSchema = {
  memberId: string
  name: (string | null) | Array<string | null>
  email: string
  inviteEmailStatus?: ((EmailStatusEnum | null) | Array<EmailStatusEnum | null>) | undefined
  dateInvited: string
  cvDetails:
    | (Array<CVDetailsExternalCandidatesOutSchema> | null)
    | Array<Array<CVDetailsExternalCandidatesOutSchema> | null>
  detailed: 'yes'
  inviteLinkCompanyName?: ((string | null) | Array<string | null>) | undefined
}
export type EmailStatusEnum =
  | 'There is no information about the delivery'
  | 'Delivered'
  | 'Unknown failure, ask the recipient to verify mail settings'
  | "The recipient's email does not exist"
  | "The recipient's mailbox is full"
export type CVDetailsExternalCandidatesOutSchema = Partial<{
  skillscvId: (string | null) | Array<string | null>
  completenessPercentage: (number | null) | Array<number | null>
  dateCreated: (string | null) | Array<string | null>
  candidateName: (string | null) | Array<string | null>
  telephoneNumber: (string | null) | Array<string | null>
}>
export type ExternalCandidatesSimpleOutSchema = {
  memberId: string
  email: string
  detailed: 'no'
}
export type CustomPage_AnonymizedOutSchema_ = {
  items: Array<AnonymizedOutSchema>
  pageInfo: PageInfo
}
export type CustomPage_CompareProfessionOutSchema_ = {
  items: Array<CompareProfessionOutSchema>
  pageInfo: PageInfo
}
export type CustomPage_ExternalCandidatesOutSchema_ = {
  items: Array<ExternalCandidatesOutSchema>
  pageInfo: PageInfo
}
export type CustomPage_GroupDryOutSchema_ = {
  items: Array<GroupDryOutSchema>
  pageInfo: PageInfo
}
export type GroupDryOutSchema = {
  groupId: string
  groupName: string
  organizationGroupCode: string
  organizationId: string
  managedBy: Array<AdminMemberOutSchema>
  numberOfMembers: number
  groupType: (GroupTypeOutSchema | null) | Array<GroupTypeOutSchema | null>
}
export type AdminMemberOutSchema = {
  email: string
  userId: string
  firstName: (string | null) | Array<string | null>
  lastName: (string | null) | Array<string | null>
}
export type GroupTypeOutSchema = {
  groupTypeId: string
  groupTypeCode: GroupTypesEnum
}
export type GroupTypesEnum = 'coaching' | 'search-and-match-external' | 'talent-pool'
export type CustomPage_GroupOutSchema_ = {
  items: Array<GroupOutSchema>
  pageInfo: PageInfo
}
export type GroupOutSchema = {
  groupId: string
  organizationId: string
  groupName: string
  groupDescription?: ((string | null) | Array<string | null>) | undefined
  groupStartDate?: ((string | null) | Array<string | null>) | undefined
  groupEndDate?: ((string | null) | Array<string | null>) | undefined
  dateCreated: string
  numberOfMembers?: number | undefined
  groupTypeCode: GroupTypesEnum
  groupModuleCode: ViewpointModulesEnum
  readOnly?: boolean | undefined
  managedByOrganizationName: string
  groupLogoUrl: (string | null) | Array<string | null>
}
export type ViewpointModulesEnum =
  | 'skillscv-admin-module'
  | 'coaching-module'
  | 'job-market-analysis-module'
  | 'search-and-match-module'
  | 'search-and-match-plus-module'
  | 'talent-pool-manager-module'
  | 'organization-set-up-module'
export type CustomPage_GroupTypeOutSchema_ = {
  items: Array<GroupTypeOutSchema>
  pageInfo: PageInfo
}
export type CustomPage_JobApplicationCandidateOutSchema_ = {
  items: Array<JobApplicationCandidateOutSchema>
  pageInfo: PageInfo
}
export type JobApplicationCandidateOutSchema = {
  skillscvSharedId: string
  email?: ((string | null) | Array<string | null>) | undefined
  organizationNm?: ((string | null) | Array<string | null>) | undefined
  lastModifiedDate: string
  jobApplicationCvStatus?: JobApplicationCVStatusEnum | undefined
  jobApplicationCvLink?: ((string | null) | Array<string | null>) | undefined
  contactedCandidate?: boolean | undefined
}
export type JobApplicationCVStatusEnum =
  | 'geselecteerd'
  | 'wacht op reactie'
  | 'afgewezen'
  | 'aangemaakt'
  | 'gesloten'
export type CustomPage_JobApplicationOutSchema_ = {
  items: Array<JobApplicationOutSchema>
  pageInfo: PageInfo
}
export type JobApplicationOutSchema = {
  profession?: ((string | null) | Array<string | null>) | undefined
  jobOfferCode: string
  applicationDate: string
  candidateName?: ((string | null) | Array<string | null>) | undefined
  initiatedBy: InitiatedByEnum
  applicationStatus: JobApplicationStateEnum
  applicationLastModifiedDate: string
  applicationId: string
  applicationCvLink?: ((string | null) | Array<string | null>) | undefined
  candidateClosedPool?:
    | (
        | (SearchAndMatchCandidatesClosedPoolOutSchema | null)
        | Array<SearchAndMatchCandidatesClosedPoolOutSchema | null>
      )
    | undefined
}
export type InitiatedByEnum = 'hiring-manager' | 'job-seeker'
export type JobApplicationStateEnum =
  | 'continue-to-apply'
  | 'awaiting-reply'
  | 'request-for-contact'
  | 'profile-has-been-shared'
  | 'abort-job-seeker-removed-application'
  | 'abort-job-seeker-ignores-anonymous-cv'
  | 'abort-job-seeker-ignores-full-cv'
  | 'abort-hiring-party-rejects-anonymous-cv'
  | 'abort-hiring-party-ignores-anonymous-cv'
  | 'abort-job-digger-closed-job'
  | 'closed-shared-full-cv'
  | 'abort-job-seeker-reply-to-invite-hiring-manager'
  | 'reply-to-invite-from-hiring-manager'
export type SearchAndMatchCandidatesClosedPoolOutSchema = {
  remainingDays: number
  talentPoolName: string
}
export type CustomPage_OrganizationGroupClusterOutSchema_ = {
  items: Array<OrganizationGroupClusterOutSchema>
  pageInfo: PageInfo
}
export type OrganizationGroupClusterOutSchema = {
  organizationGroupId: string
  participatingOrganizationId: string
  groupId: string
  participatingOrganizationName: string
  dateCreated: string
  isAdmin: boolean
}
export type CustomPage_OrganizationOutSchema_ = {
  items: Array<skillscv_analytics_web__organizations__schemas__OrganizationOutSchema>
  pageInfo: PageInfo
}
export type skillscv_analytics_web__organizations__schemas__OrganizationOutSchema = {
  organizationSubdomain?: ((string | null) | Array<string | null>) | undefined
  organizationLightLogoUrl?: ((string | null) | Array<string | null>) | undefined
  organizationDarkLogoUrl?: ((string | null) | Array<string | null>) | undefined
  organizationId: string
  organizationName: string
  organizationCode: string
  brinNumber?: ((string | null) | Array<string | null>) | undefined
  organizationType: (OrganizationTypeOutSchema | null) | Array<OrganizationTypeOutSchema | null>
  numberOfGroups?: ((number | null) | Array<number | null>) | undefined
  numberOfInvitedJobSeekers?: ((number | null) | Array<number | null>) | undefined
  numberOfActiveJobSeekers?: ((number | null) | Array<number | null>) | undefined
  organizationActiveStatus?: StatusEnum | undefined
  isActive: boolean
  modules: Array<ViewpointModuleOutSchema>
  organizationSubdomainUrl?: string | undefined
  theme: (OrganizationThemeOutSchema | null) | Array<OrganizationThemeOutSchema | null>
  emailSenderName?: ((string | null) | Array<string | null>) | undefined
  email?: ((string | null) | Array<string | null>) | undefined
  idEcommerce?: ((string | null) | Array<string | null>) | undefined
  kycIsFinished?: ((boolean | null) | Array<boolean | null>) | undefined
  vacancyDetails?:
    | (
        | (Array<OrganizationVacancyConfigurationOutSchema> | null)
        | Array<Array<OrganizationVacancyConfigurationOutSchema> | null>
      )
    | undefined
  oneInOneOutFlag: boolean
  numberOfRegisteredNewJobSeekers: number
  numberOfActivatedNewJobSeekers: number
  numberOfCandidatesTotal: number
  organizationLogoUrl: (string | null) | Array<string | null>
}
export type OrganizationTypeOutSchema = {
  organizationTypeId: string
  organizationType: OrganizationTypesEnum
  isActive: boolean
}
export type OrganizationTypesEnum =
  | 'SCHOOL'
  | 'MUNICIPALITY'
  | 'RECRUITMENT'
  | 'SKILLSCV'
  | 'UITZENDBUREAU'
  | 'OVERHEID'
  | 'COMMERCIEEL BEDRIJF'
export type StatusEnum = 'Active' | 'Inactive'
export type ViewpointModuleOutSchema = {
  moduleId: string
  moduleCode: ViewpointModulesEnum
}
export type OrganizationVacancyConfigurationOutSchema = {
  organizationId: string
  vacancyUrl: string
  vacancyContactEmail?: ((string | null) | Array<string | null>) | undefined
  vacancyCompanyName?: ((string | null) | Array<string | null>) | undefined
  organizationVacancyConfigurationId: string
}
export type CustomPage_OrganizationTypeOutSchema_ = {
  items: Array<OrganizationTypeOutSchema>
  pageInfo: PageInfo
}
export type CustomPage_ProfessionOutSchema_ = {
  items: Array<skillscv_analytics_web__analytics__profession_analytics__schemas__ProfessionOutSchema>
  pageInfo: PageInfo
}
export type skillscv_analytics_web__analytics__profession_analytics__schemas__ProfessionOutSchema =
  {
    bocCode: string
    professionName: string
  }
export type CustomPage_RoleOutSchema_ = {
  items: Array<RoleOutSchema>
  pageInfo: PageInfo
}
export type RoleOutSchema = {
  id: string
  roleName: RolesEnum
}
export type RolesEnum =
  | 'OrganizationAdmin'
  | 'OrganizationSetupAdmin'
  | 'OrganizationStaff'
  | 'ViewAPIDocs'
  | 'SkillsCVStaff'
  | 'SkillsCVSuperAdmin'
  | 'ReadOnlyDemoUser'
  | 'WhiteLabelLiteUser'
export type CustomPage_SearchAndMatchCandidatesOutSchema_ = {
  items: Array<SearchAndMatchCandidatesOutSchema>
  pageInfo: PageInfo
}
export type SearchAndMatchCandidatesOutSchema = {
  skillscvId: string
  //cvJobSimilarityScore?: ((number | null) | Array<number | null>) | undefined
  //numberMatchingSkills: number
  candidateCode: string
  openApplication?: boolean | undefined
  candidateClosedPool?:
    | (
        | (SearchAndMatchCandidatesClosedPoolOutSchema | null)
        | Array<SearchAndMatchCandidatesClosedPoolOutSchema | null>
      )
    | undefined
  matchingDetails: SearchAndMatchMatchingDetailsOutSchema
  candidateMatchStatus: CandidateMatchStatusEnum
  freeTextSearchResult?:
    | ((FreeTextSearchOutSchema | null) | Array<FreeTextSearchOutSchema | null>)
    | undefined
}
export type SearchAndMatchMatchingDetailsOutSchema = {
  numberMatchingSkills: number
  jobTotalNumberSkills: number
  matchScore: number
  numberMatchingExperiences: number
}
export type CandidateMatchStatusEnum =
  | 'already-applied'
  | 'maybe-a-match'
  | 'not-a-match'
  | 'no-status'
export type FreeTextSearchOutSchema = Partial<{
  professionMatch:
    | (Array<FreeTextSearchProfessionOutSchema> | null)
    | Array<Array<FreeTextSearchProfessionOutSchema> | null>
  skillMatch:
    | (Array<FreeTextSearchSkillOutSchema> | null)
    | Array<Array<FreeTextSearchSkillOutSchema> | null>
}>
export type FreeTextSearchProfessionOutSchema = {
  cvProfessionName: string
}
export type FreeTextSearchSkillOutSchema = {
  cvSkillName: string
}
export type CustomPage_SearchAndMatchOutSchema_ = {
  items: Array<SearchAndMatchOutSchema>
  pageInfo: PageInfo
}
export type SearchAndMatchOutSchema = {
  memberId: string
  skillscvId: string
  email: string
  telephoneNumber?: ((string | null) | Array<string | null>) | undefined
  candidateName?: ((string | null) | Array<string | null>) | undefined
  dateInvited: string
  inviteLinkCompanyName?: ((string | null) | Array<string | null>) | undefined
  cvJobSimilarityScore?: ((number | null) | Array<number | null>) | undefined
  numberMatchingSkills: number
}
export type CustomPage_SearchAndMatchPlusTalentPoolsOutSchema_ = {
  items: Array<SearchAndMatchPlusTalentPoolsOutSchema>
  pageInfo: PageInfo
}
export type SearchAndMatchPlusTalentPoolsOutSchema = {
  groupId: string
  groupName: string
}
export type CustomPage_SearchAndMatchSkillsOutSchema_ = {
  items: Array<SearchAndMatchSkillsOutSchema>
  pageInfo: PageInfo
}
export type SearchAndMatchSkillsOutSchema = {
  id: string
  skillNm: string
}
export type CustomPage_SkillsCVJobApplicationInsightsOutSchema_ = {
  items: Array<SkillsCVJobApplicationInsightsOutSchema>
  pageInfo: PageInfo
}
export type SkillsCVJobApplicationInsightsOutSchema = {
  applicationYear: number
  applicationMonth?: number | undefined | null
  applicationWeek?: number | undefined | null
  applicationDay?: number | undefined | null
  totalNumberOfApplications?: number | undefined
  cvCompleteness: SkillsCVProfileCompletenessOutSchema
  applicationState?:
    | (
        | (Array<SkillsCVJobApplicationStateOutSchema> | null)
        | Array<Array<SkillsCVJobApplicationStateOutSchema> | null>
      )
    | undefined
}
export type SkillsCVProfileCompletenessOutSchema = Partial<{
  completenessCvLess10: number
  completenessCv10: number
  completenessCv20: number
  completenessCv30: number
  completenessCv40: number
  completenessCv50: number
  completenessCv60: number
  completenessCv70: number
  completenessCv80: number
  completenessCv90: number
  completenessCv100: number
}>
export type SkillsCVJobApplicationStateOutSchema = {
  stateCode: string
  numberOfApplications: number
}
export type CustomPage_SkillsCVProfileInsightsOutSchema_ = {
  items: Array<SkillsCVProfileInsightsOutSchema>
  pageInfo: PageInfo
}
export type SkillsCVProfileInsightsOutSchema = {
  totalNumberOfUsers?: number | undefined
  totalNumberOfActiveUsers?: number | undefined
  cvCreationYear: number
  cvCreationMonth?: number | undefined | null
  cvCreationWeek?: number | undefined | null
  cvCreationDay?: number | undefined | null
  cvCompleteness: SkillsCVProfileCompletenessOutSchema
  skillsCompleteness100?: number | undefined
  profileCompleteness100?: number | undefined
  certificateCompleteness100?: number | undefined
  languageCompleteness100?: number | undefined
  hobbyCompleteness100?: number | undefined
  educationCompleteness100?: number | undefined
  workExperienceCompleteness100?: number | undefined
}
export type CustomPage_SkillsOutSchema_ = {
  items: Array<skillscv_analytics_web__analytics__profession_analytics__schemas__SkillsOutSchema>
  pageInfo: PageInfo
}
export type skillscv_analytics_web__analytics__profession_analytics__schemas__SkillsOutSchema = {
  skillId: string
  name: string
  percentage: number
}
export type CustomPage_ViewpointClusterAvailableOrganizationOutSchema_ = {
  items: Array<ViewpointClusterAvailableOrganizationOutSchema>
  pageInfo: PageInfo
}
export type ViewpointClusterAvailableOrganizationOutSchema = {
  organizationId: string
  organizationName: string
}
export type CustomPage_ViewpointExistingUserOutSchema_ = {
  items: Array<ViewpointExistingUserOutSchema>
  pageInfo: PageInfo
}
export type ViewpointExistingUserOutSchema = {
  firstName: (string | null) | Array<string | null>
  lastName: (string | null) | Array<string | null>
  email: string
  userId: string
  status?: UserStatusEnum | undefined
  organizations: Array<skillscv_analytics_web__users__schemas__OrganizationOutSchema>
}
export type UserStatusEnum = 'Active' | 'Inactive' | 'Needs to reset password'
export type skillscv_analytics_web__users__schemas__OrganizationOutSchema = {
  organizationId: string
  organizationName: string
  organizationCode: string
  roles: Array<RolesEnum>
  modules: Array<ViewpointModulesEnum>
  kycIsFinished?: ((boolean | null) | Array<boolean | null>) | undefined
  idEcommerce?: ((string | null) | Array<string | null>) | undefined
  organizationOpenLinkId: string
  oneInOneOutFlag: boolean
  organizationOpenLinkIdUrlSafe: string
}
export type CustomPage_ViewpointModuleOutSchema_ = {
  items: Array<ViewpointModuleOutSchema>
  pageInfo: PageInfo
}
export type EcommerceSubscription = {
  maxNumberOfUsers: number
  maxNumberOfCandidates: number
  status: EcommerceStatus
  offerName: string
}
export type EcommerceStatus = 0 | 1 | 2 | 3 | 4
export type GeoLocationInSchema = {
  geoLocation: (Position2D | Position3D) | Array<Position2D | Position3D>
  distance?: number | undefined
}
export type Position2D = Array<any>
export type Position3D = Array<any>
export type CustomPage_ViewpointOrganizationGroupClusterOutSchema_ = {
  items: Array<ViewpointOrganizationGroupClusterOutSchema>
  pageInfo: PageInfo
}
export type ViewpointOrganizationGroupClusterOutSchema = {
  organizationGroupId: string
  participatingOrganizationId: string
  groupId: string
  participatingOrganizationName: string
  dateCreated: string
  isAdmin: boolean
}
export type GroupAddMembersInSchema = {
  members: Array<MemberInSchema>
}
export type MemberInSchema = {
  email: string
  name?: ((string | null) | Array<string | null>) | undefined
  inviteLinkId?: ((string | null) | Array<string | null>) | undefined
}
export type GroupDetailedOutSchema = {
  groupId: string
  groupName: string
  organizationGroupCode: string
  organizationId: string
  managedBy: Array<AdminMemberOutSchema>
  numberOfMembers: number
  groupType: (GroupTypeOutSchema | null) | Array<GroupTypeOutSchema | null>
  skillsOrdered?:
    | (
        | (Array<skillscv_analytics_web__group__schemas__group_v1__SkillsOutSchema> | null)
        | Array<Array<skillscv_analytics_web__group__schemas__group_v1__SkillsOutSchema> | null>
      )
    | undefined
  numberOfFilteredMembers: number
  members: Array<MemberExtendedOutSchema>
}
export type skillscv_analytics_web__group__schemas__group_v1__SkillsOutSchema = {
  skillId: string
  name?: ((string | null) | Array<string | null>) | undefined
  skillCategorySource?: ((string | null) | Array<string | null>) | undefined
  numberOfOccurrences: number
}
export type MemberExtendedOutSchema = {
  email: string
  memberId: string
  name: (string | null) | Array<string | null>
  cvDetails: (Array<CVDetailsOutSchema> | null) | Array<Array<CVDetailsOutSchema> | null>
  inviteEmailStatus?: ((EmailStatusEnum | null) | Array<EmailStatusEnum | null>) | undefined
}
export type GroupInSchema = {
  groupName: string
  organizationId: string
  groupTypeId: string
  moduleId: string
  members: Array<MemberInSchema>
}
export type MemberDetailedOutSchema = {
  email: string
  memberId: string
  name: (string | null) | Array<string | null>
  cvDetails: (Array<CVDetailsOutSchema> | null) | Array<Array<CVDetailsOutSchema> | null>
  inviteEmailStatus?: ((EmailStatusEnum | null) | Array<EmailStatusEnum | null>) | undefined
  skills?:
    | ((Array<SkillsGeneralOutSchema> | null) | Array<Array<SkillsGeneralOutSchema> | null>)
    | undefined
}
export type SkillsGeneralOutSchema = {
  skillId: string
  name?: ((string | null) | Array<string | null>) | undefined
  skillCategorySource?: ((string | null) | Array<string | null>) | undefined
}
export type OrganizationCreateInSchema = {
  organizationName: string
  organizationCode?: ((string | null) | Array<string | null>) | undefined
  organizationTypeId: string
  brinNumber?: ((string | null) | Array<string | null>) | undefined
  organizationSubdomain?: ((string | null) | Array<string | null>) | undefined
  theme?: ((OrganizationThemeInSchema | null) | Array<OrganizationThemeInSchema | null>) | undefined
  emailSenderName?: ((string | null) | Array<string | null>) | undefined
  email?: ((string | null) | Array<string | null>) | undefined
  vacancyDetails?:
    | (
        | (Array<OrganizationVacanciesDetailSchema> | null)
        | Array<Array<OrganizationVacanciesDetailSchema> | null>
      )
    | undefined
  organizationLogoBase64?: ((string | null) | Array<string | null>) | undefined
  modules: Array<OrganizationModuleInSchema>
  oneInOneOutFlag?: ((boolean | null) | Array<boolean | null>) | undefined
}
export type OrganizationThemeInSchema = Partial<{
  primaryColor: (string | null) | Array<string | null>
  secondaryColor: (string | null) | Array<string | null>
  tertiaryColor: (string | null) | Array<string | null>
  otherColor: (string | null) | Array<string | null>
}>
export type OrganizationVacanciesDetailSchema = {
  vacancyUrl: string
  vacancyContactEmail?: ((string | null) | Array<string | null>) | undefined
  vacancyCompanyName?: ((string | null) | Array<string | null>) | undefined
}
export type OrganizationModuleInSchema = {
  moduleId: string
}
export type OrganizationInsightsOutSchema = {
  ecommerceSubscription?: EcommerceSubscription | null | undefined
  // | ((EcommerceSubscription | null) | Array<EcommerceSubscription | null>)
  // | undefined
  oneInOneOutFlag: boolean
  numberOfUsers: number
  numberOfCandidatesThisMonth: number
  numberOfCandidatesTotal: number
  numberOfRegisteredNewJobSeekers: number
}
export type OrganizationUpdateInSchema = {
  organizationName: string
  organizationCode: string
  organizationTypeId: string
  brinNumber?: ((string | null) | Array<string | null>) | undefined
  organizationSubdomain?: ((string | null) | Array<string | null>) | undefined
  theme?: ((OrganizationThemeInSchema | null) | Array<OrganizationThemeInSchema | null>) | undefined
  emailSenderName?: ((string | null) | Array<string | null>) | undefined
  email?: ((string | null) | Array<string | null>) | undefined
  oneInOneOutFlag?: ((boolean | null) | Array<boolean | null>) | undefined
}
export type OwnOrganizationUpdateInSchema = {
  organizationName: string
  emailSenderName: string
  email: string
  vacancyDetails: Array<OrganizationVacanciesDetailNewSchema>
}
export type OrganizationVacanciesDetailNewSchema = {
  vacancyUrl: string
}
export type SearchAndMatchAnonymizedCVOutSchema = {
  skillsCvId: string
  user:
    | (SearchAndMatchCandidateUserOutSchema | null)
    | Array<SearchAndMatchCandidateUserOutSchema | null>
  cvProfile:
    | (SearchAndMatchCandidateProfileOutSchema | null)
    | Array<SearchAndMatchCandidateProfileOutSchema | null>
  cvAddress: (SkillsCVAddressOutSchema | null) | Array<SkillsCVAddressOutSchema | null>
  cvItems?:
    | ((SkillsCVLineItemsOutSchema | null) | Array<SkillsCVLineItemsOutSchema | null>)
    | undefined
  organization?:
    | ((CVOrganizationOutSchema | null) | Array<CVOrganizationOutSchema | null>)
    | undefined
}
export type SearchAndMatchCandidateUserOutSchema = Partial<{
  userId: string | null
  email: (string | null) | Array<string | null>
  preferredName: (string | null) | Array<string | null>
}>
export type SearchAndMatchCandidateProfileOutSchema = Partial<{
  firstNm: (string | null) | Array<string | null>
  prefixNm: (string | null) | Array<string | null>
  lastNm: (string | null) | Array<string | null>
  dateOfBirth: (string | null) | Array<string | null>
  workingHours: (number | null) | Array<number | null>
  willingToTravel: (boolean | null) | Array<boolean | null>
  telephoneNumber: (string | null) | Array<string | null>
  aboutMe: (string | null) | Array<string | null>
  lastModifiedDt: (string | null) | Array<string | null>
  profilePhoto3x: (string | null) | Array<string | null>
  profilePhoto2x: (string | null) | Array<string | null>
  profilePhotoCircle2x: (string | null) | Array<string | null>
  profilePhoto1x: (string | null) | Array<string | null>
  skillsCvId: (string | null) | Array<string | null>
  gender: (string | null) | Array<string | null>
}>
export type SearchAndMatchCandidateFilterInSchema = Partial<{
  educationDegreeCategory: (Array<string> | null) | Array<Array<string> | null>
  location: (GeoLocationInSchema | null) | Array<GeoLocationInSchema | null>
  privateFilter: (boolean | null) | Array<boolean | null>
  groupId: (string | null) | Array<string | null>
  candidateMatchStatus: (CandidateMatchStatusEnum | null) | Array<CandidateMatchStatusEnum | null>
  previousExperienceRequired: (boolean | null) | Array<boolean | null>
}>
export type SearchAndMatchInSchema = Partial<{
  educationDegreeCategory: (Array<string> | null) | Array<Array<string> | null>
  location: (SearchAndMatchLocationInSchema | null) | Array<SearchAndMatchLocationInSchema | null>
}>
export type SearchAndMatchLocationInSchema = {
  geoLocation: (Position2D | Position3D) | Array<Position2D | Position3D>
  distance?: number | undefined
}
export type SkillsCVFullOutSchema = {
  skillsCvId: string
  user?: ((UserOutSchema | null) | Array<UserOutSchema | null>) | undefined
  cvProfile?:
    | ((SkillsCVProfileOutSchema | null) | Array<SkillsCVProfileOutSchema | null>)
    | undefined
  cvAddress?:
    | ((SkillsCVAddressOutSchema | null) | Array<SkillsCVAddressOutSchema | null>)
    | undefined
  cvItems?:
    | ((SkillsCVLineItemsOutSchema | null) | Array<SkillsCVLineItemsOutSchema | null>)
    | undefined
  organization?:
    | ((CVOrganizationOutSchema | null) | Array<CVOrganizationOutSchema | null>)
    | undefined
}
export type UserOutSchema = {
  userId: string | null
  email?: ((string | null) | Array<string | null>) | undefined
  preferredName?: ((string | null) | Array<string | null>) | undefined
}
export type SkillsCVProfileOutSchema = Partial<{
  firstNm: (string | null) | Array<string | null>
  prefixNm: (string | null) | Array<string | null>
  lastNm: (string | null) | Array<string | null>
  dateOfBirth: (string | null) | Array<string | null>
  workingHours: (number | null) | Array<number | null>
  willingToTravel: (boolean | null) | Array<boolean | null>
  telephoneNumber: (string | null) | Array<string | null>
  aboutMe: (string | null) | Array<string | null>
  lastModifiedDt: (string | null) | Array<string | null>
  profilePhoto3x: (string | null) | Array<string | null>
  profilePhoto2x: (string | null) | Array<string | null>
  profilePhotoCircle2x: (string | null) | Array<string | null>
  profilePhoto1x: (string | null) | Array<string | null>
  skillsCvId: (string | null) | Array<string | null>
  gender: (string | null) | Array<string | null>
}>
export type SkillsCVTotalInsightsOutSchema = {
  totalNumberOfUsers?: number | undefined
  totalNumberOfActiveUsers?: number | undefined
  totalNumberOfApplications?: number | undefined
  applicationState?:
    | (
        | (Array<SkillsCVJobApplicationStateOutSchema> | null)
        | Array<Array<SkillsCVJobApplicationStateOutSchema> | null>
      )
    | undefined
  cvCompleteness: SkillsCVProfileCompletenessOutSchema
}
export type ViewpointModuleGroupTypeSchema = {
  groupTypeCode: GroupTypesEnum
  groupTypeId: string
}
export type ViewpointModuleWithGroupTypesOutSchema = {
  moduleId: string
  moduleCode: ViewpointModulesEnum
  groupTypes: Array<ViewpointModuleGroupTypeSchema>
}
export type ViewpointNewUserOutSchema = {
  email: string
  userId: string
  status?: UserStatusEnum | undefined
  organizations: Array<skillscv_analytics_web__users__schemas__OrganizationOutSchema>
}
export type skillscv_analytics_web__link__schemas__link_v1__LinkOutSchema = {
  groupId: string
  validityInDays: number
  hasEmail?: boolean | undefined
  linkName?: ((string | null) | Array<string | null>) | undefined
  companyName?: ((string | null) | Array<string | null>) | undefined
  linkId: string
  expirationDatetime: string
  dateCreated: string
  dateUpdated: string
  linkUrl: string
  organizationId: string
  groupCode?: string | undefined
  groupName?: string | undefined
  groupModuleCode?: ViewpointModulesEnum | undefined
  groupTypeCode?: GroupTypesEnum | undefined
  isActive: boolean
  linkIdUrlSafe: string
  numberOfClicks: number
}
export type skillscv_analytics_web__organizations__schemas__VacancyOutSchema = {
  uriHost?: ((string | null) | Array<string | null>) | undefined
  jobOfferCode: string
  profession?: ((string | null) | Array<string | null>) | undefined
  physicalLocation?: ((string | null) | Array<string | null>) | undefined
  organization?: ((string | null) | Array<string | null>) | undefined
  skill?: ((Array<string> | null) | Array<Array<string> | null>) | undefined
  jobOfferHtmlDescription?: ((string | null) | Array<string | null>) | undefined
  physicalLocationCoordinates?:
    | ((Position2D | Position3D | null) | Array<Position2D | Position3D | null>)
    | undefined
  educationDegreeMinimumRequired?: ((string | null) | Array<string | null>) | undefined
  educationDegreeCategory?: ((string | null) | Array<string | null>) | undefined
}
export type skillscv_analytics_web__search_and_match__schemas__SearchAndMatchCandidateOutSchema = {
  memberId: string
  skillscvId: string
  email: string
  telephoneNumber?: ((string | null) | Array<string | null>) | undefined
  candidateName?: ((string | null) | Array<string | null>) | undefined
  dateInvited: string
  inviteLinkCompanyName?: ((string | null) | Array<string | null>) | undefined
  cvJobSimilarityScore?: ((number | null) | Array<number | null>) | undefined
  numberMatchingSkills: number
  skillMatchingResult?:
    | (
        | (Array<skillscv_analytics_web__search_and_match__schemas__SkillOverlapOutSchema> | null)
        | Array<Array<skillscv_analytics_web__search_and_match__schemas__SkillOverlapOutSchema> | null>
      )
    | undefined
}
export type skillscv_analytics_web__search_and_match__schemas__SkillOverlapOutSchema = {
  jobSkillName: string
  cvSkillName?: ((string | null) | Array<string | null>) | undefined
  skillSimilarityScore: number
}
export type skillscv_analytics_web__search_and_match__schemas__VacanciesOutSchema = {
  items?:
    | Array<skillscv_analytics_web__search_and_match__schemas__VacancyDetailOutSchema>
    | undefined
  pageInfo: PageInfo
}
export type skillscv_analytics_web__search_and_match__schemas__VacancyDetailOutSchema = {
  jobOfferCode: string
  professionBocDetails?:
    | ((ProfessionBocOutSchema | null) | Array<ProfessionBocOutSchema | null>)
    | undefined
  profession?: ((string | null) | Array<string | null>) | undefined
  physicalLocation?: ((string | null) | Array<string | null>) | undefined
  url?: ((string | null) | Array<string | null>) | undefined
  dateFound: string
  dateDeactivated?: ((string | null) | Array<string | null>) | undefined
  active: (boolean | null) | Array<boolean | null>
  organization?: ((string | null) | Array<string | null>) | undefined
  daysSinceFound?: ((number | null) | Array<number | null>) | undefined
}
export type ProfessionBocOutSchema = Partial<{
  professionBocNm: (string | null) | Array<string | null>
}>
export type skillscv_analytics_web__search_and_match__schemas__VacancyOutSchema = {
  jobOfferCode: string
  profession?: ((string | null) | Array<string | null>) | undefined
  physicalLocation?: ((string | null) | Array<string | null>) | undefined
  organization?: ((string | null) | Array<string | null>) | undefined
  skill?: ((Array<string> | null) | Array<Array<string> | null>) | undefined
  jobOfferHtmlDescription?: ((string | null) | Array<string | null>) | undefined
  physicalLocationCoordinates?:
    | ((Position2D | Position3D | null) | Array<Position2D | Position3D | null>)
    | undefined
  educationDegreeMinimumRequired?: ((string | null) | Array<string | null>) | undefined
  educationDegreeCategory?: ((string | null) | Array<string | null>) | undefined
}
export type skillscv_analytics_web__search_and_match_plus__schemas__candidates__SearchAndMatchCandidateOutSchema =
  {
    skillscvId: string
    candidateCode: string
    openApplication?: boolean | undefined
    candidateClosedPool?:
      | (
          | (SearchAndMatchCandidatesClosedPoolOutSchema | null)
          | Array<SearchAndMatchCandidatesClosedPoolOutSchema | null>
        )
      | undefined
    matchingDetails: SearchAndMatchMatchingDetailsOutSchema
    candidateMatchStatus: CandidateMatchStatusEnum
    freeTextSearchResult?:
      | ((FreeTextSearchOutSchema | null) | Array<FreeTextSearchOutSchema | null>)
      | undefined
    sharedProfileType?:
      | ((CandidateSharedProfileTypeEnum | null) | Array<CandidateSharedProfileTypeEnum | null>)
      | undefined
    applicationCvLink?: ((string | null) | Array<string | null>) | undefined
    numberOfOpenApplications?: number | undefined
    talentPools?:
      | (
          | (Array<SearchAndMatchCandidateTalentPoolOutSchema> | null)
          | Array<Array<SearchAndMatchCandidateTalentPoolOutSchema> | null>
        )
      | undefined
    jobProfessionName: string
    skillMatchingResult?:
      | (
          | (Array<skillscv_analytics_web__search_and_match_plus__schemas__candidates__SkillOverlapOutSchema> | null)
          | Array<Array<skillscv_analytics_web__search_and_match_plus__schemas__candidates__SkillOverlapOutSchema> | null>
        )
      | undefined
    professionMatchingResult?:
      | (
          | (Array<ProfessionOverlapOutSchema> | null)
          | Array<Array<ProfessionOverlapOutSchema> | null>
        )
      | undefined
  }
export type skillscv_analytics_web__search_and_match_plus__schemas__candidates__SkillOverlapOutSchema =
  {
    jobSkillName: string
    cvSkillName?: ((string | null) | Array<string | null>) | undefined
    skillSimilarityScore: number
  }
export type CandidateSharedProfileTypeEnum = 'shared_anonymous_profile' | 'shared_full_profile'
export type SearchAndMatchCandidateTalentPoolOutSchema = {
  groupId: string
  groupName: string
  groupLogoUrl: (string | null) | Array<string | null>
}
export type ProfessionOverlapOutSchema = {
  cvProfessionName: string
  professionSimilarityScore: number
}
export type skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacanciesOutSchema =
  {
    items?:
      | Array<skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacancyDetailOutSchema>
      | undefined
    pageInfo: PageInfo
  }
export type skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacancyDetailOutSchema =
  {
    jobOfferCode: string
    profession?: ((string | null) | Array<string | null>) | undefined
    physicalLocation?: ((string | null) | Array<string | null>) | undefined
    url?: ((string | null) | Array<string | null>) | undefined
    dateFound: string
    dateDeactivated?: ((string | null) | Array<string | null>) | undefined
    active: (boolean | null) | Array<boolean | null>
    organization?: ((string | null) | Array<string | null>) | undefined
    daysSinceFound?: ((number | null) | Array<number | null>) | undefined
  }
export type skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacancyOutSchema = {
  jobOfferCode: string
  profession?: ((string | null) | Array<string | null>) | undefined
  physicalLocation?: ((string | null) | Array<string | null>) | undefined
  organization?: ((string | null) | Array<string | null>) | undefined
  skill?: ((Array<string> | null) | Array<Array<string> | null>) | undefined
  jobOfferHtmlDescription?: ((string | null) | Array<string | null>) | undefined
  physicalLocationCoordinates?:
    | ((Position2D | Position3D | null) | Array<Position2D | Position3D | null>)
    | undefined
  educationDegreeMinimumRequired?: ((string | null) | Array<string | null>) | undefined
  educationDegreeCategory?: ((string | null) | Array<string | null>) | undefined
  numberOfOpenApplications?: number | undefined
}
export type skillscv_analytics_web__utils__pagination__CustomPage_JobApplicationVacancyOutSchema___1 =
  {
    items: Array<skillscv_analytics_web__search_and_match_plus__schemas__job_applications__JobApplicationVacancyOutSchema>
    pageInfo: PageInfo
  }
export type skillscv_analytics_web__search_and_match_plus__schemas__job_applications__JobApplicationVacancyOutSchema =
  {
    profession?: ((string | null) | Array<string | null>) | undefined
    jobOfferCode: string
  }
export type skillscv_analytics_web__utils__pagination__CustomPage_JobApplicationVacancyOutSchema___2 =
  {
    items: Array<skillscv_analytics_web__search_and_match__schemas__JobApplicationVacancyOutSchema>
    pageInfo: PageInfo
  }
export type skillscv_analytics_web__search_and_match__schemas__JobApplicationVacancyOutSchema = {
  jobOfferCode: string
  profession?: ((string | null) | Array<string | null>) | undefined
  physicalLocationPlace?: ((string | null) | Array<string | null>) | undefined
  lastModifiedDate: string
}
export type skillscv_analytics_web__utils__pagination__CustomPage_LinkOutSchema___1 = {
  items: Array<skillscv_analytics_web__link__schemas__link_v1__LinkOutSchema>
  pageInfo: PageInfo
}
export type skillscv_analytics_web__utils__pagination__CustomPage_LinkOutSchema___2 = {
  items: Array<skillscv_analytics_web__talent_pool_manager__schemas__link__LinkOutSchema>
  pageInfo: PageInfo
}
export type skillscv_analytics_web__talent_pool_manager__schemas__link__LinkOutSchema = {
  linkId: string
  linkName?: ((string | null) | Array<string | null>) | undefined
  groupId: string
  organizationId: string
  expirationDatetime: string
  dateCreated: string
  dateUpdated: string
  linkUrl: string
  isActive: boolean
  validityInDays: number
  linkIdUrlSafe: string
  numberOfClicks: number
}

const OrganizationThemeInSchema: z.ZodType<OrganizationThemeInSchema> = z
  .object({
    primaryColor: z.union([z.string(), z.null()]).default('#390099'),
    secondaryColor: z.union([z.string(), z.null()]).default('#FF0054'),
    tertiaryColor: z.union([z.string(), z.null()]).default('#FFBD00'),
    otherColor: z.union([z.string(), z.null()]).default('#FF5400'),
  })
  .partial()
  .passthrough()
const OrganizationVacanciesDetailSchema: z.ZodType<OrganizationVacanciesDetailSchema> = z
  .object({
    vacancyUrl: z.string(),
    vacancyContactEmail: z.union([z.string(), z.null()]).optional(),
    vacancyCompanyName: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough()
const OrganizationModuleInSchema: z.ZodType<OrganizationModuleInSchema> = z
  .object({ moduleId: z.string() })
  .passthrough()
const OrganizationCreateInSchema: z.ZodType<OrganizationCreateInSchema> = z
  .object({
    organizationName: z.string(),
    organizationCode: z.string().min(1).max(4).nullable(),
    organizationTypeId: z.string(),
    brinNumber: z.union([z.string(), z.null()]).optional(),
    organizationSubdomain: z.union([z.string(), z.null()]).optional(),
    theme: z.union([OrganizationThemeInSchema, z.null()]).optional(),
    emailSenderName: z.union([z.string(), z.null()]).optional(),
    email: z.union([z.string(), z.null()]).optional(),
    vacancyDetails: z.union([z.array(OrganizationVacanciesDetailSchema), z.null()]).optional(),
    organizationLogoBase64: z.union([z.string(), z.null()]).optional(),
    modules: z.array(OrganizationModuleInSchema),
    oneInOneOutFlag: z.union([z.boolean(), z.null()]).optional().default(false),
  })
  .passthrough()
const OrganizationTypesEnum = z.enum([
  'SCHOOL',
  'MUNICIPALITY',
  'RECRUITMENT',
  'SKILLSCV',
  'UITZENDBUREAU',
  'OVERHEID',
  'COMMERCIEEL BEDRIJF',
])
const OrganizationTypeOutSchema: z.ZodType<OrganizationTypeOutSchema> = z
  .object({
    organizationTypeId: z.string(),
    organizationType: OrganizationTypesEnum,
    isActive: z.boolean(),
  })
  .passthrough()
const StatusEnum = z.enum(['Active', 'Inactive'])
const ViewpointModulesEnum = z.enum([
  'skillscv-admin-module',
  'coaching-module',
  'job-market-analysis-module',
  'search-and-match-module',
  'search-and-match-plus-module',
  'talent-pool-manager-module',
  'organization-set-up-module',
])
const ViewpointModuleOutSchema: z.ZodType<ViewpointModuleOutSchema> = z
  .object({ moduleId: z.string(), moduleCode: ViewpointModulesEnum })
  .passthrough()
const OrganizationThemeOutSchema: z.ZodType<OrganizationThemeOutSchema> = z
  .object({
    primaryColor: z.string().default('#390099'),
    secondaryColor: z.string().default('#FF0054'),
    tertiaryColor: z.string().default('#FFBD00'),
    otherColor: z.string().default('#FF5400'),
  })
  .partial()
  .passthrough()
const OrganizationVacancyConfigurationOutSchema: z.ZodType<OrganizationVacancyConfigurationOutSchema> =
  z
    .object({
      organizationId: z.string(),
      vacancyUrl: z.string(),
      vacancyContactEmail: z.union([z.string(), z.null()]).optional(),
      vacancyCompanyName: z.union([z.string(), z.null()]).optional(),
      organizationVacancyConfigurationId: z.string(),
    })
    .passthrough()
const skillscv_analytics_web__organizations__schemas__OrganizationOutSchema: z.ZodType<skillscv_analytics_web__organizations__schemas__OrganizationOutSchema> =
  z
    .object({
      organizationSubdomain: z.union([z.string(), z.null()]).optional(),
      organizationLightLogoUrl: z.union([z.string(), z.null()]).optional(),
      organizationDarkLogoUrl: z.union([z.string(), z.null()]).optional(),
      organizationId: z.string(),
      organizationName: z.string(),
      organizationCode: z.string().max(4),
      brinNumber: z.union([z.string(), z.null()]).optional(),
      organizationType: z.union([OrganizationTypeOutSchema, z.null()]),
      numberOfGroups: z.union([z.number(), z.null()]).optional().default(0),
      numberOfInvitedJobSeekers: z.union([z.number(), z.null()]).optional().default(0),
      numberOfActiveJobSeekers: z.union([z.number(), z.null()]).optional().default(0),
      organizationActiveStatus: StatusEnum.optional().default('Inactive'),
      isActive: z.boolean(),
      modules: z.array(ViewpointModuleOutSchema),
      organizationSubdomainUrl: z.string().optional(),
      theme: z.union([OrganizationThemeOutSchema, z.null()]),
      emailSenderName: z.union([z.string(), z.null()]).optional(),
      email: z.union([z.string(), z.null()]).optional(),
      idEcommerce: z.union([z.string(), z.null()]).optional(),
      kycIsFinished: z.union([z.boolean(), z.null()]).optional().default(false),
      vacancyDetails: z
        .union([z.array(OrganizationVacancyConfigurationOutSchema), z.null()])
        .optional(),
      oneInOneOutFlag: z.boolean(),
      numberOfRegisteredNewJobSeekers: z.number().int(),
      numberOfActivatedNewJobSeekers: z.number().int(),
      numberOfCandidatesTotal: z.number().int(),
      organizationLogoUrl: z.union([z.string(), z.null()]),
    })
    .passthrough()
const BadRequestMessageOutSchema = z.object({ detail: z.string() }).passthrough()
const UnauthorizedMessageOutSchema = z.object({ detail: z.string() }).passthrough()
const ForbiddenMessageOutSchema = z.object({ detail: z.string() }).passthrough()
const NotFoundMessageOutSchema = z.object({ detail: z.string() }).passthrough()
const ConflictMessageOutSchema = z.object({ detail: z.string() }).passthrough()
const ValidationErrorMessageOutSchema = z
  .object({ detail: z.string(), field: z.string() })
  .passthrough()
const LimitsErrorMessageOutSchema = z.object({ detail: z.string() }).passthrough()
const InternalErrorMessageOutSchema = z.object({ detail: z.string() }).passthrough()
const PageInfo: z.ZodType<PageInfo> = z
  .object({
    itemsTotal: z.number().int().gte(0).default(1),
    itemsOnPage: z.number().int().gte(0).lte(250).default(1),
    pageSize: z.number().int().gte(1).lte(250).default(1),
    pageCurrent: z.number().int().gte(1).default(1),
    pageMax: z.number().int().gte(1).default(1),
  })
  .partial()
  .passthrough()
const CustomPage_OrganizationOutSchema_: z.ZodType<CustomPage_OrganizationOutSchema_> = z
  .object({
    items: z.array(skillscv_analytics_web__organizations__schemas__OrganizationOutSchema),
    pageInfo: PageInfo,
  })
  .passthrough()
const OrganizationUpdateInSchema: z.ZodType<OrganizationUpdateInSchema> = z
  .object({
    organizationName: z.string(),
    organizationCode: z.string().min(1).max(4),
    organizationTypeId: z.string(),
    brinNumber: z.union([z.string(), z.null()]).optional(),
    organizationSubdomain: z.union([z.string(), z.null()]).optional(),
    theme: z.union([OrganizationThemeInSchema, z.null()]).optional(),
    emailSenderName: z.union([z.string(), z.null()]).optional(),
    email: z.union([z.string(), z.null()]).optional(),
    oneInOneOutFlag: z.union([z.boolean(), z.null()]).optional().default(false),
  })
  .passthrough()
const GenericMessageOutSchema = z.object({ detail: z.string() }).passthrough()
const OrganizationVacanciesDetailNewSchema: z.ZodType<OrganizationVacanciesDetailNewSchema> = z
  .object({ vacancyUrl: z.string() })
  .passthrough()
const OwnOrganizationUpdateInSchema: z.ZodType<OwnOrganizationUpdateInSchema> = z
  .object({
    organizationName: z.string(),
    emailSenderName: z.string().refine((value) => !/[@.]/.test(value), {
      message: 'Name cannot contain @ or .',
    }),
    email: z.string().email(),
    vacancyDetails: z
      .array(OrganizationVacanciesDetailNewSchema)
      .nonempty({ message: 'Please enter at least one URL' }),
  })
  .passthrough()
//const ConflictMessageOutSchema = z.object({ detail: z.string() }).passthrough()
const OrganizationLogoInSchema = z
  .object({ organizationLogoBase64: z.union([z.string(), z.null()]) })
  .partial()
  .passthrough()
const EcommerceStatus = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
])
const EcommerceSubscription: z.ZodType<EcommerceSubscription> = z
  .object({
    maxNumberOfUsers: z.number().int(),
    maxNumberOfCandidates: z.number().int(),
    status: EcommerceStatus,
    offerName: z.string(),
  })
  .passthrough()
const OrganizationInsightsOutSchema: z.ZodType<OrganizationInsightsOutSchema> = z
  .object({
    ecommerceSubscription: z.union([EcommerceSubscription, z.null()]).optional(),
    oneInOneOutFlag: z.boolean(),
    numberOfUsers: z.number().int(),
    numberOfCandidatesThisMonth: z.number().int(),
    numberOfCandidatesTotal: z.number().int(),
    numberOfRegisteredNewJobSeekers: z.number().int(),
  })
  .passthrough()
const OrganizationVacancyConfigurationInSchema = z
  .object({
    organizationId: z.string(),
    vacancyUrl: z.string(),
    vacancyContactEmail: z.union([z.string(), z.null()]).optional(),
    vacancyCompanyName: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough()
const OrganizationVacancyConfigurationUpdateInSchema = z
  .object({
    vacancyUrl: z.string(),
    vacancyContactEmail: z.union([z.string(), z.null()]).optional(),
    vacancyCompanyName: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough()
const OrganizationVacancyUrlInSchema = z
  .object({ url: z.string().min(1).max(2083).url() })
  .passthrough()
const Position2D = z.array(z.any())
const Position3D = z.array(z.any())
const skillscv_analytics_web__organizations__schemas__VacancyOutSchema = z
  .object({
    uriHost: z.union([z.string(), z.null()]).optional(),
    jobOfferCode: z.string(),
    profession: z.union([z.string(), z.null()]).optional(),
    physicalLocation: z.union([z.string(), z.null()]).optional(),
    organization: z.union([z.string(), z.null()]).optional(),
    skill: z
      .union([z.array(z.string()), z.null()])
      .optional()
      .default([]),
    jobOfferHtmlDescription: z.union([z.string(), z.null()]).optional(),
    physicalLocationCoordinates: z.union([Position2D, Position3D, z.null()]).optional(),
    educationDegreeMinimumRequired: z.union([z.string(), z.null()]).optional(),
    educationDegreeCategory: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough()
// export type skillscv_analytics_web__organizations__schemas__VacancyOutSchema = z.infer<
//   typeof skillscv_analytics_web__organizations__schemas__VacancyOutSchema
// >
const OrganizationEcommerceInSchema = z
  .object({
    organizationUuid: z.string(),
    userEmail: z.string().email(),
    organizationName: z.union([z.string(), z.null()]).optional(),
    userFirstName: z.union([z.string(), z.null()]).optional(),
    userLastName: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough()
const CustomPage_OrganizationTypeOutSchema_: z.ZodType<CustomPage_OrganizationTypeOutSchema_> = z
  .object({ items: z.array(OrganizationTypeOutSchema), pageInfo: PageInfo })
  .passthrough()
const ViewpointUserInSchema = z
  .object({
    email: z.string().email(),
    id: z.union([z.string(), z.null()]).optional(),
    organizationId: z.string(),
    roleId: z.string(),
  })
  .passthrough()
const UserStatusEnum = z.enum(['Active', 'Inactive', 'Needs to reset password'])
const RolesEnum = z.enum([
  'OrganizationAdmin',
  'OrganizationSetupAdmin',
  'OrganizationStaff',
  'ViewAPIDocs',
  'SkillsCVStaff',
  'SkillsCVSuperAdmin',
  'ReadOnlyDemoUser',
  'WhiteLabelLiteUser',
])
const skillscv_analytics_web__users__schemas__OrganizationOutSchema: z.ZodType<skillscv_analytics_web__users__schemas__OrganizationOutSchema> =
  z
    .object({
      organizationId: z.string(),
      organizationName: z.string(),
      organizationCode: z.string().max(4),
      roles: z.array(RolesEnum),
      modules: z.array(ViewpointModulesEnum),
      kycIsFinished: z.union([z.boolean(), z.null()]).optional().default(false),
      idEcommerce: z.union([z.string(), z.null()]).optional(),
      organizationOpenLinkId: z.string(),
      oneInOneOutFlag: z.boolean(),
      organizationOpenLinkIdUrlSafe: z.string(),
    })
    .passthrough()
const ViewpointNewUserOutSchema: z.ZodType<ViewpointNewUserOutSchema> = z
  .object({
    email: z.string().email(),
    userId: z.string(),
    status: UserStatusEnum.optional().default('Inactive'),
    organizations: z.array(skillscv_analytics_web__users__schemas__OrganizationOutSchema),
  })
  .passthrough()
const ViewpointExistingUserOutSchema: z.ZodType<ViewpointExistingUserOutSchema> = z
  .object({
    firstName: z.union([z.string(), z.null()]),
    lastName: z.union([z.string(), z.null()]),
    email: z.string().email(),
    userId: z.string(),
    status: UserStatusEnum.optional().default('Inactive'),
    organizations: z.array(skillscv_analytics_web__users__schemas__OrganizationOutSchema),
  })
  .passthrough()
const ViewpointUserUpdateSchema = z
  .object({ firstName: z.union([z.string(), z.null()]), lastName: z.union([z.string(), z.null()]) })
  .passthrough()
const CustomPage_ViewpointExistingUserOutSchema_: z.ZodType<CustomPage_ViewpointExistingUserOutSchema_> =
  z.object({ items: z.array(ViewpointExistingUserOutSchema), pageInfo: PageInfo }).passthrough()
const ViewpointRemoveUserFromOrgInSchema = z
  .object({ userId: z.string(), organizationId: z.string() })
  .passthrough()
const ViewpointAddUserRoleOrgInSchema = z
  .object({ userId: z.string(), organizationId: z.string(), roleId: z.string() })
  .passthrough()
const MemberInSchema: z.ZodType<MemberInSchema> = z
  .object({
    email: z.string().email(),
    name: z.union([z.string(), z.null()]).optional(),
    inviteLinkId: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough()
const GroupAddMembersInSchema: z.ZodType<GroupAddMembersInSchema> = z
  .object({ members: z.array(MemberInSchema) })
  .passthrough()
const MembershipUpdateSchema = z.object({ name: z.string() }).passthrough()
const AdminMemberOutSchema: z.ZodType<AdminMemberOutSchema> = z
  .object({
    email: z.string().email(),
    userId: z.string(),
    firstName: z.union([z.string(), z.null()]),
    lastName: z.union([z.string(), z.null()]),
  })
  .passthrough()
const GroupTypesEnum = z.enum(['coaching', 'search-and-match-external', 'talent-pool'])
const GroupTypeOutSchema: z.ZodType<GroupTypeOutSchema> = z
  .object({ groupTypeId: z.string(), groupTypeCode: GroupTypesEnum })
  .passthrough()
const skillscv_analytics_web__group__schemas__group_v1__SkillsOutSchema: z.ZodType<skillscv_analytics_web__group__schemas__group_v1__SkillsOutSchema> =
  z
    .object({
      skillId: z.string(),
      name: z.union([z.string(), z.null()]).optional().default('Unknown'),
      skillCategorySource: z.union([z.string(), z.null()]).optional().default('Unknown'),
      numberOfOccurrences: z.number().int(),
    })
    .passthrough()
const CVDetailsOutSchema: z.ZodType<CVDetailsOutSchema> = z
  .object({
    skillscvId: z.union([z.string(), z.null()]),
    completenessPercentage: z.union([z.number(), z.null()]).default(0),
    skillsCompleteness: z.boolean().default(false),
    workExperienceCompleteness: z.boolean().default(false),
    educationCompleteness: z.boolean().default(false),
    hobbyCompleteness: z.boolean().default(false),
    languageCompleteness: z.boolean().default(false),
    profileCompleteness: z.boolean().default(false),
  })
  .partial()
  .passthrough()
const EmailStatusEnum = z.enum([
  'There is no information about the delivery',
  'Delivered',
  'Unknown failure, ask the recipient to verify mail settings',
  "The recipient's email does not exist",
  "The recipient's mailbox is full",
])
const MemberExtendedOutSchema: z.ZodType<MemberExtendedOutSchema> = z
  .object({
    email: z.string().email(),
    memberId: z.string(),
    name: z.union([z.string(), z.null()]),
    cvDetails: z.union([z.array(CVDetailsOutSchema), z.null()]),
    inviteEmailStatus: z
      .union([EmailStatusEnum, z.null()])
      .optional()
      .default('There is no information about the delivery'),
  })
  .passthrough()
const GroupDetailedOutSchema: z.ZodType<GroupDetailedOutSchema> = z
  .object({
    groupId: z.string(),
    groupName: z.string(),
    organizationGroupCode: z.string(),
    organizationId: z.string(),
    managedBy: z.array(AdminMemberOutSchema),
    numberOfMembers: z.number().int(),
    groupType: z.union([GroupTypeOutSchema, z.null()]),
    skillsOrdered: z
      .union([z.array(skillscv_analytics_web__group__schemas__group_v1__SkillsOutSchema), z.null()])
      .optional()
      .default([]),
    numberOfFilteredMembers: z.number().int(),
    members: z.array(MemberExtendedOutSchema),
  })
  .passthrough()
const SkillsGeneralOutSchema: z.ZodType<SkillsGeneralOutSchema> = z
  .object({
    skillId: z.string(),
    name: z.union([z.string(), z.null()]).optional().default('Unknown'),
    skillCategorySource: z.union([z.string(), z.null()]).optional().default('Unknown'),
  })
  .passthrough()
const MemberDetailedOutSchema: z.ZodType<MemberDetailedOutSchema> = z
  .object({
    email: z.string().email(),
    memberId: z.string(),
    name: z.union([z.string(), z.null()]),
    cvDetails: z.union([z.array(CVDetailsOutSchema), z.null()]),
    inviteEmailStatus: z
      .union([EmailStatusEnum, z.null()])
      .optional()
      .default('There is no information about the delivery'),
    skills: z
      .union([z.array(SkillsGeneralOutSchema), z.null()])
      .optional()
      .default([]),
  })
  .passthrough()
const CreateSkillsCVAccountAndAddToGroupInSchema = z
  .object({ email: z.string().email() })
  .passthrough()
const MemberRedirectSkillsCVAppOutSchema = z
  .object({ redirectUrl: z.string().min(1).max(2083).url() })
  .passthrough()
const GroupAddAdminInSchema = z.object({ userId: z.string() }).passthrough()
const skillscv_analytics_web__link__schemas__link_v1__LinkInSchema = z
  .object({
    groupId: z.string(),
    validityInDays: z.union([z.number(), z.null()]).optional().default(365),
    hasEmail: z.boolean().optional().default(true),
    linkName: z.union([z.string(), z.null()]).optional(),
    companyName: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough()
const skillscv_analytics_web__link__schemas__link_v1__LinkOutSchema: z.ZodType<skillscv_analytics_web__link__schemas__link_v1__LinkOutSchema> =
  z
    .object({
      groupId: z.string(),
      validityInDays: z.number().int().gte(1).lte(365),
      hasEmail: z.boolean().optional().default(true),
      linkName: z.union([z.string(), z.null()]).optional(),
      companyName: z.union([z.string(), z.null()]).optional(),
      linkId: z.string(),
      expirationDatetime: z.string().datetime({ offset: true }),
      dateCreated: z.string().datetime({ offset: true }),
      dateUpdated: z.string().datetime({ offset: true }),
      linkUrl: z.string(),
      organizationId: z.string(),
      groupCode: z.string().optional(),
      groupName: z.string().optional(),
      groupModuleCode: ViewpointModulesEnum.optional(),
      groupTypeCode: GroupTypesEnum.optional(),
      isActive: z.boolean(),
      linkIdUrlSafe: z.string(),
      numberOfClicks: z.number().int(),
    })
    .passthrough()
const group_module_code = ViewpointModulesEnum.optional()
const group_type_code = GroupTypesEnum.optional()
const skillscv_analytics_web__utils__pagination__CustomPage_LinkOutSchema___1: z.ZodType<skillscv_analytics_web__utils__pagination__CustomPage_LinkOutSchema___1> =
  z
    .object({
      items: z.array(skillscv_analytics_web__link__schemas__link_v1__LinkOutSchema),
      pageInfo: PageInfo,
    })
    .passthrough()
const skillscv_analytics_web__link__schemas__link_v1__LinkUpdateSchema = z
  .object({
    linkName: z.union([z.string(), z.null()]),
    companyName: z.union([z.string(), z.null()]),
  })
  .partial()
  .passthrough()
const skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacancyDetailOutSchema: z.ZodType<skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacancyDetailOutSchema> =
  z
    .object({
      jobOfferCode: z.string(),
      profession: z.union([z.string(), z.null()]).optional(),
      physicalLocation: z.union([z.string(), z.null()]).optional(),
      url: z.union([z.string(), z.null()]).optional(),
      dateFound: z.string().datetime({ offset: true }),
      dateDeactivated: z.union([z.string(), z.null()]).optional(),
      active: z.union([z.boolean(), z.null()]),
      organization: z.union([z.string(), z.null()]).optional(),
      daysSinceFound: z.union([z.number(), z.null()]).optional().default(0),
    })
    .passthrough()
const skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacanciesOutSchema: z.ZodType<skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacanciesOutSchema> =
  z
    .object({
      items: z
        .array(
          skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacancyDetailOutSchema,
        )
        .optional()
        .default([]),
      pageInfo: PageInfo,
    })
    .passthrough()
const skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacancyOutSchema = z
  .object({
    jobOfferCode: z.string(),
    profession: z.union([z.string(), z.null()]).optional(),
    physicalLocation: z.union([z.string(), z.null()]).optional(),
    organization: z.union([z.string(), z.null()]).optional(),
    skill: z
      .union([z.array(z.string()), z.null()])
      .optional()
      .default([]),
    jobOfferHtmlDescription: z.union([z.string(), z.null()]).optional(),
    physicalLocationCoordinates: z.union([Position2D, Position3D, z.null()]).optional(),
    educationDegreeMinimumRequired: z.union([z.string(), z.null()]).optional(),
    educationDegreeCategory: z.union([z.string(), z.null()]).optional(),
    numberOfOpenApplications: z.number().int().optional().default(0),
  })
  .passthrough()

// export type skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacancyOutSchema =
//   z.infer<
//     typeof skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacancyOutSchema
//   >

const GeoLocationInSchema: z.ZodType<GeoLocationInSchema> = z
  .object({
    geoLocation: z.union([Position2D, Position3D]),
    distance: z.number().int().optional().default(25),
  })
  .passthrough()
const CandidateMatchStatusEnum = z.enum([
  'already-applied',
  'maybe-a-match',
  'not-a-match',
  'no-status',
])
const SearchAndMatchCandidateFilterInSchema: z.ZodType<SearchAndMatchCandidateFilterInSchema> = z
  .object({
    educationDegreeCategory: z.union([z.array(z.string()), z.null()]),
    location: z.union([GeoLocationInSchema, z.null()]),
    privateFilter: z.union([z.boolean(), z.null()]),
    groupId: z.union([z.string(), z.null()]),
    candidateMatchStatus: z.union([CandidateMatchStatusEnum, z.null()]),
    previousExperienceRequired: z.union([z.boolean(), z.null()]),
    freeTextSearch: z.union([z.string(), z.null()]),
  })
  .partial()
  .passthrough()
const SearchAndMatchCandidatesClosedPoolOutSchema: z.ZodType<SearchAndMatchCandidatesClosedPoolOutSchema> =
  z.object({ remainingDays: z.number().int(), talentPoolName: z.string() }).passthrough()
const SearchAndMatchMatchingDetailsOutSchema: z.ZodType<SearchAndMatchMatchingDetailsOutSchema> = z
  .object({
    numberMatchingSkills: z.number().int(),
    jobTotalNumberSkills: z.number().int(),
    matchScore: z.number(),
    numberMatchingExperiences: z.number().int(),
  })
  .passthrough()
const FreeTextSearchProfessionOutSchema: z.ZodType<FreeTextSearchProfessionOutSchema> = z
  .object({ cvProfessionName: z.string() })
  .passthrough()
const FreeTextSearchSkillOutSchema: z.ZodType<FreeTextSearchSkillOutSchema> = z
  .object({ cvSkillName: z.string() })
  .passthrough()
const FreeTextSearchOutSchema: z.ZodType<FreeTextSearchOutSchema> = z
  .object({
    professionMatch: z.union([z.array(FreeTextSearchProfessionOutSchema), z.null()]),
    skillMatch: z.union([z.array(FreeTextSearchSkillOutSchema), z.null()]),
  })
  .partial()
  .passthrough()
const SearchAndMatchCandidatesOutSchema: z.ZodType<SearchAndMatchCandidatesOutSchema> = z
  .object({
    skillscvId: z.string(),
    candidateCode: z.string(),
    openApplication: z.boolean().optional().default(false),
    candidateClosedPool: z
      .union([SearchAndMatchCandidatesClosedPoolOutSchema, z.null()])
      .optional(),
    matchingDetails: SearchAndMatchMatchingDetailsOutSchema,
    candidateMatchStatus: CandidateMatchStatusEnum,
    freeTextSearchResult: z.union([FreeTextSearchOutSchema, z.null()]).optional(),
  })
  .passthrough()
const CustomPage_SearchAndMatchCandidatesOutSchema_: z.ZodType<CustomPage_SearchAndMatchCandidatesOutSchema_> =
  z.object({ items: z.array(SearchAndMatchCandidatesOutSchema), pageInfo: PageInfo }).passthrough()
const skillscv_analytics_web__search_and_match_plus__schemas__candidates__SkillOverlapOutSchema: z.ZodType<skillscv_analytics_web__search_and_match_plus__schemas__candidates__SkillOverlapOutSchema> =
  z
    .object({
      jobSkillName: z.string(),
      cvSkillName: z.union([z.string(), z.null()]).optional(),
      skillSimilarityScore: z.number(),
    })
    .passthrough()
const CandidateSharedProfileTypeEnum = z.enum(['shared_anonymous_profile', 'shared_full_profile'])
const SearchAndMatchCandidateTalentPoolOutSchema: z.ZodType<SearchAndMatchCandidateTalentPoolOutSchema> =
  z
    .object({
      groupId: z.string(),
      groupName: z.string(),
      groupLogoUrl: z.union([z.string(), z.null()]),
    })
    .passthrough()
const ProfessionOverlapOutSchema: z.ZodType<ProfessionOverlapOutSchema> = z
  .object({ cvProfessionName: z.string(), professionSimilarityScore: z.number() })
  .passthrough()
const skillscv_analytics_web__search_and_match_plus__schemas__candidates__SearchAndMatchCandidateOutSchema: z.ZodType<skillscv_analytics_web__search_and_match_plus__schemas__candidates__SearchAndMatchCandidateOutSchema> =
  z
    .object({
      skillscvId: z.string(),
      candidateCode: z.string(),
      openApplication: z.boolean().optional().default(false),
      candidateClosedPool: z
        .union([SearchAndMatchCandidatesClosedPoolOutSchema, z.null()])
        .optional(),
      matchingDetails: SearchAndMatchMatchingDetailsOutSchema,
      candidateMatchStatus: CandidateMatchStatusEnum,
      freeTextSearchResult: z.union([FreeTextSearchOutSchema, z.null()]).optional(),
      sharedProfileType: z.union([CandidateSharedProfileTypeEnum, z.null()]).optional(),
      applicationCvLink: z.union([z.string(), z.null()]).optional(),
      numberOfOpenApplications: z.number().int().optional().default(0),
      talentPools: z
        .union([z.array(SearchAndMatchCandidateTalentPoolOutSchema), z.null()])
        .optional(),
      jobProfessionName: z.string(),
      skillMatchingResult: z
        .union([
          z.array(
            skillscv_analytics_web__search_and_match_plus__schemas__candidates__SkillOverlapOutSchema,
          ),
          z.null(),
        ])
        .optional(),
      professionMatchingResult: z.union([z.array(ProfessionOverlapOutSchema), z.null()]).optional(),
    })
    .passthrough()
const skillscv_analytics_web__search_and_match_plus__schemas__candidates__ViewpointSearchAndMatchFeedbackInSchema =
  z.object({ isRelevant: z.boolean() }).passthrough()
const skillscv_analytics_web__search_and_match_plus__schemas__candidates__ViewpointSearchAndMatchFeedbackOutSchema =
  z
    .object({ isRelevant: z.union([z.boolean(), z.null()]) })
    .partial()
    .passthrough()
const SearchAndMatchCandidateUserOutSchema: z.ZodType<SearchAndMatchCandidateUserOutSchema> = z
  .object({
    userId: z.union([z.string(), z.null()]),
    email: z.union([z.string(), z.null()]).default('****@****.***'),
    preferredName: z.union([z.string(), z.null()]).default('****'),
  })
  .partial()
  .passthrough()
const SearchAndMatchCandidateProfileOutSchema: z.ZodType<SearchAndMatchCandidateProfileOutSchema> =
  z
    .object({
      firstNm: z.union([z.string(), z.null()]).default('****'),
      prefixNm: z.union([z.string(), z.null()]),
      lastNm: z.union([z.string(), z.null()]).default('****'),
      dateOfBirth: z.union([z.string(), z.null()]),
      workingHours: z.union([z.number(), z.null()]),
      willingToTravel: z.union([z.boolean(), z.null()]),
      telephoneNumber: z.union([z.string(), z.null()]),
      aboutMe: z.union([z.string(), z.null()]),
      lastModifiedDt: z.union([z.string(), z.null()]),
      profilePhoto3x: z.union([z.string(), z.null()]),
      profilePhoto2x: z.union([z.string(), z.null()]),
      profilePhotoCircle2x: z.union([z.string(), z.null()]),
      profilePhoto1x: z.union([z.string(), z.null()]),
      skillsCvId: z.union([z.string(), z.null()]),
      gender: z.union([z.string(), z.null()]),
    })
    .partial()
    .passthrough()
const SkillsCVAddressOutSchema: z.ZodType<SkillsCVAddressOutSchema> = z
  .object({
    street: z.union([z.string(), z.null()]),
    houseNumber: z.union([z.string(), z.null()]),
    postalCode: z.union([z.string(), z.null()]),
    city: z.union([z.string(), z.null()]),
    state: z.union([z.string(), z.null()]),
    lastModifiedDt: z.union([z.string(), z.null()]),
    skillsCvId: z.union([z.string(), z.null()]),
  })
  .partial()
  .passthrough()
const skillscv_analytics_web__group__schemas__external_schemas__ProfessionOutSchema: z.ZodType<skillscv_analytics_web__group__schemas__external_schemas__ProfessionOutSchema> =
  z
    .object({ id: z.union([z.string(), z.null()]).optional(), professionNm: z.string().max(255) })
    .passthrough()
const SkillsCVLineItemRelatedSkillOutSchema: z.ZodType<SkillsCVLineItemRelatedSkillOutSchema> = z
  .object({ relatedSkillId: z.string(), relatedSkillsCvLineItemId: z.string() })
  .passthrough()
const SkillsCVLineItemExperienceOutSchema: z.ZodType<SkillsCVLineItemExperienceOutSchema> = z
  .object({
    skillsCvLineItemId: z.union([z.string(), z.null()]).optional(),
    profession: skillscv_analytics_web__group__schemas__external_schemas__ProfessionOutSchema,
    company: z.string().max(100),
    startD: z.union([z.string(), z.null()]).optional(),
    endD: z.union([z.string(), z.null()]).optional(),
    location: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    lastModifiedDt: z.union([z.string(), z.null()]).optional(),
    lineItemType: z.string(),
    relatedSkills: z
      .union([z.array(SkillsCVLineItemRelatedSkillOutSchema), z.null()])
      .optional()
      .default([]),
  })
  .passthrough()
const EducationOutSchema: z.ZodType<EducationOutSchema> = z
  .object({ educationNm: z.string().max(255), educationModeratedId: z.string() })
  .passthrough()
const EducationalInstitutionOutSchema: z.ZodType<EducationalInstitutionOutSchema> = z
  .object({
    id: z.union([z.string(), z.null()]),
    educationalInstitutionNm: z.union([z.string(), z.null()]),
  })
  .partial()
  .passthrough()
const EducationDegreeOutSchema: z.ZodType<EducationDegreeOutSchema> = z
  .object({ id: z.string(), degreeCode: z.union([z.string(), z.null()]).optional() })
  .passthrough()
const EducationFieldOfStudyOutSchema: z.ZodType<EducationFieldOfStudyOutSchema> = z
  .object({ id: z.string(), fieldOfStudyNm: z.string() })
  .passthrough()
const SkillsCVLineItemEducationOutSchema: z.ZodType<SkillsCVLineItemEducationOutSchema> = z
  .object({
    skillsCvLineItemId: z.union([z.string(), z.null()]).optional(),
    educationalInstitutionLocation: z.union([z.string(), z.null()]).optional(),
    startD: z.union([z.string(), z.null()]).optional(),
    endD: z.union([z.string(), z.null()]).optional(),
    lastModifiedDt: z.union([z.string(), z.null()]).optional(),
    educationCompleted: z.union([z.boolean(), z.null()]).optional(),
    lineItemType: z.string(),
    education: z.union([EducationOutSchema, z.null()]).optional(),
    educationalInstitution: EducationalInstitutionOutSchema,
    relatedSkills: z
      .union([z.array(SkillsCVLineItemRelatedSkillOutSchema), z.null()])
      .optional()
      .default([]),
    educationDegree: z.union([EducationDegreeOutSchema, z.null()]).optional(),
    educationFieldOfStudy: EducationFieldOfStudyOutSchema,
  })
  .passthrough()
const HobbyOutSchema: z.ZodType<HobbyOutSchema> = z
  .object({ hobbyNm: z.string().max(255), hobbyModeratedId: z.string() })
  .passthrough()
const SkillsCVLineItemHobbyOutSchema: z.ZodType<SkillsCVLineItemHobbyOutSchema> = z
  .object({
    skillsCvLineItemId: z.union([z.string(), z.null()]).optional(),
    hobby: HobbyOutSchema,
    lastModifiedDt: z.union([z.string(), z.null()]).optional(),
    lineItemType: z.string(),
    relatedSkills: z
      .union([z.array(SkillsCVLineItemRelatedSkillOutSchema), z.null()])
      .optional()
      .default([]),
  })
  .passthrough()
const SkillOutSchema: z.ZodType<SkillOutSchema> = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    skillCategory: z.union([z.string(), z.null()]).optional().default('LANGUAGE'),
    skillNm: z.string().max(255),
  })
  .passthrough()
const SkillsCVLineItemLanguageOutSchema: z.ZodType<SkillsCVLineItemLanguageOutSchema> = z
  .object({
    skillsCvLineItemId: z.union([z.string(), z.null()]).optional(),
    rating: z.union([z.number(), z.null()]).optional(),
    lastModifiedDt: z.union([z.string(), z.null()]).optional(),
    lineItemType: z.string(),
    language: SkillOutSchema,
  })
  .passthrough()
const SkillsCVLineItemSoftSkillKnowledgeOutSchema: z.ZodType<SkillsCVLineItemSoftSkillKnowledgeOutSchema> =
  z
    .object({
      skillsCvLineItemId: z.union([z.string(), z.null()]).optional(),
      skill: z.union([SkillOutSchema, z.null()]).optional(),
      rating: z.union([z.number(), z.null()]).optional(),
      lastModifiedDt: z.union([z.string(), z.null()]).optional(),
      lineItemType: z.string(),
    })
    .passthrough()
const SkillsCVLineItemCertificateOutSchema: z.ZodType<SkillsCVLineItemCertificateOutSchema> = z
  .object({
    skillsCvLineItemId: z.union([z.string(), z.null()]).optional(),
    certificate: z.union([SkillOutSchema, z.null()]).optional(),
    lastModifiedDt: z.union([z.string(), z.null()]).optional(),
    lineItemType: z.string(),
  })
  .passthrough()
const SkillsCVLineItemsOutSchema: z.ZodType<SkillsCVLineItemsOutSchema> = z
  .object({
    experience: z.union([z.array(SkillsCVLineItemExperienceOutSchema), z.null()]).default([]),
    education: z.union([z.array(SkillsCVLineItemEducationOutSchema), z.null()]).default([]),
    hobby: z.union([z.array(SkillsCVLineItemHobbyOutSchema), z.null()]).default([]),
    language: z.union([z.array(SkillsCVLineItemLanguageOutSchema), z.null()]).default([]),
    softSkillKnowledge: z
      .union([z.array(SkillsCVLineItemSoftSkillKnowledgeOutSchema), z.null()])
      .default([]),
    certificate: z.union([z.array(SkillsCVLineItemCertificateOutSchema), z.null()]).default([]),
  })
  .partial()
  .passthrough()
const CVOrganizationOutSchema: z.ZodType<CVOrganizationOutSchema> = z
  .object({
    organizationName: z.union([z.string(), z.null()]).default('SkillsCV'),
    theme: z.union([OrganizationThemeOutSchema, z.null()]).default({
      primaryColor: '#390099',
      secondaryColor: '#FF0054',
      tertiaryColor: '#FFBD00',
      otherColor: '#FF5400',
    }),
    organizationLogoUrl: z
      .union([z.string(), z.null()])
      .default(
        'https://skillscv-analytics-web.dev.skillscv.cusbi.nl/static/organisations/skillscv/logo-light.png',
      ),
  })
  .partial()
  .passthrough()
const SearchAndMatchAnonymizedCVOutSchema: z.ZodType<SearchAndMatchAnonymizedCVOutSchema> = z
  .object({
    skillsCvId: z.string(),
    user: z.union([SearchAndMatchCandidateUserOutSchema, z.null()]),
    cvProfile: z.union([SearchAndMatchCandidateProfileOutSchema, z.null()]),
    cvAddress: z.union([SkillsCVAddressOutSchema, z.null()]),
    cvItems: z.union([SkillsCVLineItemsOutSchema, z.null()]).optional().default({}),
    organization: z
      .union([CVOrganizationOutSchema, z.null()])
      .optional()
      .default({
        organizationName: 'SkillsCV',
        theme: {
          otherColor: '#FF5400',
          primaryColor: '#390099',
          secondaryColor: '#FF0054',
          tertiaryColor: '#FFBD00',
        },
        organizationLogoUrl:
          'https://skillscv-analytics-web.dev.skillscv.cusbi.nl/static/organisations/skillscv/logo-light.png',
      }),
  })
  .passthrough()
const AllCandidatesOutSchema: z.ZodType<AllCandidatesOutSchema> = z
  .object({
    skillscvId: z.string(),
    cvCreationDate: z.string().datetime({ offset: true }),
    candidateCode: z.string(),
  })
  .passthrough()
const CustomPage_AllCandidatesOutSchema_: z.ZodType<CustomPage_AllCandidatesOutSchema_> = z
  .object({ items: z.array(AllCandidatesOutSchema), pageInfo: PageInfo })
  .passthrough()
const JobApplicationViewpointStateEnum = z.enum([
  'shared_anonymous_profile',
  'requested_full_profile',
  'shared_full_profile',
  'closed',
])
const job_application_state = JobApplicationViewpointStateEnum.optional().default(
  'shared_anonymous_profile',
)
const InitiatedByEnum = z.enum(['hiring-manager', 'job-seeker'])
const initiated_by = InitiatedByEnum.optional()
const JobApplicationStateEnum = z.enum([
  'continue-to-apply',
  'awaiting-reply',
  'request-for-contact',
  'profile-has-been-shared',
  'abort-job-seeker-removed-application',
  'abort-job-seeker-ignores-anonymous-cv',
  'abort-job-seeker-ignores-full-cv',
  'abort-hiring-party-rejects-anonymous-cv',
  'abort-hiring-party-ignores-anonymous-cv',
  'abort-job-digger-closed-job',
  'closed-shared-full-cv',
  'abort-job-seeker-reply-to-invite-hiring-manager',
  'reply-to-invite-from-hiring-manager',
])
const JobApplicationOutSchema: z.ZodType<JobApplicationOutSchema> = z
  .object({
    profession: z.union([z.string(), z.null()]).optional(),
    jobOfferCode: z.string(),
    applicationDate: z.string().datetime({ offset: true }),
    candidateName: z.union([z.string(), z.null()]).optional(),
    initiatedBy: InitiatedByEnum,
    applicationStatus: JobApplicationStateEnum,
    applicationLastModifiedDate: z.string().datetime({ offset: true }),
    applicationId: z.string(),
    applicationCvLink: z.union([z.string(), z.null()]).optional(),
    candidateClosedPool: z
      .union([SearchAndMatchCandidatesClosedPoolOutSchema, z.null()])
      .optional(),
  })
  .passthrough()
const CustomPage_JobApplicationOutSchema_: z.ZodType<CustomPage_JobApplicationOutSchema_> = z
  .object({ items: z.array(JobApplicationOutSchema), pageInfo: PageInfo })
  .passthrough()
const skillscv_analytics_web__search_and_match_plus__schemas__job_applications__JobApplicationVacancyOutSchema: z.ZodType<skillscv_analytics_web__search_and_match_plus__schemas__job_applications__JobApplicationVacancyOutSchema> =
  z
    .object({ profession: z.union([z.string(), z.null()]).optional(), jobOfferCode: z.string() })
    .passthrough()
const skillscv_analytics_web__utils__pagination__CustomPage_JobApplicationVacancyOutSchema___1: z.ZodType<skillscv_analytics_web__utils__pagination__CustomPage_JobApplicationVacancyOutSchema___1> =
  z
    .object({
      items: z.array(
        skillscv_analytics_web__search_and_match_plus__schemas__job_applications__JobApplicationVacancyOutSchema,
      ),
      pageInfo: PageInfo,
    })
    .passthrough()
const SearchAndMatchPlusTalentPoolsOutSchema: z.ZodType<SearchAndMatchPlusTalentPoolsOutSchema> = z
  .object({ groupId: z.string(), groupName: z.string() })
  .passthrough()
const CustomPage_SearchAndMatchPlusTalentPoolsOutSchema_: z.ZodType<CustomPage_SearchAndMatchPlusTalentPoolsOutSchema_> =
  z
    .object({ items: z.array(SearchAndMatchPlusTalentPoolsOutSchema), pageInfo: PageInfo })
    .passthrough()
const skillscv_analytics_web__analytics__profession_analytics__schemas__ProfessionOutSchema: z.ZodType<skillscv_analytics_web__analytics__profession_analytics__schemas__ProfessionOutSchema> =
  z.object({ bocCode: z.string(), professionName: z.string() }).passthrough()
const CustomPage_ProfessionOutSchema_: z.ZodType<CustomPage_ProfessionOutSchema_> = z
  .object({
    items: z.array(
      skillscv_analytics_web__analytics__profession_analytics__schemas__ProfessionOutSchema,
    ),
    pageInfo: PageInfo,
  })
  .passthrough()
const SkillTypeEnum = z.enum([
  'SignificantSkills',
  'SkillsLanguages',
  'SkillsSoftSkills',
  'SkillsCertificates',
  'SkillsKnowledgeAndCompetences',
])
const skill_category = SkillTypeEnum.optional().default('SignificantSkills')
const skillscv_analytics_web__analytics__profession_analytics__schemas__SkillsOutSchema: z.ZodType<skillscv_analytics_web__analytics__profession_analytics__schemas__SkillsOutSchema> =
  z.object({ skillId: z.string(), name: z.string(), percentage: z.number() }).passthrough()
const CustomPage_SkillsOutSchema_: z.ZodType<CustomPage_SkillsOutSchema_> = z
  .object({
    items: z.array(
      skillscv_analytics_web__analytics__profession_analytics__schemas__SkillsOutSchema,
    ),
    pageInfo: PageInfo,
  })
  .passthrough()
const SkillsCompareProfessionOutSchema: z.ZodType<SkillsCompareProfessionOutSchema> = z
  .object({
    skillId: z.string(),
    name: z.string(),
    percentage: z.number(),
    sharedSkill: z.boolean().optional().default(false),
    sharedSkillPosition: z.number().int().optional().default(9999),
  })
  .passthrough()
const CompareProfessionOutSchema: z.ZodType<CompareProfessionOutSchema> = z
  .object({
    bocCode: z.string(),
    professionName: z.string(),
    numberOfPositionsOpened: z.number().int(),
    skillOverlapPercentage: z.number().int(),
    skills: z.array(SkillsCompareProfessionOutSchema),
  })
  .passthrough()
const CustomPage_CompareProfessionOutSchema_: z.ZodType<CustomPage_CompareProfessionOutSchema_> = z
  .object({ items: z.array(CompareProfessionOutSchema), pageInfo: PageInfo })
  .passthrough()
const AnalyticsTimeLevelEnum = z.enum(['year', 'month', 'day', 'week'])
const aggregation_level = AnalyticsTimeLevelEnum.optional().default('year')
const SkillsCVProfileCompletenessOutSchema: z.ZodType<SkillsCVProfileCompletenessOutSchema> = z
  .object({
    completenessCvLess10: z.number().int().default(0),
    completenessCv10: z.number().int().default(0),
    completenessCv20: z.number().int().default(0),
    completenessCv30: z.number().int().default(0),
    completenessCv40: z.number().int().default(0),
    completenessCv50: z.number().int().default(0),
    completenessCv60: z.number().int().default(0),
    completenessCv70: z.number().int().default(0),
    completenessCv80: z.number().int().default(0),
    completenessCv90: z.number().int().default(0),
    completenessCv100: z.number().int().default(0),
  })
  .partial()
  .passthrough()
const SkillsCVProfileInsightsOutSchema: z.ZodType<SkillsCVProfileInsightsOutSchema> = z
  .object({
    totalNumberOfUsers: z.number().int().optional().default(0),
    totalNumberOfActiveUsers: z.number().int().optional().default(0),
    cvCreationYear: z.number().int(),
    cvCreationMonth: z.number().int().nullable().optional(),
    cvCreationWeek: z.number().int().nullable().optional(),
    cvCreationDay: z.number().int().nullable().optional(),
    cvCompleteness: SkillsCVProfileCompletenessOutSchema,
    skillsCompleteness100: z.number().int().optional().default(0),
    profileCompleteness100: z.number().int().optional().default(0),
    certificateCompleteness100: z.number().int().optional().default(0),
    languageCompleteness100: z.number().int().optional().default(0),
    hobbyCompleteness100: z.number().int().optional().default(0),
    educationCompleteness100: z.number().int().optional().default(0),
    workExperienceCompleteness100: z.number().int().optional().default(0),
  })
  .passthrough()
const CustomPage_SkillsCVProfileInsightsOutSchema_: z.ZodType<CustomPage_SkillsCVProfileInsightsOutSchema_> =
  z.object({ items: z.array(SkillsCVProfileInsightsOutSchema), pageInfo: PageInfo }).passthrough()
const SkillsCVJobApplicationStateOutSchema: z.ZodType<SkillsCVJobApplicationStateOutSchema> = z
  .object({ stateCode: z.string(), numberOfApplications: z.number().int() })
  .passthrough()
const SkillsCVJobApplicationInsightsOutSchema: z.ZodType<SkillsCVJobApplicationInsightsOutSchema> =
  z
    .object({
      applicationYear: z.number().int(),
      applicationMonth: z.number().int().nullable().optional(),
      applicationWeek: z.number().int().nullable().optional(),
      applicationDay: z.number().int().nullable().optional(),
      totalNumberOfApplications: z.number().int().optional().default(0),
      cvCompleteness: SkillsCVProfileCompletenessOutSchema,
      applicationState: z
        .union([z.array(SkillsCVJobApplicationStateOutSchema), z.null()])
        .optional(),
    })
    .passthrough()
const CustomPage_SkillsCVJobApplicationInsightsOutSchema_: z.ZodType<CustomPage_SkillsCVJobApplicationInsightsOutSchema_> =
  z
    .object({ items: z.array(SkillsCVJobApplicationInsightsOutSchema), pageInfo: PageInfo })
    .passthrough()
const SkillsCVTotalInsightsOutSchema: z.ZodType<SkillsCVTotalInsightsOutSchema> = z
  .object({
    totalNumberOfUsers: z.number().int().optional().default(0),
    totalNumberOfActiveUsers: z.number().int().optional().default(0),
    totalNumberOfApplications: z.number().int().optional().default(0),
    applicationState: z
      .union([z.array(SkillsCVJobApplicationStateOutSchema), z.null()])
      .optional()
      .default([]),
    cvCompleteness: SkillsCVProfileCompletenessOutSchema,
  })
  .passthrough()
const SkillsCVApplicationFlowOutSchema = z
  .object({ from: z.string(), to: z.string(), flow: z.number().int() })
  .passthrough()
const AnonymizedOutSchema: z.ZodType<AnonymizedOutSchema> = z
  .object({
    email: z.string(),
    name: z.union([z.string(), z.null()]).optional().default('****'),
    cvDetails: z.union([z.array(CVDetailsOutSchema), z.null()]),
  })
  .passthrough()
const CustomPage_AnonymizedOutSchema_: z.ZodType<CustomPage_AnonymizedOutSchema_> = z
  .object({ items: z.array(AnonymizedOutSchema), pageInfo: PageInfo })
  .passthrough()
const AnonymizedUserOutSchema: z.ZodType<AnonymizedUserOutSchema> = z
  .object({
    userId: z.string().nullable(),
    email: z.union([z.string(), z.null()]).default('****@****.***'),
    preferredName: z.union([z.string(), z.null()]).default('****'),
  })
  .partial()
  .passthrough()
const AnonymizedSkillCVProfileOutSchema: z.ZodType<AnonymizedSkillCVProfileOutSchema> = z
  .object({
    firstNm: z.union([z.string(), z.null()]).default('****'),
    prefixNm: z.union([z.string(), z.null()]),
    lastNm: z.union([z.string(), z.null()]).default('****'),
    dateOfBirth: z.union([z.string(), z.null()]),
    workingHours: z.union([z.number(), z.null()]),
    willingToTravel: z.union([z.boolean(), z.null()]),
    telephoneNumber: z.union([z.string(), z.null()]),
    aboutMe: z.union([z.string(), z.null()]),
    lastModifiedDt: z.union([z.string(), z.null()]),
    profilePhoto3x: z.union([z.string(), z.null()]),
    profilePhoto2x: z.union([z.string(), z.null()]),
    profilePhotoCircle2x: z.union([z.string(), z.null()]),
    profilePhoto1x: z.union([z.string(), z.null()]),
    skillsCvId: z.union([z.string(), z.null()]),
    gender: z.union([z.string(), z.null()]),
  })
  .partial()
  .passthrough()
const AnonymizedCVOutSchema: z.ZodType<AnonymizedCVOutSchema> = z
  .object({
    skillsCvId: z.string(),
    user: z.union([AnonymizedUserOutSchema, z.null()]),
    cvProfile: z.union([AnonymizedSkillCVProfileOutSchema, z.null()]),
    cvAddress: z.union([SkillsCVAddressOutSchema, z.null()]),
    cvItems: z.union([SkillsCVLineItemsOutSchema, z.null()]).optional().default({}),
    organization: z
      .union([CVOrganizationOutSchema, z.null()])
      .optional()
      .default({
        organizationName: 'SkillsCV',
        theme: {
          otherColor: '#FF5400',
          primaryColor: '#390099',
          secondaryColor: '#FF0054',
          tertiaryColor: '#FFBD00',
        },
        organizationLogoUrl:
          'https://skillscv-analytics-web.dev.skillscv.cusbi.nl/static/organisations/skillscv/logo-light.png',
      }),
  })
  .passthrough()
const UserOutSchema: z.ZodType<UserOutSchema> = z
  .object({
    userId: z.string().nullable(),
    email: z.union([z.string(), z.null()]).optional(),
    preferredName: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough()
const SkillsCVProfileOutSchema: z.ZodType<SkillsCVProfileOutSchema> = z
  .object({
    firstNm: z.union([z.string(), z.null()]),
    prefixNm: z.union([z.string(), z.null()]),
    lastNm: z.union([z.string(), z.null()]),
    dateOfBirth: z.union([z.string(), z.null()]),
    workingHours: z.union([z.number(), z.null()]).default(36),
    willingToTravel: z.union([z.boolean(), z.null()]).default(false),
    telephoneNumber: z.union([z.string(), z.null()]),
    aboutMe: z.union([z.string(), z.null()]),
    lastModifiedDt: z.union([z.string(), z.null()]),
    profilePhoto3x: z.union([z.string(), z.null()]),
    profilePhoto2x: z.union([z.string(), z.null()]),
    profilePhotoCircle2x: z.union([z.string(), z.null()]),
    profilePhoto1x: z.union([z.string(), z.null()]),
    skillsCvId: z.union([z.string(), z.null()]),
    gender: z.union([z.string(), z.null()]),
  })
  .partial()
  .passthrough()
const SkillsCVFullOutSchema: z.ZodType<SkillsCVFullOutSchema> = z
  .object({
    skillsCvId: z.string(),
    user: z.union([UserOutSchema, z.null()]).optional(),
    cvProfile: z.union([SkillsCVProfileOutSchema, z.null()]).optional().default({}),
    cvAddress: z.union([SkillsCVAddressOutSchema, z.null()]).optional().default({}),
    cvItems: z.union([SkillsCVLineItemsOutSchema, z.null()]).optional().default({}),
    organization: z
      .union([CVOrganizationOutSchema, z.null()])
      .optional()
      .default({
        organizationName: 'SkillsCV',
        theme: {
          otherColor: '#FF5400',
          primaryColor: '#390099',
          secondaryColor: '#FF0054',
          tertiaryColor: '#FFBD00',
        },
        organizationLogoUrl:
          'https://skillscv-analytics-web.dev.skillscv.cusbi.nl/static/organisations/skillscv/logo-light.png',
      }),
  })
  .passthrough()
const RoleOutSchema: z.ZodType<RoleOutSchema> = z
  .object({ id: z.string(), roleName: RolesEnum })
  .passthrough()
const CustomPage_RoleOutSchema_: z.ZodType<CustomPage_RoleOutSchema_> = z
  .object({ items: z.array(RoleOutSchema), pageInfo: PageInfo })
  .passthrough()
const EducationDegreeCategoryOutSchema = z
  .object({ degreeCategory: z.array(z.string()) })
  .passthrough()
const CustomPage_ViewpointModuleOutSchema_: z.ZodType<CustomPage_ViewpointModuleOutSchema_> = z
  .object({ items: z.array(ViewpointModuleOutSchema), pageInfo: PageInfo })
  .passthrough()
const ViewpointModuleGroupTypeSchema: z.ZodType<ViewpointModuleGroupTypeSchema> = z
  .object({ groupTypeCode: GroupTypesEnum, groupTypeId: z.string() })
  .passthrough()
const ViewpointModuleWithGroupTypesOutSchema: z.ZodType<ViewpointModuleWithGroupTypesOutSchema> = z
  .object({
    moduleId: z.string(),
    moduleCode: ViewpointModulesEnum,
    groupTypes: z.array(ViewpointModuleGroupTypeSchema),
  })
  .passthrough()
const GroupCreateInSchema = z
  .object({
    groupName: z.string(),
    groupDescription: z.union([z.string(), z.null()]).optional(),
    groupStartDate: z.union([z.string(), z.null()]).optional(),
    groupEndDate: z.union([z.string(), z.null()]).optional(),
    groupLogoBase64: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough()
const GroupOutSchema: z.ZodType<GroupOutSchema> = z
  .object({
    groupId: z.string(),
    organizationId: z.string(),
    groupName: z.string(),
    groupDescription: z.union([z.string(), z.null()]).optional(),
    groupStartDate: z.union([z.string(), z.null()]).optional(),
    groupEndDate: z.union([z.string(), z.null()]).optional(),
    dateCreated: z.string().datetime({ offset: true }),
    numberOfMembers: z.number().int().optional().default(0),
    groupTypeCode: GroupTypesEnum,
    groupModuleCode: ViewpointModulesEnum,
    readOnly: z.boolean().optional().default(false),
    managedByOrganizationName: z.string(),
    groupLogoUrl: z.union([z.string(), z.null()]),
  })
  .passthrough()
const CustomPage_GroupOutSchema_: z.ZodType<CustomPage_GroupOutSchema_> = z
  .object({ items: z.array(GroupOutSchema), pageInfo: PageInfo })
  .passthrough()
const GroupUpdateInSchema = z
  .object({
    groupName: z.string(),
    groupDescription: z.union([z.string(), z.null()]).optional(),
    groupStartDate: z.union([z.string(), z.null()]).optional(),
    groupEndDate: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough()
const GroupLogoInSchema = z
  .object({ groupLogoBase64: z.union([z.string(), z.null()]) })
  .partial()
  .passthrough()
const ViewpointClusterAvailableOrganizationOutSchema: z.ZodType<ViewpointClusterAvailableOrganizationOutSchema> =
  z.object({ organizationId: z.string(), organizationName: z.string() }).passthrough()
const CustomPage_ViewpointClusterAvailableOrganizationOutSchema_: z.ZodType<CustomPage_ViewpointClusterAvailableOrganizationOutSchema_> =
  z
    .object({ items: z.array(ViewpointClusterAvailableOrganizationOutSchema), pageInfo: PageInfo })
    .passthrough()
const OrganizationGroupClusterInSchema = z
  .object({ participatingOrganizationId: z.string() })
  .passthrough()
const OrganizationGroupClusterOutSchema: z.ZodType<OrganizationGroupClusterOutSchema> = z
  .object({
    organizationGroupId: z.string(),
    participatingOrganizationId: z.string(),
    groupId: z.string(),
    participatingOrganizationName: z.string(),
    dateCreated: z.string().datetime({ offset: true }),
    isAdmin: z.boolean(),
  })
  .passthrough()
const CustomPage_OrganizationGroupClusterOutSchema_: z.ZodType<CustomPage_OrganizationGroupClusterOutSchema_> =
  z.object({ items: z.array(OrganizationGroupClusterOutSchema), pageInfo: PageInfo }).passthrough()
const skillscv_analytics_web__talent_pool_manager__schemas__link__LinkInSchema = z
  .object({
    linkName: z.union([z.string(), z.null()]),
    validityInDays: z.union([z.number(), z.null()]).default(365),
  })
  .partial()
  .passthrough()
const skillscv_analytics_web__talent_pool_manager__schemas__link__LinkOutSchema: z.ZodType<skillscv_analytics_web__talent_pool_manager__schemas__link__LinkOutSchema> =
  z
    .object({
      linkId: z.string(),
      linkName: z.union([z.string(), z.null()]).optional(),
      groupId: z.string(),
      organizationId: z.string(),
      expirationDatetime: z.string().datetime({ offset: true }),
      dateCreated: z.string().datetime({ offset: true }),
      dateUpdated: z.string().datetime({ offset: true }),
      linkUrl: z.string(),
      isActive: z.boolean(),
      validityInDays: z.number().int().gte(1).lte(365),
      linkIdUrlSafe: z.string(),
      numberOfClicks: z.number().int(),
    })
    .passthrough()
const skillscv_analytics_web__utils__pagination__CustomPage_LinkOutSchema___2: z.ZodType<skillscv_analytics_web__utils__pagination__CustomPage_LinkOutSchema___2> =
  z
    .object({
      items: z.array(skillscv_analytics_web__talent_pool_manager__schemas__link__LinkOutSchema),
      pageInfo: PageInfo,
    })
    .passthrough()
const skillscv_analytics_web__talent_pool_manager__schemas__link__LinkUpdateSchema = z
  .object({ linkName: z.union([z.string(), z.null()]) })
  .partial()
  .passthrough()
const Body_upload_api_v1_group_upload_file_post = z
  .object({ file: z.instanceof(File) })
  .passthrough()
const CVDetailsExternalCandidatesOutSchema: z.ZodType<CVDetailsExternalCandidatesOutSchema> = z
  .object({
    skillscvId: z.union([z.string(), z.null()]),
    completenessPercentage: z.union([z.number(), z.null()]),
    dateCreated: z.union([z.string(), z.null()]),
    candidateName: z.union([z.string(), z.null()]),
    telephoneNumber: z.union([z.string(), z.null()]),
  })
  .partial()
  .passthrough()
const ContactJobSeekerForJobInSchema = z
  .object({ jobOfferCode: z.string(), profession: z.union([z.string(), z.null()]).optional() })
  .passthrough()
const ExternalCandidatesOutSchema: z.ZodType<ExternalCandidatesOutSchema> = z
  .object({
    memberId: z.string(),
    name: z.union([z.string(), z.null()]),
    email: z.string().email(),
    inviteEmailStatus: z
      .union([EmailStatusEnum, z.null()])
      .optional()
      .default('There is no information about the delivery'),
    dateInvited: z.string(),
    cvDetails: z.union([z.array(CVDetailsExternalCandidatesOutSchema), z.null()]),
    detailed: z.literal('yes'),
    inviteLinkCompanyName: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough()
const ExternalCandidatesSimpleOutSchema: z.ZodType<ExternalCandidatesSimpleOutSchema> = z
  .object({ memberId: z.string(), email: z.string().email(), detailed: z.literal('no') })
  .passthrough()
// const CustomPage_Annotated_Union_ExternalCandidatesOutSchema__ExternalCandidatesSimpleOutSchema___FieldInfo_annotation_NoneType__required_True__discriminator__detailed____: z.ZodType<CustomPage_Annotated_Union_ExternalCandidatesOutSchema__ExternalCandidatesSimpleOutSchema___FieldInfo_annotation_NoneType__required_True__discriminator__detailed____> =
//   z
//     .object({
//       items: z.array(
//         z.discriminatedUnion('detailed', [
//           ExternalCandidatesOutSchema,
//           ExternalCandidatesSimpleOutSchema,
//         ]),
//       ),
//       pageInfo: PageInfo,
//     })
//     .passthrough()
const CustomPage_ExternalCandidatesOutSchema_: z.ZodType<CustomPage_ExternalCandidatesOutSchema_> =
  z.object({ items: z.array(ExternalCandidatesOutSchema), pageInfo: PageInfo }).passthrough()
const GroupDryOutSchema: z.ZodType<GroupDryOutSchema> = z
  .object({
    groupId: z.string(),
    groupName: z.string(),
    organizationGroupCode: z.string(),
    organizationId: z.string(),
    managedBy: z.array(AdminMemberOutSchema),
    numberOfMembers: z.number().int(),
    groupType: z.union([GroupTypeOutSchema, z.null()]),
  })
  .passthrough()
const CustomPage_GroupDryOutSchema_: z.ZodType<CustomPage_GroupDryOutSchema_> = z
  .object({ items: z.array(GroupDryOutSchema), pageInfo: PageInfo })
  .passthrough()
const CustomPage_GroupTypeOutSchema_: z.ZodType<CustomPage_GroupTypeOutSchema_> = z
  .object({ items: z.array(GroupTypeOutSchema), pageInfo: PageInfo })
  .passthrough()
const JobApplicationCVStatusEnum = z.enum([
  'geselecteerd',
  'wacht op reactie',
  'afgewezen',
  'aangemaakt',
  'gesloten',
])
const JobApplicationCandidateOutSchema: z.ZodType<JobApplicationCandidateOutSchema> = z
  .object({
    skillscvSharedId: z.string(),
    email: z.union([z.string(), z.null()]).optional().default('****@****.***'),
    organizationNm: z.union([z.string(), z.null()]).optional(),
    lastModifiedDate: z.string(),
    jobApplicationCvStatus: JobApplicationCVStatusEnum.optional().default('afgewezen'),
    jobApplicationCvLink: z.union([z.string(), z.null()]).optional(),
    contactedCandidate: z.boolean().optional().default(false),
  })
  .passthrough()
const CustomPage_JobApplicationCandidateOutSchema_: z.ZodType<CustomPage_JobApplicationCandidateOutSchema_> =
  z.object({ items: z.array(JobApplicationCandidateOutSchema), pageInfo: PageInfo }).passthrough()
const SearchAndMatchOutSchema: z.ZodType<SearchAndMatchOutSchema> = z
  .object({
    memberId: z.string(),
    skillscvId: z.string(),
    email: z.string().email(),
    telephoneNumber: z.union([z.string(), z.null()]).optional(),
    candidateName: z.union([z.string(), z.null()]).optional(),
    dateInvited: z.string(),
    inviteLinkCompanyName: z.union([z.string(), z.null()]).optional(),
    cvJobSimilarityScore: z.union([z.number(), z.null()]).optional().default(0),
    numberMatchingSkills: z.number().int(),
  })
  .passthrough()
const CustomPage_SearchAndMatchOutSchema_: z.ZodType<CustomPage_SearchAndMatchOutSchema_> = z
  .object({ items: z.array(SearchAndMatchOutSchema), pageInfo: PageInfo })
  .passthrough()
const SearchAndMatchSkillsOutSchema: z.ZodType<SearchAndMatchSkillsOutSchema> = z
  .object({ id: z.string(), skillNm: z.string() })
  .passthrough()
const CustomPage_SearchAndMatchSkillsOutSchema_: z.ZodType<CustomPage_SearchAndMatchSkillsOutSchema_> =
  z.object({ items: z.array(SearchAndMatchSkillsOutSchema), pageInfo: PageInfo }).passthrough()
const GroupInSchema: z.ZodType<GroupInSchema> = z
  .object({
    groupName: z.string(),
    organizationId: z.string(),
    groupTypeId: z.string(),
    moduleId: z.string(),
    members: z.array(MemberInSchema),
  })
  .passthrough()
const GroupUpdateSchema = z.object({ groupName: z.string() }).passthrough()
const ProfessionBocOutSchema: z.ZodType<ProfessionBocOutSchema> = z
  .object({ professionBocNm: z.union([z.string(), z.null()]) })
  .partial()
  .passthrough()
const SearchAndMatchLocationInSchema: z.ZodType<SearchAndMatchLocationInSchema> = z
  .object({
    geoLocation: z.union([Position2D, Position3D]),
    distance: z.number().int().optional().default(25),
  })
  .passthrough()
const SearchAndMatchInSchema: z.ZodType<SearchAndMatchInSchema> = z
  .object({
    educationDegreeCategory: z.union([z.array(z.string()), z.null()]),
    location: z.union([SearchAndMatchLocationInSchema, z.null()]),
  })
  .partial()
  .passthrough()
const UploadFileOutSchema = z
  .object({ email: z.string().email(), name: z.union([z.string(), z.null()]) })
  .passthrough()
const VacancyContactedMemberOutSchema = z.object({ id: z.string() }).passthrough()
const skillscv_analytics_web__search_and_match__schemas__JobApplicationVacancyOutSchema: z.ZodType<skillscv_analytics_web__search_and_match__schemas__JobApplicationVacancyOutSchema> =
  z
    .object({
      jobOfferCode: z.string(),
      profession: z.union([z.string(), z.null()]).optional(),
      physicalLocationPlace: z.union([z.string(), z.null()]).optional(),
      lastModifiedDate: z.string(),
    })
    .passthrough()
const skillscv_analytics_web__search_and_match__schemas__SkillOverlapOutSchema: z.ZodType<skillscv_analytics_web__search_and_match__schemas__SkillOverlapOutSchema> =
  z
    .object({
      jobSkillName: z.string(),
      cvSkillName: z.union([z.string(), z.null()]).optional(),
      skillSimilarityScore: z.number(),
    })
    .passthrough()
const skillscv_analytics_web__search_and_match__schemas__SearchAndMatchCandidateOutSchema: z.ZodType<skillscv_analytics_web__search_and_match__schemas__SearchAndMatchCandidateOutSchema> =
  z
    .object({
      memberId: z.string(),
      skillscvId: z.string(),
      email: z.string().email(),
      telephoneNumber: z.union([z.string(), z.null()]).optional(),
      candidateName: z.union([z.string(), z.null()]).optional(),
      dateInvited: z.string(),
      inviteLinkCompanyName: z.union([z.string(), z.null()]).optional(),
      cvJobSimilarityScore: z.union([z.number(), z.null()]).optional().default(0),
      numberMatchingSkills: z.number().int(),
      skillMatchingResult: z
        .union([
          z.array(skillscv_analytics_web__search_and_match__schemas__SkillOverlapOutSchema),
          z.null(),
        ])
        .optional(),
    })
    .passthrough()
const skillscv_analytics_web__search_and_match__schemas__VacancyDetailOutSchema: z.ZodType<skillscv_analytics_web__search_and_match__schemas__VacancyDetailOutSchema> =
  z
    .object({
      jobOfferCode: z.string(),
      professionBocDetails: z.union([ProfessionBocOutSchema, z.null()]).optional(),
      profession: z.union([z.string(), z.null()]).optional(),
      physicalLocation: z.union([z.string(), z.null()]).optional(),
      url: z.union([z.string(), z.null()]).optional(),
      dateFound: z.string().datetime({ offset: true }),
      dateDeactivated: z.union([z.string(), z.null()]).optional(),
      active: z.union([z.boolean(), z.null()]),
      organization: z.union([z.string(), z.null()]).optional(),
      daysSinceFound: z.union([z.number(), z.null()]).optional().default(0),
    })
    .passthrough()
const skillscv_analytics_web__search_and_match__schemas__VacanciesOutSchema: z.ZodType<skillscv_analytics_web__search_and_match__schemas__VacanciesOutSchema> =
  z
    .object({
      items: z
        .array(skillscv_analytics_web__search_and_match__schemas__VacancyDetailOutSchema)
        .optional()
        .default([]),
      pageInfo: PageInfo,
    })
    .passthrough()
const skillscv_analytics_web__search_and_match__schemas__VacancyOutSchema = z
  .object({
    jobOfferCode: z.string(),
    profession: z.union([z.string(), z.null()]).optional(),
    physicalLocation: z.union([z.string(), z.null()]).optional(),
    organization: z.union([z.string(), z.null()]).optional(),
    skill: z
      .union([z.array(z.string()), z.null()])
      .optional()
      .default([]),
    jobOfferHtmlDescription: z.union([z.string(), z.null()]).optional(),
    physicalLocationCoordinates: z.union([Position2D, Position3D, z.null()]).optional(),
    educationDegreeMinimumRequired: z.union([z.string(), z.null()]).optional(),
    educationDegreeCategory: z.union([z.string(), z.null()]).optional(),
  })
  .passthrough()

// export type skillscv_analytics_web__search_and_match__schemas__VacancyOutSchema = z.infer<
//   typeof skillscv_analytics_web__search_and_match__schemas__VacancyOutSchema
// >
const skillscv_analytics_web__search_and_match__schemas__ViewpointSearchAndMatchFeedbackInSchema = z
  .object({ isRelevant: z.boolean() })
  .passthrough()
const skillscv_analytics_web__search_and_match__schemas__ViewpointSearchAndMatchFeedbackOutSchema =
  z
    .object({ isRelevant: z.union([z.boolean(), z.null()]) })
    .partial()
    .passthrough()
const skillscv_analytics_web__utils__pagination__CustomPage_JobApplicationVacancyOutSchema___2: z.ZodType<skillscv_analytics_web__utils__pagination__CustomPage_JobApplicationVacancyOutSchema___2> =
  z
    .object({
      items: z.array(
        skillscv_analytics_web__search_and_match__schemas__JobApplicationVacancyOutSchema,
      ),
      pageInfo: PageInfo,
    })
    .passthrough()

export const schemas = {
  OrganizationThemeInSchema,
  OrganizationVacanciesDetailSchema,
  OrganizationModuleInSchema,
  OrganizationCreateInSchema,
  OrganizationTypesEnum,
  OrganizationTypeOutSchema,
  StatusEnum,
  ViewpointModulesEnum,
  ViewpointModuleOutSchema,
  OrganizationThemeOutSchema,
  OrganizationVacancyConfigurationOutSchema,
  skillscv_analytics_web__organizations__schemas__OrganizationOutSchema,
  BadRequestMessageOutSchema,
  UnauthorizedMessageOutSchema,
  ForbiddenMessageOutSchema,
  NotFoundMessageOutSchema,
  ConflictMessageOutSchema,
  ValidationErrorMessageOutSchema,
  LimitsErrorMessageOutSchema,
  InternalErrorMessageOutSchema,
  PageInfo,
  CustomPage_OrganizationOutSchema_,
  OrganizationUpdateInSchema,
  GenericMessageOutSchema,
  OrganizationVacanciesDetailNewSchema,
  OwnOrganizationUpdateInSchema,
  OrganizationLogoInSchema,
  EcommerceStatus,
  EcommerceSubscription,
  OrganizationInsightsOutSchema,
  OrganizationVacancyConfigurationInSchema,
  OrganizationVacancyConfigurationUpdateInSchema,
  OrganizationVacancyUrlInSchema,
  Position2D,
  Position3D,
  skillscv_analytics_web__organizations__schemas__VacancyOutSchema,
  OrganizationEcommerceInSchema,
  CustomPage_OrganizationTypeOutSchema_,
  ViewpointUserInSchema,
  UserStatusEnum,
  RolesEnum,
  skillscv_analytics_web__users__schemas__OrganizationOutSchema,
  ViewpointNewUserOutSchema,
  ViewpointExistingUserOutSchema,
  ViewpointUserUpdateSchema,
  CustomPage_ViewpointExistingUserOutSchema_,
  ViewpointRemoveUserFromOrgInSchema,
  ViewpointAddUserRoleOrgInSchema,
  MemberInSchema,
  GroupAddMembersInSchema,
  MembershipUpdateSchema,
  AdminMemberOutSchema,
  GroupTypesEnum,
  GroupTypeOutSchema,
  skillscv_analytics_web__group__schemas__group_v1__SkillsOutSchema,
  CVDetailsOutSchema,
  EmailStatusEnum,
  MemberExtendedOutSchema,
  GroupDetailedOutSchema,
  SkillsGeneralOutSchema,
  MemberDetailedOutSchema,
  CreateSkillsCVAccountAndAddToGroupInSchema,
  MemberRedirectSkillsCVAppOutSchema,
  GroupAddAdminInSchema,
  skillscv_analytics_web__link__schemas__link_v1__LinkInSchema,
  skillscv_analytics_web__link__schemas__link_v1__LinkOutSchema,
  group_module_code,
  group_type_code,
  skillscv_analytics_web__utils__pagination__CustomPage_LinkOutSchema___1,
  skillscv_analytics_web__link__schemas__link_v1__LinkUpdateSchema,
  skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacancyDetailOutSchema,
  skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacanciesOutSchema,
  skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacancyOutSchema,
  GeoLocationInSchema,
  CandidateMatchStatusEnum,
  SearchAndMatchCandidateFilterInSchema,
  SearchAndMatchCandidatesClosedPoolOutSchema,
  SearchAndMatchMatchingDetailsOutSchema,
  FreeTextSearchProfessionOutSchema,
  FreeTextSearchSkillOutSchema,
  FreeTextSearchOutSchema,
  SearchAndMatchCandidatesOutSchema,
  CustomPage_SearchAndMatchCandidatesOutSchema_,
  skillscv_analytics_web__search_and_match_plus__schemas__candidates__SkillOverlapOutSchema,
  CandidateSharedProfileTypeEnum,
  SearchAndMatchCandidateTalentPoolOutSchema,
  ProfessionOverlapOutSchema,
  skillscv_analytics_web__search_and_match_plus__schemas__candidates__SearchAndMatchCandidateOutSchema,
  skillscv_analytics_web__search_and_match_plus__schemas__candidates__ViewpointSearchAndMatchFeedbackInSchema,
  skillscv_analytics_web__search_and_match_plus__schemas__candidates__ViewpointSearchAndMatchFeedbackOutSchema,
  SearchAndMatchCandidateUserOutSchema,
  SearchAndMatchCandidateProfileOutSchema,
  SkillsCVAddressOutSchema,
  skillscv_analytics_web__group__schemas__external_schemas__ProfessionOutSchema,
  SkillsCVLineItemRelatedSkillOutSchema,
  SkillsCVLineItemExperienceOutSchema,
  EducationOutSchema,
  EducationalInstitutionOutSchema,
  EducationDegreeOutSchema,
  EducationFieldOfStudyOutSchema,
  SkillsCVLineItemEducationOutSchema,
  HobbyOutSchema,
  SkillsCVLineItemHobbyOutSchema,
  SkillOutSchema,
  SkillsCVLineItemLanguageOutSchema,
  SkillsCVLineItemSoftSkillKnowledgeOutSchema,
  SkillsCVLineItemCertificateOutSchema,
  SkillsCVLineItemsOutSchema,
  CVOrganizationOutSchema,
  SearchAndMatchAnonymizedCVOutSchema,
  AllCandidatesOutSchema,
  CustomPage_AllCandidatesOutSchema_,
  JobApplicationViewpointStateEnum,
  job_application_state,
  InitiatedByEnum,
  initiated_by,
  JobApplicationStateEnum,
  JobApplicationOutSchema,
  CustomPage_JobApplicationOutSchema_,
  skillscv_analytics_web__search_and_match_plus__schemas__job_applications__JobApplicationVacancyOutSchema,
  skillscv_analytics_web__utils__pagination__CustomPage_JobApplicationVacancyOutSchema___1,
  SearchAndMatchPlusTalentPoolsOutSchema,
  CustomPage_SearchAndMatchPlusTalentPoolsOutSchema_,
  skillscv_analytics_web__analytics__profession_analytics__schemas__ProfessionOutSchema,
  CustomPage_ProfessionOutSchema_,
  SkillTypeEnum,
  skill_category,
  skillscv_analytics_web__analytics__profession_analytics__schemas__SkillsOutSchema,
  CustomPage_SkillsOutSchema_,
  SkillsCompareProfessionOutSchema,
  CompareProfessionOutSchema,
  CustomPage_CompareProfessionOutSchema_,
  AnalyticsTimeLevelEnum,
  aggregation_level,
  SkillsCVProfileCompletenessOutSchema,
  SkillsCVProfileInsightsOutSchema,
  CustomPage_SkillsCVProfileInsightsOutSchema_,
  SkillsCVJobApplicationStateOutSchema,
  SkillsCVJobApplicationInsightsOutSchema,
  CustomPage_SkillsCVJobApplicationInsightsOutSchema_,
  SkillsCVTotalInsightsOutSchema,
  SkillsCVApplicationFlowOutSchema,
  AnonymizedOutSchema,
  CustomPage_AnonymizedOutSchema_,
  AnonymizedUserOutSchema,
  AnonymizedSkillCVProfileOutSchema,
  AnonymizedCVOutSchema,
  UserOutSchema,
  SkillsCVProfileOutSchema,
  SkillsCVFullOutSchema,
  RoleOutSchema,
  CustomPage_RoleOutSchema_,
  EducationDegreeCategoryOutSchema,
  CustomPage_ViewpointModuleOutSchema_,
  ViewpointModuleGroupTypeSchema,
  ViewpointModuleWithGroupTypesOutSchema,
  GroupCreateInSchema,
  GroupOutSchema,
  CustomPage_GroupOutSchema_,
  GroupUpdateInSchema,
  GroupLogoInSchema,
  ViewpointClusterAvailableOrganizationOutSchema,
  CustomPage_ViewpointClusterAvailableOrganizationOutSchema_,
  OrganizationGroupClusterInSchema,
  OrganizationGroupClusterOutSchema,
  CustomPage_OrganizationGroupClusterOutSchema_,
  skillscv_analytics_web__talent_pool_manager__schemas__link__LinkInSchema,
  skillscv_analytics_web__talent_pool_manager__schemas__link__LinkOutSchema,
  skillscv_analytics_web__utils__pagination__CustomPage_LinkOutSchema___2,
  skillscv_analytics_web__talent_pool_manager__schemas__link__LinkUpdateSchema,
  Body_upload_api_v1_group_upload_file_post,
  CVDetailsExternalCandidatesOutSchema,
  ContactJobSeekerForJobInSchema,
  ExternalCandidatesOutSchema,
  ExternalCandidatesSimpleOutSchema,
  // CustomPage_Annotated_Union_ExternalCandidatesOutSchema__ExternalCandidatesSimpleOutSchema___FieldInfo_annotation_NoneType__required_True__discriminator__detailed____,
  CustomPage_ExternalCandidatesOutSchema_,
  GroupDryOutSchema,
  CustomPage_GroupDryOutSchema_,
  CustomPage_GroupTypeOutSchema_,
  JobApplicationCVStatusEnum,
  JobApplicationCandidateOutSchema,
  CustomPage_JobApplicationCandidateOutSchema_,
  SearchAndMatchOutSchema,
  CustomPage_SearchAndMatchOutSchema_,
  SearchAndMatchSkillsOutSchema,
  CustomPage_SearchAndMatchSkillsOutSchema_,
  GroupInSchema,
  GroupUpdateSchema,
  ProfessionBocOutSchema,
  SearchAndMatchLocationInSchema,
  SearchAndMatchInSchema,
  UploadFileOutSchema,
  VacancyContactedMemberOutSchema,
  skillscv_analytics_web__search_and_match__schemas__JobApplicationVacancyOutSchema,
  skillscv_analytics_web__search_and_match__schemas__SkillOverlapOutSchema,
  skillscv_analytics_web__search_and_match__schemas__SearchAndMatchCandidateOutSchema,
  skillscv_analytics_web__search_and_match__schemas__VacancyDetailOutSchema,
  skillscv_analytics_web__search_and_match__schemas__VacanciesOutSchema,
  skillscv_analytics_web__search_and_match__schemas__VacancyOutSchema,
  skillscv_analytics_web__search_and_match__schemas__ViewpointSearchAndMatchFeedbackInSchema,
  skillscv_analytics_web__search_and_match__schemas__ViewpointSearchAndMatchFeedbackOutSchema,
  //skillscv_analytics_web__search_and_match_plus__schemas__candidates__ViewpointSearchAndMatchFeedbackOutSchema,
  skillscv_analytics_web__utils__pagination__CustomPage_JobApplicationVacancyOutSchema___2,
}

const endpoints = makeApi([
  {
    method: 'get',
    path: '/api/v1/admin/role',
    alias: 'list_roles_api_v1_admin_role_get',
    description: `Retrieve all roles`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_RoleOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/analytics/anonymized-profiles',
    alias: 'get_anonymized_pool_api_v1_analytics_anonymized_profiles_get',
    description: `List all job seekers anonymized`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_AnonymizedOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/analytics/anonymized-profiles/preview-cv/:skillscv_id',
    alias:
      'get_anonymized_cv_pdf_api_v1_analytics_anonymized_profiles_preview_cv__skillscv_id__get',
    description: `Get the anonymized CV data in JSON for preview`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'skillscv_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: AnonymizedCVOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/analytics/profession',
    alias: 'get_profession_api_v1_analytics_profession_get',
    description: `Get profession based on search`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'search',
        type: 'Query',
        schema: z.string(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_ProfessionOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/analytics/profession/get-similar-professions',
    alias: 'get_similar_professions_api_v1_analytics_profession_get_similar_professions_get',
    description: `Retrieve similar professions with their skills for a certain profession, based on their top 10 skills`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'boc_code',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'skill_list',
        type: 'Query',
        schema: z.array(z.string()).optional(),
      },
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'skill_category',
        type: 'Query',
        schema: z.literal('SignificantSkills').optional().default('SignificantSkills'),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_CompareProfessionOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/analytics/profession/top-skills',
    alias: 'get_top_skills_api_v1_analytics_profession_top_skills_get',
    description: `Get skills ordered per occurrence of a profession`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'boc_code',
        type: 'Query',
        schema: z.string(),
      },
      {
        name: 'skill_category',
        type: 'Query',
        schema: skill_category,
      },
      {
        name: 'skill_size',
        type: 'Query',
        schema: z.number().int().optional().default(10),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_SkillsOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/analytics/skillscv-user-analytics/aggregations',
    alias:
      'get_skillscv_profile_analytics_api_v1_analytics_skillscv_user_analytics_aggregations_get',
    description: `Get profile insights for SkillsCV users`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'aggregation_level',
        type: 'Query',
        schema: aggregation_level,
      },
      {
        name: 'for_dashboard',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_SkillsCVProfileInsightsOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/analytics/skillscv-user-analytics/aggregations/job-application',
    alias:
      'get_job_application_analytics_api_v1_analytics_skillscv_user_analytics_aggregations_job_application_get',
    description: `Get job application insights for SkillsCV users`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'aggregation_level',
        type: 'Query',
        schema: aggregation_level,
      },
      {
        name: 'for_dashboard',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_SkillsCVJobApplicationInsightsOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/analytics/skillscv-user-analytics/aggregations/total',
    alias: 'get_total_insights_api_v1_analytics_skillscv_user_analytics_aggregations_total_get',
    description: `Get aggregations about SkillsCV users and job application`,
    requestFormat: 'json',
    response: SkillsCVTotalInsightsOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/analytics/skillscv-user-analytics/application-flow-aggregations',
    alias:
      'get_skillscv_job_application_flow_api_v1_analytics_skillscv_user_analytics_application_flow_aggregations_get',
    description: `Get application flow aggregations for SkillsCV users
        The data is used to build a sankey diagram with starting node on job_seeker_set_toggles_for_anonymous_cv
        from job_seeker_set_toggles_for_anonymous_cv we can have links to other states or to no state.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'aggregation_level',
        type: 'Query',
        schema: aggregation_level,
      },
      {
        name: 'filter_date',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.array(SkillsCVApplicationFlowOutSchema),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/group',
    alias: 'create_api_v1_group_post',
    description: `Add a new group and send invites to members`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GroupInSchema,
      },
    ],
    response: GroupDryOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/group',
    alias: 'list_all_api_v1_group_get',
    description: `List all groups the user is admin of`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Query',
        schema: z.string(),
      },
      {
        name: 'modules',
        type: 'Query',
        schema: z.array(ViewpointModulesEnum),
      },
      {
        name: 'group_type_code',
        type: 'Query',
        schema: group_type_code,
      },
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_GroupDryOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/group-type',
    alias: 'list_all_api_v1_group_type_get',
    description: `List all group types`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_GroupTypeOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/group-type/:group_type_id',
    alias: 'list_one_api_v1_group_type__group_type_id__get',
    description: `Show group type`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'group_type_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: GroupTypeOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/group/:group_id',
    alias: 'update_api_v1_group__group_id__put',
    description: `Update group information`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ groupName: z.string() }).passthrough(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: GroupDetailedOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/api/v1/group/:group_id',
    alias: 'delete_api_v1_group__group_id__delete',
    description: `Hard remove group and all information related with it,
    Deletion of a group will not delete the members of the group, but the membership for a group.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/group/:group_id',
    alias: 'list_one_api_v1_group__group_id__get',
    description: `Show specific group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'filter_on_cv',
        type: 'Query',
        schema: z.boolean().optional().default(false),
      },
    ],
    response: GroupDetailedOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/group/:group_id/member/:member_id',
    alias: 'get_member_api_v1_group__group_id__member__member_id__get',
    description: `Get member details, including all skills across multiple CV`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: MemberDetailedOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/group/add-admin/:group_id',
    alias: 'add_admin_api_v1_group_add_admin__group_id__put',
    description: `Make user administrator/manager of the group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ userId: z.string() }).passthrough(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/group/add-members/:group_id',
    alias: 'add_members_api_v1_group_add_members__group_id__put',
    description: `Add members to an existing group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GroupAddMembersInSchema,
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/api/v1/group/remove-admin/:group_id/:user_id',
    alias: 'remove_admin_api_v1_group_remove_admin__group_id___user_id__delete',
    description: `Remove user as administrator/manager of the group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'user_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/api/v1/group/remove-member/:group_id/:member_id',
    alias: 'remove_members_api_v1_group_remove_member__group_id___member_id__delete',
    description: `Remove member from an existing group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/group/update-member/:group_id/:member_id',
    alias: 'update_member_api_v1_group_update_member__group_id___member_id__put',
    description: `Update the member details within a group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: GroupDetailedOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/group/upload-file',
    alias: 'upload_api_v1_group_upload_file_post',
    description: `Upload csv file, accepted header: &#x60;email,name&#x60;`,
    requestFormat: 'form-data',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ file: z.instanceof(File) }).passthrough(),
      },
    ],
    response: z.array(UploadFileOutSchema),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/invite/:link_safe_id',
    alias: 'create_skills_cv_account_and_add_to_group_api_v1_invite__link_safe_id__post',
    description: `Automatically create SkillsCV account for new job seekers and add them to the group of the invite link.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ email: z.string().email() }).passthrough(),
      },
      {
        name: 'link_safe_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ redirectUrl: z.string().min(1).max(2083).url() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/invite/:link_safe_id/:email',
    alias: 'initiate_member_invite_api_v1_invite__link_safe_id___email__get',
    description: `Redirect the job seeker to provide consent and create its skillscv account`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'link_safe_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'email',
        type: 'Path',
        schema: z.string().email(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/link/:organization_id',
    alias: 'create_api_v1_link__organization_id__post',
    description: `Generate a link to be used by job seekers to join an org/group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: skillscv_analytics_web__link__schemas__link_v1__LinkInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__link__schemas__link_v1__LinkOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/link/:organization_id',
    alias: 'list_all_api_v1_link__organization_id__get',
    description: `Retrieve all links in organization or group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'is_active',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'group_id',
        type: 'Query',
        schema: z.string().uuid().optional(),
      },
      {
        name: 'company_name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'group_module_code',
        type: 'Query',
        schema: group_module_code,
      },
      {
        name: 'group_type_code',
        type: 'Query',
        schema: group_type_code,
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: skillscv_analytics_web__utils__pagination__CustomPage_LinkOutSchema___1,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/link/:organization_id/:link_id',
    alias: 'list_one_api_v1_link__organization_id___link_id__get',
    description: `Show link`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'link_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__link__schemas__link_v1__LinkOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/link/:organization_id/:link_id',
    alias: 'update_api_v1_link__organization_id___link_id__put',
    description: `Update a link which is used by job seekers to join an org/group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: skillscv_analytics_web__link__schemas__link_v1__LinkUpdateSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'link_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__link__schemas__link_v1__LinkOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/link/:organization_id/disable/:link_id',
    alias: 'disable_api_v1_link__organization_id__disable__link_id__put',
    description: `Soft delete a link`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'link_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/masterdata/education-degree-category',
    alias: 'get_education_degree_category_api_v1_masterdata_education_degree_category_get',
    description: `Retrieve education degree categories in order to education level`,
    requestFormat: 'json',
    response: EducationDegreeCategoryOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/member/:member_id/cv/:skillscv_id',
    alias: 'show_cv_api_v1_member__member_id__cv__skillscv_id__get',
    description: `Generate the CV pdf of a member`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'skillscv_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'organization_id',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/member/:member_id/preview-cv/:skillscv_id',
    alias: 'preview_cv_api_v1_member__member_id__preview_cv__skillscv_id__get',
    description: `Get the CV data in JSON for preview`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'skillscv_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'organization_id',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: SkillsCVFullOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/organization',
    alias: 'create_organization_api_v1_organization_post',
    description: `Setup organization by admin`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OrganizationCreateInSchema,
      },
      {
        name: 'organization_id',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: skillscv_analytics_web__organizations__schemas__OrganizationOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/organization',
    alias: 'list_all_api_v1_organization_get',
    description: `Retrieve all organizations`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_OrganizationOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/organization-type',
    alias: 'list_all_api_v1_organization_type_get',
    description: `Retrieve all organization types`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_OrganizationTypeOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/organization-type/:org_type_id',
    alias: 'list_one_api_v1_organization_type__org_type_id__get',
    description: `Show organization type`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'org_type_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: OrganizationTypeOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/organization-vacancy-configuration/:organization_id',
    alias:
      'add_organization_vacancy_configuration_api_v1_organization_vacancy_configuration__organization_id__post',
    description: `Add vacancy configuration to an organization, such as url and contact email`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OrganizationVacancyConfigurationInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: OrganizationVacancyConfigurationOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/organization-vacancy-configuration/:organization_id/:organization_vacancy_configuration_id',
    alias:
      'update_organization_vacancy_configuration_api_v1_organization_vacancy_configuration__organization_id___organization_vacancy_configuration_id__put',
    description: `Update the vacancy configuration of an organization, such as url and contact email`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OrganizationVacancyConfigurationUpdateInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'organization_vacancy_configuration_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: OrganizationVacancyConfigurationOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/api/v1/organization-vacancy-configuration/:organization_id/:organization_vacancy_configuration_id',
    alias:
      'delete_api_v1_organization_vacancy_configuration__organization_id___organization_vacancy_configuration_id__delete',
    description: `Permanently delete a vacancy configuration of an organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'organization_vacancy_configuration_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/organization-vacancy-configuration/:organization_id/urls',
    alias: 'search_url_api_v1_organization_vacancy_configuration__organization_id__urls_post',
    description: `A user can insert an url where the vacancies are posted on their website.This URL response with an example vacancy and the vacancy url that needs to be add to the organisation setup:POST /api/v1/organization-vacancy-configuration/{organization_id}`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ url: z.string().min(1).max(2083).url() }).passthrough(),
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__organizations__schemas__VacancyOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/organization/:organization_id',
    alias: 'organization_update_by_admin_api_v1_organization__organization_id__put',
    description: `Update an existing organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OrganizationUpdateInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__organizations__schemas__OrganizationOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/api/v1/organization/:organization_id',
    alias: 'delete_api_v1_organization__organization_id__delete',
    description: `Permanently delete an organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/organization/:organization_id',
    alias: 'get_one_api_v1_organization__organization_id__get',
    description: `Show organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__organizations__schemas__OrganizationOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/organization/:organization_id/:module_id',
    alias: 'add_organization_module_api_v1_organization__organization_id___module_id__post',
    description: `Add module to existing organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'module_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/api/v1/organization/:organization_id/:module_id',
    alias: 'remove_organization_module_api_v1_organization__organization_id___module_id__delete',
    description: `Remove module from existing organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'module_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/organization/:organization_id/insights',
    alias: 'get_organization_metrics_api_v1_organization__organization_id__insights_get',
    description: `Show organization insights, such as ecommerce plan, candidates metrics`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: OrganizationInsightsOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/organization/e-commerce',
    alias: 'create_ecommerce_organization_api_v1_organization_e_commerce_post',
    description: `The e-commerce platform facilitated the selection of the offer and the billing process.
    When the KYC process is completed within the e-commerce platform,
    it triggers the continuation of the organizational setup and
    the setup of job vacancies in the Viewpoint application.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OrganizationEcommerceInSchema,
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/api/v1/organization/logo/:organization_id',
    alias: 'update_organization_logo_api_v1_organization_logo__organization_id__patch',
    description: `Update the logo of an organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OrganizationLogoInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__organizations__schemas__OrganizationOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/organization/set-up/:organization_id',
    alias: 'set_up_organization_by_org_api_v1_organization_set_up__organization_id__put',
    description: `Setup organization by organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OwnOrganizationUpdateInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__organizations__schemas__OrganizationOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match-plus/applications/:organization_id',
    alias: 'list_job_applications_api_v1_search_and_match_plus_applications__organization_id__get',
    description: `List all organization job applications`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'skillscv_id',
        type: 'Query',
        schema: z.string().uuid().optional(),
      },
      {
        name: 'job_application_state',
        type: 'Query',
        schema: job_application_state,
      },
      {
        name: 'initiated_by',
        type: 'Query',
        schema: initiated_by,
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_JobApplicationOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match-plus/applications/:organization_id/active-application-vacancies',
    alias:
      'list_job_application_vacancies_api_v1_search_and_match_plus_applications__organization_id__active_application_vacancies_get',
    description: `List vacancies on which there are running applications`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response:
      skillscv_analytics_web__utils__pagination__CustomPage_JobApplicationVacancyOutSchema___1,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match-plus/candidates/:organization_id',
    alias: 'get_all_candidates_api_v1_search_and_match_plus_candidates__organization_id__get',
    description: `Get all candidates which provided consent`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_AllCandidatesOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match-plus/candidates/:organization_id/:skillscv_id/matching-vacancy/:job_offer_code',
    alias:
      'get_candidate_matching_details_api_v1_search_and_match_plus_candidates__organization_id___skillscv_id__matching_vacancy__job_offer_code__get',
    description: `Get candidate information overlap for matching job`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'skillscv_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response:
      skillscv_analytics_web__search_and_match_plus__schemas__candidates__SearchAndMatchCandidateOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/search-and-match-plus/candidates/:organization_id/:skillscv_id/matching-vacancy/:job_offer_code/contact',
    alias:
      'post_ask_for_contact_information_api_v1_search_and_match_plus_candidates__organization_id___skillscv_id__matching_vacancy__job_offer_code__contact_post',
    description: `Ask the candidate for contact information, triggering an application flow in SkillsCV`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'skillscv_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/search-and-match-plus/candidates/:organization_id/:skillscv_id/matching-vacancy/:job_offer_code/feedback',
    alias:
      'post_search_and_match_feedback_api_v1_search_and_match_plus_candidates__organization_id___skillscv_id__matching_vacancy__job_offer_code__feedback_post',
    description: `Provide feedback on the profile of a job seeker for a job`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ isRelevant: z.boolean() }).passthrough(),
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'skillscv_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match-plus/candidates/:organization_id/:skillscv_id/matching-vacancy/:job_offer_code/feedback',
    alias:
      'get_search_and_match_feedback_api_v1_search_and_match_plus_candidates__organization_id___skillscv_id__matching_vacancy__job_offer_code__feedback_get',
    description: `Get the last feedback provided by the user on the profile of a job seeker for a job`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'skillscv_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response:
      skillscv_analytics_web__search_and_match_plus__schemas__candidates__ViewpointSearchAndMatchFeedbackOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/search-and-match-plus/candidates/:organization_id/matching-vacancy/:job_offer_code',
    alias:
      'search_candidates_api_v1_search_and_match_plus_candidates__organization_id__matching_vacancy__job_offer_code__post',
    description: `List all candidates matching criteria for a certain job offer`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchAndMatchCandidateFilterInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_SearchAndMatchCandidatesOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match-plus/candidates/:organization_id/preview-anonymized-cv/:skillscv_id',
    alias:
      'get_anonymized_cv_api_v1_search_and_match_plus_candidates__organization_id__preview_anonymized_cv__skillscv_id__get',
    description: `Get the anonymized CV data in JSON for preview`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'skillscv_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: SearchAndMatchAnonymizedCVOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match-plus/talent-pool/:organization_id',
    alias: 'list_talent_pools_api_v1_search_and_match_plus_talent_pool__organization_id__get',
    description: `List all talent pools of the organization, with search on name`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_SearchAndMatchPlusTalentPoolsOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match-plus/vacancies/:organization_id',
    alias: 'list_vacancies_api_v1_search_and_match_plus_vacancies__organization_id__get',
    description: `List all vacancies of organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'location',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'distance',
        type: 'Query',
        schema: z.number().int().optional().default(25),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional().default(250),
      },
    ],
    response: skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacanciesOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match-plus/vacancies/:organization_id/:job_offer_code',
    alias:
      'get_vacancy_api_v1_search_and_match_plus_vacancies__organization_id___job_offer_code__get',
    description: `Get details of vacancy`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__search_and_match_plus__schemas__vacancies__VacancyOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match/candidates/:organization_id',
    alias: 'list_external_candidates_api_v1_search_and_match_candidates__organization_id__get',
    description: `List all external candidates`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'detailed',
        type: 'Query',
        schema: z.boolean().optional().default(true),
      },
      {
        name: 'has_profile',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'has_filled_profile',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'company_name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: z.any(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match/candidates/:organization_id/:member_id',
    alias:
      'get_external_candidate_api_v1_search_and_match_candidates__organization_id___member_id__get',
    description: `Get 1 candidate`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_ExternalCandidatesOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/search-and-match/candidates/:organization_id/:member_id/contact',
    alias:
      'contact_candidate_for_job_api_v1_search_and_match_candidates__organization_id___member_id__contact_post',
    description: `Send email to the candidate for a certain job`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ContactJobSeekerForJobInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match/candidates/:organization_id/:member_id/contact/:job_offer_code',
    alias:
      'get_member_contacted_for_vacancy_api_v1_search_and_match_candidates__organization_id___member_id__contact__job_offer_code__get',
    description: `Get candidate vacancy contact id`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.union([VacancyContactedMemberOutSchema, z.null()]),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/search-and-match/candidates/:organization_id/:member_id/contact/:job_offer_code',
    alias:
      'mark_member_contacted_for_vacancy_api_v1_search_and_match_candidates__organization_id___member_id__contact__job_offer_code__post',
    description: `Mark candidate as contacted for vacancy`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/api/v1/search-and-match/candidates/:organization_id/:member_id/contact/:job_offer_code/:contact_id',
    alias:
      'delete_member_contacted_for_vacancy_api_v1_search_and_match_candidates__organization_id___member_id__contact__job_offer_code___contact_id__delete',
    description: `Delete candidate vacancy contact`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'contact_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match/candidates/:organization_id/:member_id/matching-vacancy/:job_offer_code',
    alias:
      'get_matching_candidate_api_v1_search_and_match_candidates__organization_id___member_id__matching_vacancy__job_offer_code__get',
    description: `Get candidate information overlap for matching job`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__search_and_match__schemas__SearchAndMatchCandidateOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/search-and-match/candidates/:organization_id/:member_id/matching-vacancy/:job_offer_code/feedback',
    alias:
      'post_search_and_match_feedback_api_v1_search_and_match_candidates__organization_id___member_id__matching_vacancy__job_offer_code__feedback_post',
    description: `Provide feedback on the profile of a job seeker for a job`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ isRelevant: z.boolean() }).passthrough(),
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match/candidates/:organization_id/:member_id/matching-vacancy/:job_offer_code/feedback',
    alias:
      'get_search_and_match_feedback_api_v1_search_and_match_candidates__organization_id___member_id__matching_vacancy__job_offer_code__feedback_get',
    description: `Get the last feedback provided by the user on the profile of a job seeker for a job`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'member_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response:
      skillscv_analytics_web__search_and_match__schemas__ViewpointSearchAndMatchFeedbackOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/search-and-match/candidates/:organization_id/matching-vacancy/:job_offer_code',
    alias:
      'search_candidates_api_v1_search_and_match_candidates__organization_id__matching_vacancy__job_offer_code__post',
    description: `List all candidates matching criteria for a certain job offer`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SearchAndMatchInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_SearchAndMatchOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match/candidates/skills/:organization_id',
    alias: 'get_candidates_skills_api_v1_search_and_match_candidates_skills__organization_id__get',
    description: `List all skills of external candidates`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'search_skill',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_SearchAndMatchSkillsOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match/job-application/vacancy/:organization_id',
    alias:
      'get_job_application_list_api_v1_search_and_match_job_application_vacancy__organization_id__get',
    description: `List vacancies that have at least one application`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'company_name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response:
      skillscv_analytics_web__utils__pagination__CustomPage_JobApplicationVacancyOutSchema___2,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match/job-application/vacancy/:organization_id/:job_offer_code',
    alias:
      'get_candidates_for_job_list_api_v1_search_and_match_job_application_vacancy__organization_id___job_offer_code__get',
    description: `List all job applications for a certain vacancy`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_JobApplicationCandidateOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match/vacancies/:organization_id',
    alias: 'list_vacancies_api_v1_search_and_match_vacancies__organization_id__get',
    description: `List all vacancies of organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'member_id',
        type: 'Query',
        schema: z.string().uuid().optional(),
      },
      {
        name: 'company_name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'location',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'distance',
        type: 'Query',
        schema: z.number().int().optional().default(25),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional().default(250),
      },
    ],
    response: skillscv_analytics_web__search_and_match__schemas__VacanciesOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/search-and-match/vacancies/:organization_id/:job_offer_code',
    alias: 'get_vacancy_api_v1_search_and_match_vacancies__organization_id___job_offer_code__get',
    description: `Get details of vacancy`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'job_offer_code',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__search_and_match__schemas__VacancyOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/talent-pool-manager/group/:organization_id',
    alias: 'create_api_v1_talent_pool_manager_group__organization_id__post',
    description: `Create a new group in the organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GroupCreateInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: GroupOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/talent-pool-manager/group/:organization_id',
    alias: 'list_groups_api_v1_talent_pool_manager_group__organization_id__get',
    description: `List the groups of an organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_GroupOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/talent-pool-manager/group/:organization_id/:group_id',
    alias: 'update_group_api_v1_talent_pool_manager_group__organization_id___group_id__put',
    description: `Update a group for an organization, excluding the logo`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GroupUpdateInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: GroupOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/talent-pool-manager/group/:organization_id/:group_id',
    alias: 'get_group_api_v1_talent_pool_manager_group__organization_id___group_id__get',
    description: `Get a group event of an organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: GroupOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/api/v1/talent-pool-manager/group/:organization_id/:group_id',
    alias: 'delete_group_api_v1_talent_pool_manager_group__organization_id___group_id__delete',
    description: `Hard Delete a group from an organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/talent-pool-manager/group/cluster/:organization_id/:group_id',
    alias:
      'add_organization_to_group_api_v1_talent_pool_manager_group_cluster__organization_id___group_id__post',
    description: `Add participating organizations to a group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.array(OrganizationGroupClusterInSchema),
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/talent-pool-manager/group/cluster/:organization_id/:group_id',
    alias:
      'get_participating_organizations_api_v1_talent_pool_manager_group_cluster__organization_id___group_id__get',
    description: `List participating organizations in a group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_OrganizationGroupClusterOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/api/v1/talent-pool-manager/group/cluster/:organization_id/:group_id/:organization_group_id',
    alias:
      'remove_participating_organizations_api_v1_talent_pool_manager_group_cluster__organization_id___group_id___organization_group_id__delete',
    description: `Remove organization from a group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'organization_group_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/talent-pool-manager/group/cluster/:organization_id/:group_id/available-organizations',
    alias:
      'get_available_organizations_api_v1_talent_pool_manager_group_cluster__organization_id___group_id__available_organizations_get',
    description: `List organizations which can be added as participating in group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_ViewpointClusterAvailableOrganizationOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/api/v1/talent-pool-manager/group/logo/:organization_id/:group_id',
    alias:
      'update_group_logo_api_v1_talent_pool_manager_group_logo__organization_id___group_id__patch',
    description: `Update the logo of a group for an organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GroupLogoInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: GroupOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/talent-pool-manager/link/:organization_id/:group_id',
    alias: 'create_api_v1_talent_pool_manager_link__organization_id___group_id__post',
    description: `Generate a link to be used by job seekers to join a group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: skillscv_analytics_web__talent_pool_manager__schemas__link__LinkInSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__talent_pool_manager__schemas__link__LinkOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/talent-pool-manager/link/:organization_id/:group_id',
    alias: 'list_all_api_v1_talent_pool_manager_link__organization_id___group_id__get',
    description: `Retrieve all links in organization and a group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'is_active',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: skillscv_analytics_web__utils__pagination__CustomPage_LinkOutSchema___2,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/talent-pool-manager/link/:organization_id/:group_id/:link_id',
    alias: 'list_one_api_v1_talent_pool_manager_link__organization_id___group_id___link_id__get',
    description: `Show link`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'link_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__talent_pool_manager__schemas__link__LinkOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/talent-pool-manager/link/:organization_id/:group_id/:link_id',
    alias: 'update_api_v1_talent_pool_manager_link__organization_id___group_id___link_id__put',
    description: `Update a link which is used by job seekers to join an org/group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: skillscv_analytics_web__talent_pool_manager__schemas__link__LinkUpdateSchema,
      },
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'link_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: skillscv_analytics_web__talent_pool_manager__schemas__link__LinkOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/talent-pool-manager/link/:organization_id/:group_id/disable/:link_id',
    alias:
      'disable_api_v1_talent_pool_manager_link__organization_id___group_id__disable__link_id__put',
    description: `Soft delete a link`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'group_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'link_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/user/from-org',
    alias: 'remove_user_from_org_api_v1_user_from_org_post',
    description: `Remove user from organization: hard remove, it deletes all the roles attached to user within organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ViewpointRemoveUserFromOrgInSchema,
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/user/invite',
    alias: 'invite_input_data_org_api_v1_user_invite_post',
    description: `Create a new user with a role and send an invitation email`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ViewpointUserInSchema,
      },
    ],
    response: ViewpointNewUserOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/user/me',
    alias: 'get_me_api_v1_user_me_get',
    description: `information about the user that is logged in`,
    requestFormat: 'json',
    response: ViewpointExistingUserOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'put',
    path: '/api/v1/user/me',
    alias: 'update_me_api_v1_user_me_put',
    description: `update information about the user that is logged in`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ViewpointUserUpdateSchema,
      },
    ],
    response: ViewpointExistingUserOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'post',
    path: '/api/v1/user/role-in-org',
    alias: 'add_user_role_api_v1_user_role_in_org_post',
    description: `Add role to user in organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ViewpointAddUserRoleOrgInSchema,
      },
    ],
    response: z.object({ detail: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/user/search/in-org',
    alias: 'list_users_in_org_api_v1_user_search_in_org_get',
    description: `Retrieve all users in organization`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'organization_id',
        type: 'Query',
        schema: z.string().uuid(),
      },
      {
        name: 'role_id',
        type: 'Query',
        schema: z.string().uuid().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_ViewpointExistingUserOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/viewpoint-module',
    alias: 'list_all_api_v1_viewpoint_module_get',
    description: `List all modules`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'user_facing',
        type: 'Query',
        schema: z.boolean().optional().default(true),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().gte(1).lte(250).optional().default(25),
      },
    ],
    response: CustomPage_ViewpointModuleOutSchema_,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v1/viewpoint-module/:module_code',
    alias: 'list_one_api_v1_viewpoint_module__module_code__get',
    description: `Show viewpoint module`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'module_code',
        type: 'Path',
        schema: z.enum([
          'skillscv-admin-module',
          'coaching-module',
          'job-market-analysis-module',
          'search-and-match-module',
          'search-and-match-plus-module',
          'talent-pool-manager-module',
          'organization-set-up-module',
        ]),
      },
    ],
    response: ViewpointModuleWithGroupTypesOutSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 403,
        description: `Forbidden`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Conflict`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: ValidationErrorMessageOutSchema,
      },
      {
        status: 490,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 491,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 492,
        description: `Additional Response`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Internal Server Error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
])

export const api = new Zodios(endpoints)

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options)
}
