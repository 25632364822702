import { MODULES, ROLES } from '@/helper/Permissions.js'
import { lazy } from 'react'
// import { Navigate, Route } from 'react-router-dom'
import RequireAuth from '@/components/RequireAuth.js'
import AuthLayout from '@/layouts/AuthLayout'
import FullLayout from '@/layouts/FullLayout'
import InviteFailed from '@/views/auth/InviteFailed'
import PrivateRoutes from './PrivateRoutes'

const Unauthorized = lazy(() => import('@/components/Unauthorized'))
const Home = lazy(() => import('@/views/Home'))
const Profile = lazy(() => import('@/views/Profile'))
const ChangePassword = lazy(() => import('@/views/ChangePassword'))
const GroupStatistics = lazy(() => import('@/views/analytics/GroupStatistics'))
const MemberStatistics = lazy(() => import('@/views/analytics/MemberStatistics'))
const Staff = lazy(() => import('@/views/staff/Staff'))
const AddNewStaff = lazy(() => import('@/views/staff/AddNewStaff'))
const InviteCandidates = lazy(() => import('@/views/links/InviteCandidates'))
//const TalentpoolInviteCandidates = lazy(() => import('@/views/links/TalentpoolInviteCandidates'))
const Group = lazy(() => import('@/views/group/Group'))
const GroupAdd = lazy(() => import('@/views/group/GroupAdd'))
const GroupDetails = lazy(() => import('@/views/group/GroupDetails'))
const UserProfile = lazy(() => import('@/views/group/UserProfile'))
const Professions = lazy(() => import('@/views/analytics/Professions'))
const Organization = lazy(() => import('@/views/organization/Organization'))
const NewOrganization = lazy(() => import('@/views/organization/NewOrganization'))
const OrgDetails = lazy(() => import('@/views/organization/OrgDetails'))
const Login = lazy(() => import('@/views/auth/Login'))
const ForgotPassword = lazy(() => import('@/views/auth/ForgotPassword'))
const ResetPassword = lazy(() => import('@/views/auth/ResetPassword'))
const EmailConfirmation = lazy(() => import('@/views/auth/EmailConfirmation'))
const ResetSuccess = lazy(() => import('@/views/auth/ResetSuccess'))
const NewLogin = lazy(() => import('@/views/auth/NewLogin'))
const TalentPool = lazy(() => import('@/views/talentpool/TalentPool'))
const TalentPoolUser = lazy(() => import('@/views/talentpool/TalentPoolUser'))
const ApiDocs = lazy(() => import('@/views/docs/ApiDocs'))
const DocsHome = lazy(() => import('@/views/docs/DocsHome'))
const NotFound = lazy(() => import('@/views/NotFound'))
const JobSeekerInsights = lazy(() => import('@/views/analytics/JobSeekerInsights'))
const JobProfileInsights = lazy(() => import('@/views/analytics/JobProfileInsights'))
const AllVacancies = lazy(() => import('@/views/search-and-match/AllVacancies'))
const MyCandidates = lazy(() => import('@/views/search-and-match/MyCandidates'))
const JobOfferCandidates = lazy(() => import('@/views/search-and-match/JobOfferCandidates'))
const MyApplications = lazy(() => import('@/views/search-and-match/MyApplications'))
const VacancyDetailsPage = lazy(() => import('@/views/search-and-match/VacancyDetailsPage'))
const EmailInvite = lazy(() => import('@/views/auth/EmailInvite'))
const RenewSubscription = lazy(() => import('@/views/auth/RenewSubscription'))
const Invite = lazy(() => import('@/views/auth/Invite'))
const InviteMembers = lazy(() => import('@/views/links/InviteMembers'))
const InviteSuccess = lazy(() => import('@/views/auth/InviteSuccess'))
const TalentpoolManager = lazy(() => import('@/views/talentpool-manager/TalentpoolManager'))
const AddTalentpool = lazy(() => import('@/views/talentpool-manager/AddTalentpool'))
//const Campaigns = lazy(() => import('@/views/events/Campaigns'))
const EcomOrganization = lazy(() => import('@/views/e-commerce/Organization'))
const SubscriptionUsage = lazy(() => import('@/views/SubscriptionUsage'))

const TalentPoolAllVacancies = lazy(() => import('@/views/search-and-match-plus/AllVacancies'))
//const TalentPoolMyCandidates = lazy(() => import('@/views/search-and-match-plus/MyCandidates'))
const TalentPoolJobOfferCandidates = lazy(
  () => import('@/views/search-and-match-plus/JobOfferCandidates'),
)
const TalentPoolMijnSollicitaties = lazy(
  () => import('@/views/search-and-match-plus/MyApplications'),
)
const TalentPoolVacancyDetailsPage = lazy(
  () => import('@/views/search-and-match-plus/VacancyDetailsPage'),
)

const fullAuthRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[
        ROLES.OrganizationAdmin,
        ROLES.OrganizationStaff,
        ROLES.SkillsCVStaff,
        ROLES.ViewAPIDocs,
        ROLES.SkillsCVSuperAdmin,
        ROLES.ReadOnlyDemoUser,
        ROLES.WhiteLabelLiteUser,
      ]}
      allowedModules={[
        MODULES.CoachingModule,
        MODULES.JobMarketAnalysisModule,
        MODULES.SearchAndMatchModule,
        MODULES.SkillscvAdminModule,
        MODULES.SearchAndMatchPlusModule,
        MODULES.TalentPoolManagerModule,
        MODULES.OrganizationSetUpModule,
      ]}
    />
  ),
  children: [
    { path: '/', element: <Home /> },
    { path: 'profile', element: <Profile /> },
    { path: 'change-password', element: <ChangePassword /> },
  ],
}

const organizationAdminRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[ROLES.SkillsCVStaff, ROLES.SkillsCVSuperAdmin]}
      allowedModules={[MODULES.SkillscvAdminModule]}
    />
  ),
  children: [
    { path: 'organization-list', element: <Organization /> },
    { path: 'organization-list/:id', element: <OrgDetails /> },
    { path: 'organization-list/add-new', element: <NewOrganization /> },
  ],
}

const jobSeekerInsightRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[ROLES.SkillsCVStaff, ROLES.SkillsCVSuperAdmin, ROLES.ReadOnlyDemoUser]}
      allowedModules={[
        MODULES.CoachingModule,
        MODULES.JobMarketAnalysisModule,
        MODULES.SearchAndMatchModule,
        MODULES.SkillscvAdminModule,
      ]}
    />
  ),
  children: [
    { path: 'jobseeker-insights', element: <JobSeekerInsights /> },
    { path: 'job-application-insights', element: <JobProfileInsights /> },
  ],
}

const apiDocsRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[ROLES.ViewAPIDocs, ROLES.SkillsCVSuperAdmin]}
      allowedModules={[MODULES.SkillscvAdminModule]}
    />
  ),
  children: [
    { path: 'docs/:id', element: <ApiDocs /> },
    { path: 'docs', element: <DocsHome /> },
  ],
}

const groupStasticRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[ROLES.OrganizationAdmin, ROLES.OrganizationStaff, ROLES.SkillsCVSuperAdmin]}
      allowedModules={[MODULES.CoachingModule, MODULES.SearchAndMatchModule]}
    />
  ),
  children: [{ path: 'group-statistics', element: <GroupStatistics /> }],
}

const memberStasticRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[ROLES.OrganizationAdmin, ROLES.OrganizationStaff, ROLES.SkillsCVSuperAdmin]}
      allowedModules={[MODULES.CoachingModule, MODULES.SearchAndMatchModule]}
    />
  ),
  children: [{ path: 'member-statistics', element: <MemberStatistics /> }],
}

const staffRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[ROLES.OrganizationAdmin, ROLES.OrganizationStaff, ROLES.SkillsCVSuperAdmin]}
      allowedModules={[
        MODULES.CoachingModule,
        MODULES.SearchAndMatchModule,
        MODULES.SearchAndMatchPlusModule,
        MODULES.TalentPoolManagerModule,
      ]}
    />
  ),
  children: [
    { path: 'staff', element: <Staff /> },
    { path: 'staff/add-new', element: <AddNewStaff /> },
  ],
}

const usageStatRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[
        ROLES.OrganizationAdmin,
        ROLES.OrganizationStaff,
        ROLES.SkillsCVStaff,
        ROLES.ViewAPIDocs,
        ROLES.SkillsCVSuperAdmin,
        ROLES.ReadOnlyDemoUser,
        ROLES.WhiteLabelLiteUser,
      ]}
      allowedModules={[MODULES.SearchAndMatchPlusModule]}
    />
  ),
  children: [{ path: 'subscription-usage', element: <SubscriptionUsage /> }],
}

const inviteCandidatesRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[
        ROLES.OrganizationAdmin,
        ROLES.OrganizationStaff,
        ROLES.SkillsCVStaff,
        ROLES.SkillsCVSuperAdmin,
      ]}
      allowedModules={[MODULES.SearchAndMatchModule]}
    />
  ),
  children: [{ path: 'invite-candidates', element: <InviteCandidates /> }],
}

//EcomOrganization

const EcomRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[ROLES.OrganizationAdmin, ROLES.SkillsCVSuperAdmin]}
      allowedModules={[MODULES.OrganizationSetUpModule]}
    />
  ),
  children: [{ path: 'organization', element: <EcomOrganization /> }],
}

const inviteMembersRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[ROLES.OrganizationStaff, ROLES.OrganizationAdmin, ROLES.SkillsCVSuperAdmin]}
      allowedModules={[MODULES.CoachingModule]}
    />
  ),
  children: [{ path: 'invite-members', element: <InviteMembers /> }],
}

const EventsRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[
        ROLES.SkillsCVStaff,
        ROLES.SkillsCVSuperAdmin,
        ROLES.ReadOnlyDemoUser,
        ROLES.OrganizationAdmin,
        ROLES.OrganizationStaff,
      ]}
      allowedModules={[MODULES.TalentPoolManagerModule]}
    />
  ),
  children: [
    { path: 'talentpool-manager', element: <TalentpoolManager /> },
    { path: 'talentpool-manager/add', element: <AddTalentpool /> },
    // { path: 'Campaigns', element: <Campaigns /> },
  ],
}

const groupsRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[ROLES.OrganizationStaff, ROLES.OrganizationAdmin, ROLES.SkillsCVSuperAdmin]}
      allowedModules={[MODULES.CoachingModule]}
    />
  ),
  children: [
    { path: 'my-groups', element: <Group /> },
    { path: 'my-groups/add-new', element: <GroupAdd /> },
    { path: 'my-groups/:groupId', element: <GroupDetails /> },
    { path: 'my-groups/:groupId/:memberId', element: <UserProfile /> },
  ],
}

const myCandidatesRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[
        ROLES.OrganizationAdmin,
        ROLES.OrganizationStaff,
        ROLES.SkillsCVStaff,
        ROLES.SkillsCVSuperAdmin,
      ]}
      allowedModules={[MODULES.SearchAndMatchModule]}
    />
  ),
  children: [{ path: 'all-candidates', element: <MyCandidates /> }],
}

// const talentpoolCandidatesRoutes = {
//   element: (
//     <RequireAuth
//       allowedRoles={[
//         ROLES.OrganizationAdmin,
//         ROLES.OrganizationStaff,
//         ROLES.SkillsCVStaff,
//         ROLES.SkillsCVSuperAdmin,
//       ]}
//       allowedModules={[MODULES.SearchAndMatchPlusModule]}
//     />
//   ),
//   children: [{ path: 'talentpool-candidates', element: <TalentPoolMyCandidates /> }],
// }

const allVacanciesRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[
        ROLES.OrganizationAdmin,
        ROLES.OrganizationStaff,
        ROLES.SkillsCVStaff,
        ROLES.SkillsCVSuperAdmin,
        ROLES.WhiteLabelLiteUser,
      ]}
      allowedModules={[MODULES.SearchAndMatchModule]}
    />
  ),
  children: [
    { path: 'all-vacancies', element: <AllVacancies /> },
    {
      path: 'all-vacancies/vacancy/:orgId/:jobOfferCode',
      element: <VacancyDetailsPage />,
    },
    {
      path: 'all-vacancies/candidates/:jobOfferCode',
      element: <JobOfferCandidates />,
    },
  ],
}

const talentpoolVacanciesRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[
        ROLES.OrganizationAdmin,
        ROLES.OrganizationStaff,
        ROLES.SkillsCVStaff,
        ROLES.SkillsCVSuperAdmin,
        ROLES.WhiteLabelLiteUser,
      ]}
      allowedModules={[MODULES.SearchAndMatchPlusModule]}
    />
  ),
  children: [
    { path: 'talentpool-vacancies', element: <TalentPoolAllVacancies /> },
    {
      path: 'talentpool-vacancies/vacancy/:orgId/:jobOfferCode',
      element: <TalentPoolVacancyDetailsPage />,
    },
    {
      path: 'talentpool-vacancies/candidates/:jobOfferCode',
      element: <TalentPoolJobOfferCandidates />,
    },
  ],
}

const myApplicationsRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[
        ROLES.OrganizationAdmin,
        ROLES.OrganizationStaff,
        ROLES.SkillsCVStaff,
        ROLES.SkillsCVSuperAdmin,
        ROLES.WhiteLabelLiteUser,
      ]}
      allowedModules={[MODULES.SearchAndMatchModule]}
    />
  ),
  children: [{ path: 'my-applications', element: <MyApplications /> }],
}

const talentpoolApplicationsRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[
        ROLES.OrganizationAdmin,
        ROLES.OrganizationStaff,
        ROLES.SkillsCVStaff,
        ROLES.SkillsCVSuperAdmin,
        ROLES.WhiteLabelLiteUser,
      ]}
      allowedModules={[MODULES.SearchAndMatchPlusModule]}
    />
  ),
  children: [{ path: 'talentpool-applications', element: <TalentPoolMijnSollicitaties /> }],
}

const professionsRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[
        ROLES.OrganizationAdmin,
        ROLES.OrganizationStaff,
        ROLES.SkillsCVStaff,
        ROLES.SkillsCVSuperAdmin,
        ROLES.ReadOnlyDemoUser,
      ]}
      allowedModules={[MODULES.JobMarketAnalysisModule]}
    />
  ),
  children: [{ path: 'professions', element: <Professions /> }],
}

const talentpoolRoutes = {
  element: (
    <RequireAuth
      allowedRoles={[ROLES.ReadOnlyDemoUser, ROLES.SkillsCVSuperAdmin, ROLES.SkillsCVStaff]}
      allowedModules={[
        MODULES.CoachingModule,
        MODULES.JobMarketAnalysisModule,
        MODULES.SearchAndMatchModule,
        MODULES.SkillscvAdminModule,
      ]}
    />
  ),
  children: [
    { path: 'talentpool', element: <TalentPool /> },
    {
      path: 'talentpool/:skillscvId',
      element: <TalentPoolUser />,
    },
  ],
}

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', ...fullAuthRoutes },
      { path: '/', ...organizationAdminRoutes },
      { path: '/', ...jobSeekerInsightRoutes },
      { path: '/', ...groupStasticRoutes },
      { path: '/', ...memberStasticRoutes },
      { path: '/', ...staffRoutes },
      { path: '/', ...inviteCandidatesRoutes },
      { path: '/', ...groupsRoutes },
      { path: '/', ...myCandidatesRoutes },
      { path: '/', ...inviteMembersRoutes },
      { path: '/', ...allVacanciesRoutes },
      { path: '/', ...myApplicationsRoutes },
      { path: '/', ...professionsRoutes },
      { path: '/', ...talentpoolRoutes },
      { path: '/', ...EventsRoutes },
      { path: '/', ...talentpoolVacanciesRoutes },
      { path: '/', ...talentpoolApplicationsRoutes },
      { path: '/', ...EcomRoutes },
      { path: '/', ...usageStatRoutes },
      //{ path: '/', ...talentpoolInviteCandidatesRoutes },
      { path: 'unauthorized', element: <Unauthorized /> },
    ],
  },
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: (
          <PrivateRoutes>
            <Login />
          </PrivateRoutes>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <PrivateRoutes>
            <ForgotPassword />
          </PrivateRoutes>
        ),
      },
      { path: 'email-confirmation', element: <EmailConfirmation /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: 'reset-success', element: <ResetSuccess /> },
      { path: 'new-login', element: <NewLogin /> },
      { path: 'email-invite-confirmation', element: <EmailInvite /> },
      { path: 'invite', element: <Invite /> },
      { path: 'invite-success', element: <InviteSuccess /> },
      { path: 'invite-failed', element: <InviteFailed /> },
      { path: 'renew-subscription', element: <RenewSubscription /> },
      { path: '/', ...apiDocsRoutes },
    ],
  },
  { path: '*', element: <NotFound /> },
]

export default ThemeRoutes
