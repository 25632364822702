import Header from './Header'
import { Col } from 'react-bootstrap'
import { Suspense } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
//import Footer from './Footer'
import Sidebar from './Sidebar'
import Loader from './loader/Loader'
import useHeader from '@/hooks/useHeader'

const FullLayout = () => {
  const location = useLocation()
  //const [show, setShow] = useState<boolean>(false)

  const { toggle, logout, auth, user, show, closeToggle } = useHeader()

  const isAPIDocs =
    auth.roles &&
    auth.roles.find((value: string) => value === 'ViewAPIDocs' || 'SkillsCVSuperAdmin')

  return (
    <main>
      <div
        className={`pageWrapper d-lg-flex ${
          isAPIDocs && location.pathname === '/docs' ? 'has-docs' : ''
        }`}
      >
        <div className='contentArea'>
          {/********header**********/}
          <Header toggleMenu={toggle} user={user} onLogout={logout} show={show} />
          {/********Middle Content**********/}
          <div className={`main-wrapper  ${show === true ? 'active' : ''}`}>
            {auth && auth.email && (
              <aside className={`skills-sidebar ${show === true ? 'active' : ''}`}>
                <Sidebar toggleMenu={closeToggle} onLogout={logout} />
              </aside>
            )}
            <Col lg='12' className='px-4 py-3'>
              <Suspense fallback={<Loader />}>
                <Outlet />
              </Suspense>
            </Col>
          </div>
          {/* <Footer /> */}
          <div className={`overlay ${show === true ? 'active' : ''}`} onClick={closeToggle}></div>
        </div>
      </div>
    </main>
  )
}

export default FullLayout
