import { useRef } from 'react'

type CopySnippetType = {
  copied: boolean
  handleCopy: (...args: any[]) => any
  children: JSX.Element
  mode: 'light' | 'dark'
  output?: 'html' | 'text'
}

const CopySnippet = ({ copied, handleCopy, children, mode, output }: CopySnippetType) => {
  const contentRef = useRef<HTMLDivElement>(null)
  return (
    <div className={`clip-snippet border p-3 rounded position-relative mb-3 ${mode}`}>
      <div
        className='copy-btn position-absolute top-0 end-0 p-3 cursor'
        onClick={() => handleCopy(contentRef, 'div', output)}
      >
        <i className='bi bi-copy'></i>
        {copied && <span className='ms-2'>Copied!</span>}
      </div>
      <div className='content' ref={contentRef}>
        {children}
      </div>
    </div>
  )
}

export default CopySnippet
