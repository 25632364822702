import React from 'react'
import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import '@/assets/scss/style.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Loader from './layouts/loader/Loader'
import { AuthProvider } from './context/AuthProvider'
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import i18n from './i18n/locales/config'
import { I18nextProvider } from 'react-i18next'
//import { ErrorBoundaries } from './components/ErrorBoundaries'
import * as Sentry from '@sentry/react'
import SentryError from './components/SentryError'

window.addEventListener('vite:preloadError', () => {
  window.location.reload() // for example, refresh the page
})

Sentry.init({
  environment: import.meta.env.VITE_ENV,
  dsn: 'https://5c6aa9c413b31ce5362fd45d548a9d30@o4506219015700480.ingest.sentry.io/4506263056613376',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: [import.meta.env.VITE_DOCS_BASE_URL],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null
    }
    return event
  },
})

// if (localStorage.getItem('menu') === null) {
//   localStorage.setItem('menu', 'false')
// }

//import './interceptors/axios';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Suspense fallback={<Loader />}>
    <BrowserRouter>
      <Sentry.ErrorBoundary fallback={<SentryError />}>
        <AuthProvider>
          <I18nextProvider i18n={i18n}>
            <Routes>
              <Route path='/*' element={<App />} />
            </Routes>
          </I18nextProvider>
        </AuthProvider>
      </Sentry.ErrorBoundary>
    </BrowserRouter>
  </Suspense>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
