//import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const InviteFailed = () => {
  //const { t } = useTranslation()
  return (
    <div className='login-container align-items-center d-flex justify-content-center bg-white'>
      <div className='auth-signin' style={{ width: '30rem' }}>
        {/* <h5 className='mb-2 fw-normal text-muted fs-12'>Invite user</h5> */}
        <h4 className='mb-4 text-primary'>
          <b>Helaas</b>, deze link is niet meer geldig!
        </h4>

        <p className='mb-4'>
          Neem <Link to={`mailto: viewpoint@skillscv.nl`}>contact</Link> met ons op
        </p>

        {/* <div className='mb-4'>
          <Link
            target='_blank'
            to={`${import.meta.env.VITE_SKILLSCV_APP}/login`}
            className='btn btn-purple action-btn'
          >
            Log in op SkillsCV
          </Link>
        </div> */}
      </div>
    </div>
  )
}

export default InviteFailed
