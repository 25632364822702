import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import english from './en/translations.json'
import dutch from './nl/translations.json'

if (localStorage.getItem('lang') === null) {
  localStorage.setItem('lang', 'nl')
}
i18n.use(initReactI18next).init({
  debug: false,
  lng: localStorage.getItem('lang') || 'nl',
  fallbackLng: 'en',
  resources: {
    en: {
      translations: english,
    },
    nl: {
      translations: dutch,
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
})

i18n.languages = ['en', 'nl']

export default i18n
