import useAuth from './useAuth'
import { axiosPublic } from '@/api/axios'
import { authLogout } from '@/service/shared.service'
import { Token } from '@/interfaces/skillscv'
import Cookies from 'js-cookie'

const useRefreshToken = () => {
  const { setAuth, auth, setUser }: any = useAuth()

  // const axiosConfig = {
  //   headers: {
  //     Authorization: `Bearer ${auth?.accessToken}`,
  //   },
  // }
  const refresh = async () => {
    try {
      const response = await axiosPublic.post(
        'v1/get-refresh-token',
        { refresh: auth?.refreshToken, email: auth?.email },
        //axiosConfig,
      )
      //console.log(response)
      setAuth((prev: Token) => {
        return { ...prev, accessToken: response.data.access }
      })
      // Also update localstorage with new token
      const newToken = auth
      newToken.accessToken = response.data.access
      if (newToken && newToken.accessToken && response.data) {
        //localStorage.setItem('user', JSON.stringify(newToken))
        Cookies.set('user', JSON.stringify(newToken), { sameSite: 'strict', secure: true })
      }
      // console.log(response.data.access)
      // console.log(newToken.accessToken)

      return response.data.access
    } catch (error: any) {
      authLogout().then(() => {
        setUser({})
        setAuth({})
      })
    }
  }
  return refresh
}

export default useRefreshToken
