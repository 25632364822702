import logo from '@/assets/images/logos/logo.png'
import logoDark from '@/assets/images/logos/logo-dark.png'

import { Link } from 'react-router-dom'

const Logo = (props: { type: string; width: string | number | undefined }) => {
  return (
    <Link to='/'>
      <img
        loading='lazy'
        src={props.type === 'dark' ? logoDark : logo}
        width={props.width}
        alt='Logo'
      />
    </Link>
  )
}

export default Logo
