import { Button } from 'react-bootstrap'
import Cookies from 'js-cookie'
import blockImage from '@/assets/images/404.png'

const SentryError = () => {
  const reloadApp = () => {
    Cookies.remove('user')
    Cookies.remove('userInfo')
    window.location.replace('/login')
  }

  return (
    <div
      className='d-flex align-items-center justify-content-center text-center'
      style={{ height: '100vh' }}
    >
      <div className='p-5'>
        <div className='mb-4'>
          <img loading='lazy' src={blockImage} alt='Profile' />
        </div>

        <h3>
          <i className='bi bi-exclamation-triangle-fill text-danger'></i> Something went wrong !
        </h3>

        <p className='fw-normal mb-4'>
          Brace yourself till we get the error fixed. <br /> You may also try to refresh the page or
          logout.
        </p>

        {/* {(import.meta.env.REACT_APP_ENV === 'DEV' ||
              import.meta.env.REACT_APP_ENV === 'ACC') && <p>{this.state.errorMessage}</p>} */}
        <div className='pt-3'>
          <Button variant='outline-primary' onClick={reloadApp} className='me-2'>
            Logout
          </Button>
          <Button variant='outline-pink' onClick={() => window.location.reload()}>
            Reload App
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SentryError
