import { useRef, useState } from 'react'
import { toast } from 'react-toastify'

const useCopyQR = () => {
  const canvasRef = useRef<any>(null)
  const [copied, setCopied] = useState(false)

  const handleCopy = async () => {
    if (!canvasRef.current) return

    try {
      const svgElement = canvasRef.current.querySelector('svg')
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const svgString = new XMLSerializer().serializeToString(svgElement)
      const img = new Image()

      img.onload = () => {
        canvas.width = img.width
        canvas.height = img.height
        ctx && ctx.drawImage(img, 0, 0)

        canvas.toBlob((blob) => {
          const item = new ClipboardItem({ 'image/png': blob })
          navigator.clipboard.write([item]).then(() => {
            setCopied(true)
            toast.success('QR Code copied to clipboard!')
            setTimeout(() => setCopied(false), 2000) // Reset copied state after 2 seconds
          })
        })
      }

      img.src = 'data:image/svg+xml;base64,' + window.btoa(svgString)
    } catch (err) {
      console.error('Failed to copy QR code: ', err)
    }
  }

  return {
    handleCopy,
    copied,
    canvasRef,
  }
}

export default useCopyQR
