import { Suspense } from 'react'
import AuthHeader from './AuthHeader'
import { Outlet } from 'react-router-dom'
import Loader from './loader/Loader'
import Header from './Header'
import useHeader from '@/hooks/useHeader'

const AuthLayout = () => {
  const { toggle, logout, user } = useHeader()

  return (
    <>
      <Suspense fallback={<Loader />}>
        {user && user?.email ? (
          <Header toggleMenu={toggle} user={user} onLogout={logout} />
        ) : (
          <AuthHeader />
        )}
        <Outlet />
      </Suspense>
    </>
  )
}

export default AuthLayout
