import { axiosPublic } from '@/api/axios'
import { useEffect } from 'react'
import useRefreshToken from './useRefreshToken'
import useAuth from './useAuth'
//import { authLogout } from '@/service/shared.service'

const useAxiosPublic = () => {
  const refresh = useRefreshToken()
  const { auth, setAuth, setUser }: any = useAuth()

  useEffect(() => {
    const requestIntercept = axiosPublic.interceptors.request.use(
      (config) => {
        config.headers = config.headers ?? {}
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${auth?.accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error),
    )

    const responseIntercept = axiosPublic.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          if (error.response.data.detail === 'Token expired') {
            prevRequest.sent = true
            const newAccessToken = await refresh()
            prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
            return axiosPublic(prevRequest)
          }
          // else {
          //   authLogout().then(() => {
          //     setUser({})
          //     setAuth({})
          //   })
          // }
        }
        return Promise.reject(error)
      },
    )

    return () => {
      axiosPublic.interceptors.request.eject(requestIntercept)
      axiosPublic.interceptors.response.eject(responseIntercept)
    }
  }, [auth, refresh, setAuth, setUser])

  return axiosPublic
}

export default useAxiosPublic
