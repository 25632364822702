import { useLocation, Navigate, Outlet } from 'react-router-dom'
import useAuth from '@/hooks/useAuth'

interface allowRolesType {
  allowedRoles: Array<string>
  allowedModules: Array<string>
}

const RequireAuth: React.FC<allowRolesType> = ({
  allowedRoles,
  allowedModules,
}: allowRolesType) => {
  const { auth } = useAuth()
  const location = useLocation()

  return auth?.roles?.find((role: string) => allowedRoles?.includes(role)) &&
    auth?.modules?.find((module: string) => allowedModules?.includes(module)) &&
    auth.roles.length > 0 ? (
    <Outlet />
  ) : auth?.email ? (
    <Navigate to='/unauthorized' state={{ from: location }} replace />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  )
}

export default RequireAuth
