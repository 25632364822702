import AuthContext from '@/context/AuthProvider'
import { useSharedService, authLogout } from '@/service/shared.service'
import { t } from 'i18next'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const useHeader = () => {
  const { auth, user, setAuth, setUser }: any = useContext(AuthContext)

  if (localStorage.getItem('menu') === null) {
    localStorage.setItem('menu', 'false')
  }

  const menuState = localStorage.getItem('menu') ? localStorage.getItem('menu') : false

  const [show, setShow] = useState<boolean>(JSON.parse(menuState as string))
  const service = useSharedService()
  const navigate = useNavigate()

  const logout = () => {
    service
      .logout(auth.email, auth.refreshToken)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t('logout_success'))
          authLogout().then(() => {
            setUser({})
            setAuth({})
            navigate('/login')
          })
        }
      })
      .catch(() => {
        authLogout().then(() => {
          setUser({})
          setAuth({})
          navigate('/login')
        })
      })
  }

  const toggle = () => {
    setShow(!show)
    localStorage.setItem('menu', JSON.stringify(!show))
  }

  const closeToggle = () => {
    setShow(false)
    localStorage.setItem('menu', JSON.stringify(false))
  }

  return {
    toggle,
    logout,
    user,
    auth,
    show,
    closeToggle,
  }
}

export default useHeader
