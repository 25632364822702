import Logo from './Logo'

import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import { MouseEventHandler } from 'react'
import { User } from '@/interfaces/skillscv'
//import DropdownLanguage from '@/components/shared/DropdownLanguage'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import DropdownLanguage from '@/components/shared/DropdownLanguage'

interface header {
  toggleMenu: MouseEventHandler<HTMLButtonElement> | undefined
  user: User
  onLogout: MouseEventHandler<HTMLButtonElement> | undefined
  show?: boolean
}

const Header: React.FC<header> = ({ toggleMenu, user, onLogout, show }: header) => {
  const { t } = useTranslation()

  // const [isScrolled, setIsScrolled] = useState(false)

  // useEffect(() => {
  //   const handleScroll = () => {
  //     // Set isScrolled to true when the user has scrolled down, and false otherwise
  //     setIsScrolled(window.scrollY > 0)
  //   }

  //   // Attach the scroll event listener when the component mounts
  //   window.addEventListener('scroll', handleScroll)

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [])

  return (
    <Navbar variant='light' className={`main-header scrolled`} fixed='top'>
      <Container fluid>
        <Navbar.Brand className='d-flex align-items-center'>
          <i
            className={`bi bi-list me-3 cursor ${show ? 'text-pink' : ''}`}
            onClick={toggleMenu}
          ></i>
          <Logo type='dark' width='160' />
        </Navbar.Brand>

        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ms-auto align-items-center'>
            <div className='me-2'>
              <DropdownLanguage></DropdownLanguage>
            </div>
            {/* <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link> */}
            <NavDropdown
              title={
                <div className='d-flex align-items-center'>
                  <div className='me-2'>
                    <i className='fa-solid fa-circle-user fa-2xl text-primary'></i>
                  </div>
                  <div>
                    <div className='fs-11 text-muted fw-semibold text-containers'>
                      {user.organizations && user.organizations[0]?.organizationName}
                    </div>
                    <div className='fs-11 text-muted text-containers'>{user.email}</div>
                  </div>
                </div>
              }
              id='basic-nav-dropdown'
              align='end'
            >
              <Nav.Item className='px-3 py-3'>
                <div className='d-flex align-items-center'>
                  <span>
                    <i className='bi bi-person-fill-gear text-primary'></i>
                  </span>
                  <div className='ps-2'>
                    <div className='fw-semibold fs-11'>
                      {user?.organizations?.[0]?.roles?.join(', ') || ''}
                      {/* {user.firstName || user.lastName ? user.firstName + ' ' + user.lastName : 'User' } */}
                    </div>
                    {/* <div className='fs-12 text-muted mb-2'>{user.email}</div>
                    <div className='fs-12 text-muted fw-semibold'>
                      {user.organizations && user.organizations[0]?.organizationName}
                    </div> */}
                  </div>
                </div>
              </Nav.Item>
              <NavDropdown.Divider className='opacity-50 m-0' />
              <NavDropdown.Item className='py-2' as={Link} to='/profile'>
                <i className='icon icon-user-white me-2'></i>
                {t('profile')}
              </NavDropdown.Item>
              <NavDropdown.Item className='py-2' as={Link} to='/change-password'>
                <i className='icon icon-password me-2'></i>
                {t('change_password')}
              </NavDropdown.Item>
              <NavDropdown.Item
                className='py-2'
                as={Link}
                to={'https://thetalentpoolcommunity.nl/privacybeleid'}
                target='_blank'
              >
                <i className='bi bi-shield me-2'></i>
                {t('privacy_statement')}
              </NavDropdown.Item>
              <NavDropdown.Item
                className='py-2'
                as={Link}
                to={'https://thetalentpoolcommunity.nl/algemene-voorwaarden'}
                target='_blank'
              >
                <i className='bi bi-circle me-2'></i>
                {t('terms_and_cond')} {import.meta.env.REACT_APP_ENV}
              </NavDropdown.Item>
              {/* <NavDropdown.Divider className='opacity-50 m-0' /> */}
              <NavDropdown.Item onClick={onLogout} className='py-2'>
                <i className='icon icon-logout me-2'></i>Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>

        {/* <div className='me-3 ms-auto'>
          <DropdownLanguage></DropdownLanguage>
        </div>

        <NavDropdown
          className='profile-drowdown-wrapper'
          title={<i className='bi bi-list'></i>}
          id='profile-dropdown'
          align='end'
        >
          <NavDropdown.Item href='/profile'>{t('profile')}</NavDropdown.Item>
          <NavDropdown.Item href='/change-password'>{t('change_password')}</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={props.onLogout}>Logout</NavDropdown.Item>
        </NavDropdown> */}
      </Container>
    </Navbar>
  )
}

export default Header
