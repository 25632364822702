import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import AuthContext from '@/context/AuthProvider.js'

const PrivateRoutes = ({ children }: { children: JSX.Element }) => {
  const { user } = useContext(AuthContext)
  return user && user?.email ? <Navigate to='/' /> : children
}

export default PrivateRoutes
