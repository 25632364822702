import { zodiosApi } from '@/api/axios'
import { useEffect } from 'react'
import useRefreshToken from './useRefreshToken'
import useAuth from './useAuth'
import { authLogout } from '@/service/shared.service'
import { useNavigate } from 'react-router-dom'
//import { toast } from 'react-toastify'

const useAxiosPrivate = () => {
  const refresh = useRefreshToken()
  const navigate = useNavigate()
  const { auth, setAuth, setUser, user }: any = useAuth()

  const isEcom = user?.organizations?.[0]?.idEcommerce || false ? true : false

  useEffect(() => {
    const requestIntercept = zodiosApi.axios.interceptors.request.use(
      (config) => {
        config.headers = config.headers ?? {}
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${auth?.accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error),
    )

    const responseIntercept = zodiosApi.axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config

        if (error?.response?.status === 401 && !prevRequest?.sent) {
          if (error.response.data.detail === 'Token expired') {
            // refresh token
            prevRequest.sent = true
            const newAccessToken = await refresh()
            prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
            return zodiosApi.axios(prevRequest)
          } else {
            authLogout().then(() => {
              setUser({})
              setAuth({})
              navigate('/login')
            })
          }
        }

        if (error?.response?.status === 490) {
          authLogout().then(() => {
            setUser({})
            setAuth({})
            navigate('/renew-subscription')
          })
        }

        if (error?.response?.status === 491 && isEcom) {
          navigate('/subscription-usage?error=user')
        }

        if (error?.response?.status === 492 && isEcom) {
          navigate('/subscription-usage?error=candidate')
        }

        if (error?.response?.status === 403) {
          authLogout().then(() => {
            setUser({})
            setAuth({})
            navigate('/login')
          })
        }

        return Promise.reject(error)
      },
    )

    return () => {
      zodiosApi.axios.interceptors.request.eject(requestIntercept)
      zodiosApi.axios.interceptors.response.eject(responseIntercept)
    }
  }, [auth, refresh, setAuth, setUser])

  return zodiosApi
}

export default useAxiosPrivate
