import { createContext, useState, ReactNode } from 'react'
import { Token, User } from '@/interfaces/skillscv'
import Cookies from 'js-cookie'

type AuthContextType = {
  auth?: Token
  user?: User
  setAuth?: (arg0: Token) => void
  setUser?: (arg0: User) => void
}

const AuthContext = createContext<AuthContextType>({})

interface Props {
  children?: ReactNode
  // any props that come into the component
}

const defaultTokenData: Token = {
  email: '',
  roles: [],
  modules: [],
  accessToken: '',
  refreshToken: '',
  loading: false,
}

const defaultUserData: User = {
  firstName: '',
  lastName: '',
  email: '',
  status: '',
  userId: '',
  organizations: [],
}

export const AuthProvider = ({ children }: Props) => {
  const token = JSON.parse(Cookies.get('user') ?? JSON.stringify(defaultTokenData))
  const userInfo = JSON.parse(Cookies.get('userInfo') ?? JSON.stringify(defaultUserData))
  const [auth, setAuth] = useState(token)
  const [user, setUser] = useState(userInfo)

  return (
    <AuthContext.Provider value={{ auth, user, setAuth, setUser }}>{children}</AuthContext.Provider>
  )
}

export default AuthContext
