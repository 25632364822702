import useAxiosPrivate from '@/hooks/useAxiosPrivate'
import useAxiosPublic from '@/hooks/useAxiosPublic'
import { updateLinkType } from '@/interfaces/skillscv'
import Cookies from 'js-cookie'

export const useSharedService = () => {
  const zodiosApi = useAxiosPrivate()
  const axiosPublic = useAxiosPublic()
  return {
    updateProfile: async (firstName: string, lastName: string) => {
      const response = await zodiosApi.update_me_api_v1_user_me_put({
        firstName,
        lastName,
      })
      return response
    },
    getUserDetails: async () => {
      const response = await zodiosApi.get_me_api_v1_user_me_get()
      return response
    },
    logout: async (email: string, refreshToken: string) => {
      const response: any = await axiosPublic.post('v1/revoke-token', {
        email: email,
        refresh: refreshToken,
      })
      return response
    },
    changePassword: async (oldPassword: string, newPassword: string) => {
      const response: any = await axiosPublic.post('v1/change-password', {
        oldPassword: oldPassword,
        newPassword: newPassword,
      })
      return response
    },
    getTypeOfRoles: async () => {
      const response = await zodiosApi.list_roles_api_v1_admin_role_get()
      return response
    },
    showModule: async (module_code) => {
      const response = await zodiosApi.list_one_api_v1_viewpoint_module__module_code__get({
        params: { module_code },
      })
      return response
    },
    getTypeOfModules: async (userFacing: boolean) => {
      const response = await zodiosApi.list_all_api_v1_viewpoint_module_get({
        queries: { user_facing: userFacing },
      })
      return response
    },
    updateModule: async (organization_id: string, module_id: string) => {
      const response =
        await zodiosApi.add_organization_module_api_v1_organization__organization_id___module_id__post(
          undefined,
          {
            params: {
              organization_id: organization_id,
              module_id: module_id,
            },
          },
        )
      // const response = await zodiosApi.post("/api/v1/organization/:organization_id/:module_id", undefined, {
      //   params: {
      //     organization_id: organization_id,
      //     module_id: module_id
      //   }
      // })
      return response
    },
    deleteModule: async (organization_id: string, module_id: string) => {
      const response =
        await zodiosApi.remove_organization_module_api_v1_organization__organization_id___module_id__delete(
          undefined,
          { params: { organization_id, module_id } },
        )
      return response
    },
    inviteUser: async (email: string, organizationId: string, roleId: string) => {
      const response = await zodiosApi.invite_input_data_org_api_v1_user_invite_post({
        email: email,
        organizationId: organizationId,
        roleId: roleId,
      })
      return response
    },
    topprofessionsAnalytics: async (search: string) => {
      const response = await zodiosApi.get_profession_api_v1_analytics_profession_get({
        queries: {
          search: search,
        },
      })
      return response
    },
    topSkillsAnalytics: async (boc_code: string) => {
      const response = await zodiosApi.get_top_skills_api_v1_analytics_profession_top_skills_get({
        queries: {
          boc_code: boc_code,
        },
      })
      return response
    },
    getSimilarProffessions: async (
      boc_code: string,
      page_size: number,
      search: string,
      skill_list?: any,
    ) => {
      const response =
        await zodiosApi.get_similar_professions_api_v1_analytics_profession_get_similar_professions_get(
          {
            queries: {
              boc_code: boc_code,
              page_size: page_size,
              search: search,
              skill_list: skill_list,
            },
            paramsSerializer: { indexes: null },
          },
        )
      return response
    },
    getLinkList: async (query) => {
      const response = await zodiosApi.list_all_api_v1_link__organization_id__get({
        params: {
          organization_id: query.organizationId,
        },
        queries: {
          page: query.currentPage,
          page_size: query.pageSize,
          is_active: query.is_active,
          group_id: query.group_id,
          group_type_code: query.group_type_code,
          company_name: query.company_name,
          group_module_code: query.group_module_code,
        },
      })
      return response
    },
    createLink: async (query) => {
      const response = await zodiosApi.create_api_v1_link__organization_id__post(
        {
          groupId: query.group_id,
          validityInDays: query.validityInDays,
          hasEmail: query.hasEmail,
          linkName: query.linkName,
          company_name: query.company_name,
        },
        {
          params: {
            organization_id: query.organizationId,
          },
        },
      )
      return response
    },
    disableLink: async (organization_id: string, link_id: string) => {
      const response = await zodiosApi.disable_api_v1_link__organization_id__disable__link_id__put(
        undefined,
        {
          params: {
            organization_id: organization_id,
            link_id: link_id,
          },
        },
      )
      return response
    },
    updateLink: async (organization_id: string, link_id: string, query: updateLinkType) => {
      const response = await zodiosApi.update_api_v1_link__organization_id___link_id__put(
        {
          linkName: query.linkName,
          companyName: query.companyName,
        },
        {
          params: {
            organization_id: organization_id,
            link_id: link_id,
          },
        },
      )
      return response
    },
    getProfileAnalytics: async (query: any) => {
      const response =
        await zodiosApi.get_skillscv_profile_analytics_api_v1_analytics_skillscv_user_analytics_aggregations_get(
          {
            queries: {
              page: query.currentPage,
              page_size: query.pageSize,
              aggregation_level: query.aggregation_level,
              for_dashboard: query.for_dashboard,
            },
          },
        )
      return response
    },
    getJobAnalytics: async (query: any) => {
      const response =
        await zodiosApi.get_job_application_analytics_api_v1_analytics_skillscv_user_analytics_aggregations_job_application_get(
          {
            queries: {
              page: query.currentPage,
              page_size: query.pageSize,
              aggregation_level: query.aggregation_level,
              for_dashboard: query.for_dashboard,
            },
          },
        )
      return response
    },
    getListTotal: async () => {
      const response =
        await zodiosApi.get_total_insights_api_v1_analytics_skillscv_user_analytics_aggregations_total_get()
      return response
    },
    getAppFlowAggr: async (query: any) => {
      const response =
        await zodiosApi.get_skillscv_job_application_flow_api_v1_analytics_skillscv_user_analytics_application_flow_aggregations_get(
          {
            queries: {
              aggregation_level: query.aggregation_level,
              filter_date: query.filter_date,
            },
          },
        )
      return response
    },
    invite: async (link_safe_id: string, email: string) => {
      const response =
        await zodiosApi.initiate_member_invite_api_v1_invite__link_safe_id___email__get({
          params: {
            link_safe_id,
            email,
          },
        })
      return response
    },
  }
}

// Logout Method Created Triggers on 401 error response
export const authLogout = () => {
  Cookies.remove('user')
  Cookies.remove('userInfo')
  localStorage.removeItem('userInfo')
  localStorage.removeItem('user')
  return new Promise<string>((resolve) => {
    return resolve('logout')
  })
}
