const ROLES = {
  SkillsCVStaff: 'SkillsCVStaff', // Teacher
  OrganizationAdmin: 'OrganizationAdmin', // Organization Admin
  OrganizationStaff: 'OrganizationStaff', // Admin
  ViewAPIDocs: 'ViewAPIDocs', // ViewAPIDocs
  SkillsCVSuperAdmin: 'SkillsCVSuperAdmin', // Super Admin
  ReadOnlyDemoUser: 'ReadOnlyDemoUser', // Demo User to view only
  WhiteLabelLiteUser: 'WhiteLabelLiteUser',
}

const MODULES = {
  SkillscvAdminModule: 'skillscv-admin-module',
  CoachingModule: 'coaching-module',
  JobMarketAnalysisModule: 'job-market-analysis-module',
  SearchAndMatchModule: 'search-and-match-module',
  SearchAndMatchPlusModule: 'search-and-match-plus-module',
  EventManagerModule: 'event-manager-module',
  TalentPoolManagerModule: 'talent-pool-manager-module',
  OrganizationSetUpModule: 'organization-set-up-module',
}

export { ROLES, MODULES }
