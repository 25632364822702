import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EN from '@/assets/images/en.svg'
import NL from '@/assets/images/nl.svg'
import Select from 'react-select'
import { customizeSelectStyles, customizeSelectTheme } from '@/helper/selectTheme'

type LangType = {
  name: string
  value: string
}

const DropdownLanguage = () => {
  const { i18n } = useTranslation()
  const lang = localStorage.getItem('lang')
    ? JSON.parse(JSON.stringify(localStorage.getItem('lang')))
    : 'nl'

  const handleLangChange = (option: LangType) => {
    setLanguage(option)
    i18n.changeLanguage(option.value)
    localStorage.setItem('lang', option.value)
  }

  const [options] = useState<LangType[]>([
    {
      name: 'EN',
      value: 'en',
    },
    {
      name: 'NL',
      value: 'nl',
    },
  ])

  const [language, setLanguage] = useState<any>(options.find((x) => x.value === lang))

  const formatOptionLabel = (option: any) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className='lang-label'>
        <img loading='lazy' src={option.name === 'EN' ? EN : NL} alt={option.name} />
      </div>
      <span>{option.name}</span>
    </div>
  )

  return (
    <div className='ms-auto'>
      <div className='d-flex align-items-center fs-12 lang-dropdown'>
        <Select
          instanceId='memberSelect'
          value={language}
          onChange={handleLangChange}
          name='members'
          options={options}
          className='language-multi-select'
          classNamePrefix='select'
          getOptionValue={(option) => option.value}
          formatOptionLabel={formatOptionLabel}
          isSearchable={false}
          theme={customizeSelectTheme}
          styles={customizeSelectStyles}
        />
      </div>
    </div>
  )
}

export default DropdownLanguage
