import { Theme, StylesConfig } from 'react-select'

// Define a function to customize the ReactSelect theme
export const customizeSelectTheme = (theme: Theme): Theme => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#ebe5f4',
      primary: '#390099',
    },
  }
}

// Define a function to customize the ReactSelect styles
export const customizeSelectStyles: StylesConfig<any, any> = {
  control: (baseStyles) => ({
    ...baseStyles,
    borderColor: '#dee2e6',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }
  },
}
