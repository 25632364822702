import React from 'react'
import './loader.scss'
import { Spinner } from 'react-bootstrap'

const Loader = () => (
  <div className='fallback-spinner'>
    <div className='loading'>
      <Spinner variant='purple' animation={'border'} />
    </div>
  </div>
)
export default Loader
